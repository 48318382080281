
<div class="section-body">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Webinars</h4>
                    <div class="addBtn">
                        <!-- <button type="button" class="btn btn-primary" (click)="createwebinar()">+ New Webinar</button> -->
                        <input type="hidden" id="loadList" (click)="webinarList()">
                    </div>
					<!-- <a href="https://uatassaabloyccapi.mconnectapps.com/api/v1.0/pro_contents/pro_sms_temp.pdf" target="_blank"><i style="font-size:24px;padding-left: 20px;" class="fa fa-exclamation-circle"></i></a> -->

                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                       <table class="table table-bordered table-striped table-md">
                         
                        <tr>
                            <th>S.no </th>
                            <th>Title</th>
                            <th>Scheduled Date & Time</th>
                            <th>Duration</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>

                        <tbody>
                            <tr *ngFor="let item of Mqueue_list;  let i=index">
                                <td>{{0+i+1}}</td>
                                <td>{{item.title}}</td>
                                <td>{{item.created_at |date:'MMM dd, yyyy HH:mm'}}</td>
                                <td>{{item.duration}} Mins</td>
                                <td>{{item.status}}</td>


                                    <td>
                                        <span class="user-icon" style="cursor: pointer;"><i class="fa fa-users" (click)="viewwebinar(item.meetingid)"></i></span>
                                        <span class="user-icon" style="cursor: pointer;"><i class="far fa-trash-alt" (click)="deletedata(item.meetingid)"></i></span>
                                    </td>
                                </tr>
                            <tr *ngIf="recordNotFound == true">
                             <td colspan="12">Data not found</td>
                         </tr>
                     </tbody>
                     
                 </table>
               </div>
           </div>
         
       </div>
     </div>
  </div>
</div>




<div class="modal fade bd-example-modal-md" id="edit_deptform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
                <div class="modal-header"> 
                    <h5 class="modal-title" >Update</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    
                    <div class="row">


                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="template">Webinar Logo</label>
                                <input type="file" id="u_webinar_logo" class="form-control">  
                                <input type="hidden" id="u_id">
                                <img src='' id='webinar_m_logo' style="width: 100px; height: auto">                       
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="department">Title</label>
                                <input type="text" id="u_webinar_title" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="from_date">Scheduled Date </label>
                                <input type="datetime-local" id="u_from_date" class="form-control" >
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="duration">Duration</label>
                                <select id="u_duration" class="form-control">
                                    <option value="5">5 Mins</option>
                                    <option value="10">10 Mins</option>
                                    <option value="15">15 Mins</option>
                                    <option value="20">20 Mins</option>
                                    <option value="25">25 Mins</option>
                                    <option value="30">30 Mins</option>
                                    <option value="35">35 Mins</option>
                                    <option value="40">40 Mins</option>
                                    <option value="45">45 Mins</option>
                                    <option value="50">50 Mins</option>
                                    <option value="55">55 Mins</option>
                                    <option value="60">60 Mins</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="template">Webinar Description</label>
                                <textarea type="text" id="u_webinar_description" class="form-control"></textarea>                      
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="template">Webinar Description Image</label>
                                <input type="file" id="u_webinar_descriptionImage" class="form-control">   
                                <img src='' id='webinar_m_descriptionImage' style="width: 100px; height: auto">                       
                            </div>
                        </div>
                        
                        <!-- <div class="col-md-12">
                            <div class="form-group">
                                <label for="status">Status</label>
                                <input type="checkbox" id="status" class="form-control" formControlName="status">
                            </div>
                        </div> -->
                    </div>

                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="button" (click)="editWebinar()" class="btn btn-primary update_agent" value="Submit" >
                    </div>
                </div>
        </div>
    </div>
</div>






<div class="modal fade bd-example-modal-md" id="add_deptform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" >Add webinar</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">


                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="template">Webinar Logo</label>
                                <input type="file" id="webinar_logo"  class="form-control">                         
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="department">Title</label>
                                <input type="text" id="webinar_title"  class="form-control">
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="from_date">Scheduled Date </label>
                                <input type="datetime-local" id="from_date" class="form-control" >
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="duration">Duration</label>
                                <select id="duration" class="form-control">
                                    <option value="5">5 Mins</option>
                                    <option value="10">10 Mins</option>
                                    <option value="15">15 Mins</option>
                                    <option value="20">20 Mins</option>
                                    <option value="25">25 Mins</option>
                                    <option value="30">30 Mins</option>
                                    <option value="35">35 Mins</option>
                                    <option value="40">40 Mins</option>
                                    <option value="45">45 Mins</option>
                                    <option value="50">50 Mins</option>
                                    <option value="55">55 Mins</option>
                                    <option value="60">60 Mins</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="template">Webinar Description Image</label>
                                <input type="file" id="webinar_descriptionImage"  class="form-control">                         
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="template">Webinar Description</label>
                                <textarea type="text" id="webinar_description"  class="form-control">   </textarea>                      
                            </div>
                        </div>

                        

                        

                        <!-- <div class="col-md-12">
                            <div class="form-group">
                                <label for="status">Status</label>
                                <input type="checkbox" id="status" class="form-control" formControlName="status">
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="button" class="btn btn-primary update_agent" value="Submit">
                        <!-- <input type="button" class="btn btn-primary update_agent" value="Submit" (click)="addWebinar()"> -->
                    </div>
                </div>

        </div>
    </div>
</div>


<div class="modal fade bd-example-modal-md" id="viewParticipans">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" >List of Participants</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">


                            <div class="card-body p-0">
                                <div class="table-responsive">
                                   <table class="table table-bordered table-striped table-md">
                                     
                                    <tr>
                                        <th>S.No </th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <!-- <th>Country</th> -->
                                        <th>Organization</th>
                                    </tr>
            
                                    <tbody>
                                        <tr *ngFor="let item of participants_list;  let i=index">
                                            <td>{{0+i+1}}</td>
                                            <td>{{item.first_name}}</td>
                                            <td>{{item.last_name}}</td>
                                            <td>{{item.email}}</td>
                                            <!-- <td>  -</td> -->
                                            <td>{{item.organization}}</td>
                                        </tr>
                                        <tr *ngIf="recordNotFound == true">
                                         <td colspan="12">Data not found</td>
                                     </tr>
                                 </tbody>
                                 
                             </table>
                         </div>
                     </div>


                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="button" class="btn btn-primary update_agent" value="Export CSV" (click)="exportcsv()">
                    </div>
                </div>
        </div>
    </div>
</div>