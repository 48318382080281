<div class="section-body">
	<div class="row">
		<div class="col-12 col-md-12 col-lg-12">
			<div class="card">
				<div class="card-header">
					<h4>Market Place</h4>
					<div class="addBtn">
						<div class="default-view">
							<button type="button" class="btn btn-warning"><i class="fas fa-compress-arrows-alt"></i> Default View</button>
						</div>
					</div>
				</div>
				<div class="card-body market-place">
					<div class="demo-item-wrapper">
						<div class="row">
<!----------------------
Demo Item
------------------------>
<div class="col-md-6 col-12">
	<div class="colorful-box-item" style="background-image: url('assets/images/marketplace/wallbard-bg.jpg');">
		<div class="overlay" style="background-image:-webkit-linear-gradient(-48deg,rgb(36,226,254)0%,rgb(36,176,254)54%,rgb(35,126,253)100%);background-image:-ms-linear-gradient(-48deg,rgb(36,226,254)0%,rgb(36,176,254)54%,rgb(35,126,253)100%);"></div>
		<div class="colorful-box-inner">
			<div class="colorful-box-icon">
				<img src="assets/images/marketplace/wall-board-icon.svg">
			</div>
			<div class="colorful-box-title">
				<h4>Customise Wallboard</h4>
			</div>
			<div class="colorful-box-description">
				<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat</p>
			</div>
			<div class="colorful-box-read-more">
				<a href="javascript:;"  routerLink="/marketplace-wall">Read more <i class="fas fa-arrow-right"></i></a>
			</div>
		</div>
	</div>
</div>
<!----------------------
Demo Item End
------------------------>

<!----------------------
Demo Item
------------------------>
<div class="col-md-6 col-12">
	<div class="colorful-box-item" style="background-image: url('assets/images/marketplace/custom-report.png');">
		<div class="overlay" style="background-image: -webkit-linear-gradient(-48deg,rgb(255,211,36)0%,rgba(254,187,36,0.99)54%,rgba(253,163,35,0.99)100%);
		background-image: -ms-linear-gradient(-48deg,rgb(255,211,36)0%,rgba(254,187,36,0.99)54%,rgba(253,163,35,0.99)100%);"></div>
		<div class="colorful-box-inner">
			<div class="colorful-box-icon">
				<img src="assets/images/marketplace/report-icon.svg">
			</div>
			<div class="colorful-box-title">
				<h4>Custom Report</h4>
			</div>
			<div class="colorful-box-description">
				<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat</p>
			</div>
			<div class="colorful-box-read-more">
				<a href="javascript:;" routerLink="/marketplace-report">Read more <i class="fas fa-arrow-right"></i></a>
			</div>
		</div>
	</div>
</div>
<!----------------------
Demo Item End
------------------------>

<!----------------------
Demo Item
------------------------>
<div class="col-md-6 col-12">
	<div class="colorful-box-item" style="background-image: url('assets/images/marketplace/agent-rating-bg.jpg');">
		<div class="overlay" style="background-image: -webkit-linear-gradient(-48deg,rgba(248,47,160,0.99)0%,rgba(214,54,208,0.99)54%,rgba(180,61,255,0.99)100%);
		background-image: -ms-linear-gradient(-48deg,rgba(248,47,160,0.99)0%,rgba(214,54,208,0.99)54%,rgba(180,61,255,0.99)100%);"></div>
		<div class="colorful-box-inner">
			<div class="colorful-box-icon">
				<img src="assets/images/marketplace/agent-rating.svg">
			</div>
			<div class="colorful-box-title">
				<h4>Agent Rating</h4>
			</div>
			<div class="colorful-box-description">
				<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat</p>
			</div>
			<div class="colorful-box-read-more">
				<a href="javascript:;">Read more <i class="fas fa-arrow-right"></i></a>
			</div>
		</div>
	</div>
</div>
<!----------------------
Demo Item End
------------------------>

<!----------------------
Demo Item
------------------------>
<div class="col-md-6 col-12">
	<div class="colorful-box-item" style="background-image: url('assets/images/marketplace/predictiveDialer-bg.png');">
		<div class="overlay" style="background-color: #d78883;
		background-image: linear-gradient(315deg, #d78883 0%, #ffac81  74%);"></div>
		<div class="colorful-box-inner">
			<div class="colorful-box-icon">
				<img src="assets/images/marketplace/predictive-dialer.svg">
			</div>
			<div class="colorful-box-title">
				<h4>Predictive dialer</h4>
			</div>
			<div class="colorful-box-description">
				<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat</p>
			</div>
			<div class="colorful-box-read-more">
				<a href="javascript:;">Read more <i class="fas fa-arrow-right"></i></a>
			</div>
		</div>
	</div>
</div>
<!----------------------
Demo Item End
------------------------>

<!----------------------
Demo Item
------------------------>
<div class="col-md-6 col-12">
	<div class="colorful-box-item" style="background-image: url('assets/images/marketplace/bulk-sms-service.jpg');">
		<div class="overlay" style="background-color: #09203f;
		background-image: linear-gradient(315deg, #09203f  0%, #537895 74%);"></div>
		<div class="colorful-box-inner">
			<div class="colorful-box-icon">
				<img src="assets/images/marketplace/sms-icon.svg">
			</div>
			<div class="colorful-box-title">
				<h4>Bulk SMS</h4>
			</div>
			<div class="colorful-box-description">
				<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat</p>
			</div>
			<div class="colorful-box-read-more">
				<a href="javascript:;"  (click)="openSMSPanel()">Read more <i class="fas fa-arrow-right"></i></a>
			</div>
		</div>
	</div>
</div>
<!----------------------
Demo Item End
------------------------>

<!----------------------
Demo Item
------------------------>
<div class="col-md-6 col-12">
	<div class="colorful-box-item" style="background-image: url('assets/images/marketplace/whatsapp-bg.png');">
		<div class="overlay" style="background-image: -webkit-linear-gradient(-48deg,rgba(0,188,128,0.99)0%,rgba(2,204,71,0.99)54%,rgba(3,220,13,0.99)100%);
		background-image: -ms-linear-gradient(-48deg,rgba(0,188,128,0.99)0%,rgba(2,204,71,0.99)54%,rgba(3,220,13,0.99)100%);"></div>
		<div class="colorful-box-inner">
			<div class="colorful-box-icon">
				<img src="assets/images/marketplace/whatsapp-icon.svg">
			</div>
			<div class="colorful-box-title">
				<h4>WhatsApp Number Purchase</h4>
			</div>
			<div class="colorful-box-description">
				<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat</p>
			</div>
			<div class="colorful-box-read-more">
				<a href="javascript:;">Read more <i class="fas fa-arrow-right"></i></a>
			</div>
		</div>
	</div>
</div>
<!----------------------
Demo Item End
------------------------>

</div>
</div>
</div>
</div>
</div>
</div>



</div>












<div class="modal fade bd-example-modal-md" id="addSMSForm">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Buy SMS Balance</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="sms_balance">Amount ($)</label>
                                <input type="text" id="sms_balance" class="form-control" name="sms_balance">
                            </div>
                        </div> 
                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
						<input type="submit" class="btn btn-primary update_agent" value="Add To Cart" (click)="AddToCartSMS('1')">
						<input type="submit" class="btn btn-primary update_agent" value="Add and View Cart" (click)="AddToCartSMS('2')">

                    </div>
                </div>
        </div>
    </div>
</div>