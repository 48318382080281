<div class="section-body">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Agents</h4>
                    <div class="addBtn btn-group">
                        <!-- <button type="button" class="btn btn-primary" *ngIf="hideAddButt" (click)="addAgents()">+ Add Agent</button> -->
                            <button type="button" class="btn btn-primary" (click)="addAgents()">+ Add Agent</button>
                        <button type="button" class="btn btn-primary" (click)="retriveUsersFrom3cx()">+ Retrieve From
                            3CX</button>
                        <input type="hidden" value="0" id="agentsList" (click)="agentsList({})">
                        <input type="hidden" value="" id="datagetsucced" (click)="datagetsucced()">
                        <input type="hidden" value="" id="datagetsfailed" (click)="datagetsfailed()">
                        <input type="hidden" value="" id="enablereport" (click)="enablereport()">
                        <input type="hidden" value="" id="updatereport" (click)="updatereport()">
                    </div>
                    <a (click)="showdoc('https://uatassaabloyccapi.mconnectapps.com/api/v1.0/pro_contents/pro_agents.pdf')"
                        target="_blank"><i style="font-size: 24px;
                        float: right;" class="fa fa-question-circle"></i></a>
                    <!-- <a href="https://uatassaabloyccapi.mconnectapps.com/api/v1.0/pro_contents/pro_agents.pdf" target="_blank"><i style="font-size:24px;" class="fa fa-exclamation-circle"></i></a> -->

                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-md">
                            <tr>
                                <td colspan="20" class="search-text-tbl">
                                    <div class="input-group"><input type="text" class="form-control"
                                            (keyup.enter)="agentsList({'search_text':agent_search.value})"
                                            placeholder="Search Agent Name - Extension No" #agent_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary"
                                                (click)="agentsList({'search_text':agent_search.value})"><i
                                                    class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>


                            <tr>
                                <th>#</th>
                                <th>Agent Name</th>
                                <th>Extension</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>


                            <tbody>
                                <tr *ngFor="let queue of agents_list;let i=index">
                                    <td>{{this.offset_count+i+1}}</td>
                                    <td>{{queue.agent_name}}
                                        <!-- <a class="tooltip-container">
                                            <span class="cutom-tooltip">Hold by Agent (Total Tickets)</span><strong>{{list.total_ticket_count}} Tickets</strong>
                                        </a> -->
                                        <span class="tooltip-container" *ngIf="queue.admin_permision == '1'">
                                            <span class="cutom-tooltip">Has Admin Permission</span>
                                            <img style="height: 25px;padding-bottom: 5px;"
                                                src="assets/img/admin-crown.png" alt="Admin">
                                        </span>
                                        <span class="tooltip-container" *ngIf="queue.chat_ticket == '1'">
                                            <span class="cutom-tooltip">Chat Logout</span>
                                            <i  class="fas fa-comments" style="color: red;"></i>
                                          
                                        </span>
                                        <span class="tooltip-container" *ngIf="queue.chat_ticket == '0'">
                                            <span class="cutom-tooltip">Chat Login</span>
                                            <i  class="fas fa-comments" style="color: green;"></i>
                                        </span>

                                    </td>
                                    <!-- <td><span *ngIf="show_caller_id == 1"><i title="Click to call" class="fa fa-phone-square" aria-hidden="true" (click)="clictToCall(contact.phone)" style="cursor: pointer; margin-right: 10px;font-size: 15px;"></i>{{contact.phone}}</span><span *ngIf="show_caller_id == 0">xxxxxxx</span></td> -->

                                    <td>
                                        <div class="agent-status">
                                            <span><i title="Click to call" class="fa fa-phone-square" aria-hidden="true"
                                                    (click)="clictToCall(queue.sip_login)"
                                                    style="cursor: pointer; margin-right: 10px;font-size: 15px;"></i></span>

                                            <!-- <span  [class]="queue.sip_registered_status == '1' ? 'presence available' : queue.sip_registered_status == '2' ? 'presence away' :  queue.sip_registered_status == '0' ? 'presence out_of_office' :  '' " ></span> -->

                                            {{queue.sip_login}}
                                        </div>
                                    </td>


                                    <td>
                                        <div class="form-check form-check-inline">
                                            <div class="pretty p-switch p-fill">
                                                <input type="checkbox"
                                                    checked="{{queue.user_status == 1 ? 'checked' : ''}}"
                                                    (click)="actCamp('user_status',queue.user_id)">
                                                <div class="state p-success">
                                                    <label for="has_status"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <!-- <span class="user-icon has-tooltip" style="cursor: pointer;"><i class="fa fa-share" (click)="tranferbutton(item.qcallid,this.has_hard_id)"><span class="tooltip-modal">Transfer</span></i></span> -->
                                        <span class="user-icon has-tooltip" style="cursor: pointer;"><i
                                                class="far fa-edit" (click)="editAgents(queue.user_id)"><span
                                                    class="tooltip-modal"
                                                    style="min-width: 115px;left: -43px;">Edit</span></i></span>
                                        <!-- <input type="hidden" id="geteditonce" (click)="editAgents(queue.user_id)">  -->

                                        <span class="user-icon has-tooltip" style="cursor: pointer;"><i
                                                class="far fa-trash-alt" (click)="deleteAgentdata(queue.user_id)"><span
                                                    class="tooltip-modal"
                                                    style="min-width: 115px;left: -43px;">Delete</span></i></span>

                                        <span class="user-icon has-tooltip" style="cursor: pointer;"><i
                                                class="fa fa-envelope"
                                                (click)="sendWelcomeMail(queue.user_id,queue.email_id)"><span
                                                    class="tooltip-modal" style="min-width: 115px;left: -43px;">Welcome
                                                    Email</span></i></span>
                                        <!-- <span  class="user-icon has-tooltip" style="cursor: pointer;"><i class="fas fa-file-invoice-dollar" (click)="genInvoice(queue.user_id,queue.email_id)"><span class="tooltip-modal" style="min-width: 115px;left: -43px;">Generate Invoice</span></i></span> -->
                                        <!-- <button type="button" class="btn btn-primary" (click)="sendWelcomeMail(queue.user_id,queue.email_id)">
                                        <span>
                                            <i class="far fa-inbox-out"></i>
                                        </span>
                                        Send Welcome Mail</button> 
                                        <button type="button" class="btn btn-primary" (click)="genInvoice(queue.user_id,queue.email_id)">
                                            <span *ngIf="sendingmail == true">
                                                <i class="glyphicon glyphicon-refresh spinning"></i>
                                            </span>
                                            generate Invoice</button>  -->
                                    </td>
                                    <!-- <input type="hidden"   class="btn btn-secondary" id="geteditonce"  (click)=editAgents(queue.user_id)> -->

                                </tr>

                                <tr *ngIf="recordNotFound == true">
                                    <td colspan="16">Data not found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-footer text-right">
                    <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                            <li class="page-item">
                                <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'"
                                    href="javascript:void(0)"
                                    (click)="paginationData.backtab=='show' ? agentsList({'search_text':agent_search.value,'offset':paginationData.start}) : false"><i
                                        class="fas fa-angle-left"></i></a>
                            </li>
                            <li class="page-item" *ngIf="paginationData.backtab =='show'">
                                <a class="page-link" href="javascript:void(0)"
                                    (click)="agentsList({'search_text':agent_search.value,'offset':paginationData.back})"><i
                                        class="fas fa-angle-double-left"></i></a>
                            </li>
                            <li [class]="pagination.load ==false ? 'page-item active' :'page-item'"
                                *ngFor="let pagination of paginationData.data;let i=index">
                                <a class="page-link" href="javascript:void(0)"
                                    (click)=" pagination.load ==true ? agentsList({'search_text':agent_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}}
                                    <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                            </li>

                            <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                                <a class="page-link" href="javascript:void(0)"
                                    (click)="agentsList({'search_text':agent_search.value,'offset':paginationData.next})"><i
                                        class="fas fa-angle-double-right"></i></a>
                            </li>

                            <li class="page-item">
                                <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'"
                                    href="javascript:void(0)"
                                    (click)="paginationData.nexttab =='show' ? agentsList({'search_text':agent_search.value,'offset':paginationData.end}) : false"><i
                                        class="fas fa-angle-right"></i></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>












<div class="modal fade bd-example-modal-md" id="add_agents_form">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form [formGroup]="addAgent" (ngSubmit)="addAgentData()">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Add Agent</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="agent_name">Name <span style="color: red;">*</span></label>
                                <input type="text" id="agent_name" class="form-control" formControlName="agent_name">
                                <label class="error-message message-small"
                                    *ngIf="!addAgent.get('agent_name').valid && addAgent.get('agent_name').touched">Please
                                    enter the name</label>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="agent_emailid">Email Id <span style="color: red;">*</span></label>
                                <input type="email" id="agent_emailid" class="form-control" formControlName="emailid">
                                <label class="error-message message-small"
                                    *ngIf="!addAgent.get('emailid').valid && addAgent.get('emailid').touched ">Please
                                    enter the valid email id</label>
                                <!-- <label class="error-message message-small" *ngIf="addAgent.get('emailid').touched">Email required</label> -->
                            </div>
                            <!-- <span  *ngIf="!email.valid && email.touched">
                                Email is invalid
                           </span> -->
                        </div>


                    </div>
                    <div class="row">

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="agent_phone_number">Phone number</label>
                                <input type="text" id="agent_phone_number" class="form-control"
                                    formControlName="phone_number">

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="agent_user_name">User Name <span style="color: red;">*</span></label>
                                <input type="text" id="agent_user_name" class="form-control"
                                    formControlName="user_name">
                                <label class="error-message message-small"
                                    *ngIf="!addAgent.get('user_name').valid && addAgent.get('user_name').touched">Please
                                    enter the username</label>
                            </div>
                        </div>


                        <!-- <div class="col-md-6">
                            <div class="form-group">
                                <label for="agent_user_pwd">User password</label>
                                <span toggle="#agent_user_pwd" class="fa fa-fw fa-eye field-icon " (click)="toggleClasss()"></span>

                                <input type="password" id="update_user_pwd" value="{{this.a_pass}}" class="form-control"  >
                            </div>
                        </div> -->




                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="agent_user_pwd">User password <span style="color: red;">*</span></label>
                                <span toggle="#agent_user_pwd"
                                    class="fa fa-fw fa-eye field-icon toggle-password"></span>

                                <input type="text" id="agent_user_pwd" class="form-control">
                                <!-- <label class="error-message message-small" *ngIf="!addAgent.get('user_pwd').valid && addAgent.get('user_pwd').touched">Please enter the password</label> -->
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="agent_user_pwd">Ext / Auth</label>
                                <input type="text" id="sip_login_add" class="form-control" formControlName="sip_login"
                                    #sip_login_add>
                                <!-- <label class="error-message message-small" *ngIf="!addAgent.get('sip_login').valid && addAgent.get('sip_login').touched">Please enter the Extension</label> -->

                            </div>
                        </div>
                        <!-- <div class="col-md-12">
                            <div class="form-group">
                                <label for="agent_user_pwd"></label>
                                <button type="button" class="btn btn-primary" (click)="retriveFrom3cx(sip_login_add.value)" *ngIf="this.hardware_id !=''">Retrive From 3cx</button> 
                            </div>
                        </div> -->

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="agent_user_pwd">SIP Username</label>
                                <input type="text" id="sip_username_add" class="form-control"
                                    formControlName="sip_username">
                                <!-- <label class="error-message message-small" *ngIf="!addAgent.get('sip_username').valid && addAgent.get('sip_username').touched">You should enter the Sip_username</label> -->

                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="agent_user_pwd">SIP Password</label>
                                <span toggle="#sip_password" class="fa fa-fw fa-eye field-icon toggle-password"></span>

                                <input type="text" id="sip_password_add" class="form-control"
                                    formControlName="sip_password">
                                <!-- <label class="error-message message-small" *ngIf="!addAgent.get('sip_password').valid && addAgent.get('sip_password').touched">You should enter the Sip_password</label> -->
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <button type="button" style="margin-top: 30px;" class="btn btn-primary"
                                    title="Retrive Sip Details From 3CX" (click)="retriveFrom3cx(sip_login_add.value)"
                                    *ngIf="this.hardware_id !=''"><i class="fas fa-download"
                                        style="padding: 0px;"></i></button>
                            </div>
                        </div>

                        <!-- <div class="col-md-6 ">
                            <div class="form-check form-check-inline">
                               <div class="pretty p-switch p-fill">
                                   <input type="checkbox"  formControlName="voice_3cx"  (click)="getCallTariffsList()" >
                                   <div class="state p-success">
                                       <label for="voice_3cx">Reports</label>
                                   </div>
                               </div>
                           </div>
                           
                    </div>  -->
                        <div class="col-md-6 ">
                            <div class="form-group">
                                <label for="reports">Select Report</label>
                                <p *ngIf="this.no_report" style="color: #ec8787;">Please Upgrade your package</p>
                                <select *ngIf="!this.no_report" name="reports" multiple id="add_reports"
                                    class="form-control has-multiple-select">
                                    <ng-container *ngFor="let items of list_reports;let i=index">
                                        <option *ngIf="report_id.indexOf(items.id) > -1; else notSelected"
                                            value="{{items.id}}" (click)="eventGetChange()">{{items.report_name}}
                                        </option>
                                    </ng-container>
                                    <ng-template #notSelected></ng-template>
                                    <!-- {{report_id.indexOf(items.id)}} -->
                                    <!-- <option *ngFor="let items of list_reports;let i=index" > -->
                                    <!-- <div *ngIf="{{items.id == report_checked}}"> -->
                                    <!-- <li *ngIf="report_id.indexOf(items.id) > -1; else notSelected">  -->
                                    <!-- <li>
                                           {{items.report_name}}
                                        </li>
                                    </option> -->
                                </select>
                            </div>

                        </div>

                        <div class="col-md-4" *ngIf="agent_3cx_rep">
                            <div class="form-group">
                                <label for="reports">Call Recording group</label>
                                <select name="reports" id="recording_grp" class="form-control">
                                    <option *ngFor="let items of agent_grp;let i=index" value="{{items.id}}">
                                        {{items.group_name}}</option>

                                </select>
                            </div>
                        </div>

                        <div class="row has-row-space">
                            <div class="col-md-6 ">
                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" id="admin_permision" formControlName="admin_permision">
                                        <div class="state p-success">
                                            <label for="admin_permision">Has Admin Permision</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 {{this.voice_3cx}}">
                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="voice_3cx"
                                            (click)="getCallTariffsList()">
                                        <div class="state p-success">
                                            <label for="voice_3cx">Voice 3CX</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>
                            <!-- <div class="col-md-12" *ngIf="showCallTariffsDet">
                            <div class="row" >
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="MPlanName">Call Tariff</label>
                                        <select class="chat-widget form-control" id="MPlanName">
                                            <option  *ngFor="let item of callTariffs"  value="{{item.id}}">{{item.plan_name}}</option> 
                                        </select> 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="call_rate" >Call Rate</label>
                                        <input type="text"  class="form-control"  id="call_rate" >
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="call_prefix">Call Prefix</label>
                                        <input type="text"  class="form-control"  id="call_prefix" >
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="valid_from">Valid From</label>
                                        <input type="date"  class="form-control"   id="valid_from">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="valid_to">Valid To</label>
                                        <input type="date" class="form-control"  id="valid_to">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="a_tax_name">Tax Name</label>
                                        <input type="text"  class="form-control"  id="a_tax_name" >
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="a_tax_per">Tax Percentage</label>
                                        <input type="text"  class="form-control"  id="a_tax_per" >
                                    </div>
                                </div>
                            </div>
                        </div> -->

                            <div class="col-md-6">
                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="close_all_menu">
                                        <div class="state p-success">
                                            <label for="close_all_menu">Close all menu</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 {{this.H_PD}}">

                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="predective_dialer">
                                        <div class="state p-success">
                                            <label for="predective_dialer">Predective Dialer</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>
                            <div class="col-md-6 {{this.H_PD}}">

                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="predective_dialer_behave">
                                        <div class="state p-success">
                                            <label for="predective_dialer_behave">Predective Dialer Behaviour</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>

                            <!-- <div class="col-md-6 {{this.lead}}">

                    <div class="form-check form-check-inline">
                       <div class="pretty p-switch p-fill">
                           <input type="checkbox"  formControlName="lead" >
                           <div class="state p-success">
                               <label for="lead">Lead</label>
                           </div>
                       </div>
                   </div>
                   <div class="hidden-things">
                    Please Upgrade your package
                </div>
            </div>  -->

                            <div class="col-md-6 {{this.h_con}}">
                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="has_contact">
                                        <div class="state p-success">
                                            <label for="has_contact">Contact</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>
                            <div class="col-md-6 {{this.h_sms}}">
                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="has_sms">
                                        <div class="state p-success">
                                            <label for="has_sms">SMS</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>

                            <div class="col-md-6  {{this.h_chat}}">
                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="has_chat">
                                        <div class="state p-success">
                                            <label for="has_chat">Web Chat</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>

                            <div class="col-md-6  {{this.h_int_chat}}">
                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="has_int_chat">
                                        <div class="state p-success">
                                            <label for="has_int_chat">Internal Chat</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>

                            <div class="col-md-6  {{this.h_wp}}">
                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="has_whatsapp">
                                        <div class="state p-success">
                                            <label for="has_whatsapp">Whatsapp</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>


                            <div class="col-md-6  {{this.h_cbot}}">
                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="has_chatbot">
                                        <div class="state p-success">
                                            <label for="has_chatbot">Chatbot</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>

                            <div class="col-md-6  {{this.h_fb}}">
                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="has_fb">
                                        <div class="state p-success">
                                            <label for="has_fb">FB</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>
                            <div class="col-md-6  {{this.has_wechat}}">

                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="has_wechat">
                                        <div class="state p-success">
                                            <label for="has_wechat">WeChat</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>

                            <div class="col-md-6  {{this.has_fax}}">

                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="has_fax">
                                        <div class="state p-success">
                                            <label for="has_fax">Line</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>

                            <div class="col-md-6  {{this.has_telegram}}">

                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="has_telegram">
                                        <div class="state p-success">
                                            <label for="has_telegram">Telegram</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>
                            <div class="col-md-6  {{this.i_tick}}">

                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="has_internal_ticket">
                                        <div class="state p-success">
                                            <label for="has_internal_ticket">Internal Ticket</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>
                            <div class="col-md-6 {{this.e_tic}}">

                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="has_external_ticket">
                                        <div class="state p-success">
                                            <label for="has_external_ticket">External Ticket</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>
                            <div class="col-md-6 {{this.h_webinar}}">

                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="has_webinar">
                                        <div class="state p-success">
                                            <label for="has_webinar">Webinar</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>
                            <div class="col-md-6 {{this.h_dialer_auto_answer}}">

                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="dialer_auto_answer">
                                        <div class="state p-success">
                                            <label for="dialer_auto_answer">Dialer Auto Answer</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>


                            <div class="col-md-6 {{this.wall_1}}">

                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="wallboard_one">
                                        <div class="state p-success">
                                            <label for="wallboard_one">Wallboard 1</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>

                            <div class="col-md-6 {{this.wall_2}}">

                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="wallboard_two">
                                        <div class="state p-success">
                                            <label for="wallboard_two">Wallboard 2</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>
                            <div class="col-md-6 {{this.wall_3}}">

                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="wallboard_three">
                                        <div class="state p-success">
                                            <label for="wallboard_three">Wallboard 3</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>
                            <div class="col-md-6 {{this.wall_4}}">

                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="wallboard_four">
                                        <div class="state p-success">
                                            <label for="wallboard_four">Wallboard 4</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div>

                            <!-- <div class="col-md-6 {{this.has_sso}}">

    <div class="form-check form-check-inline">
        <div class="pretty p-switch p-fill">
            <input type="checkbox"  formControlName="has_sso"  >
            <div class="state p-success">
                <label for="has_sso">SSO (Single Sign On)</label>
            </div>
        </div>
    </div>
    <div class="hidden-things">
        Please Upgrade your package
    </div>
</div>  -->
                            <div class="col-md-6 {{this.h_2fa}}">
                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="two_factor">
                                        <div class="state p-success">
                                            <label for="two_factor">2FA</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your packages
                                </div>
                            </div>


                            <!-- <div class="col-md-6 ">
                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="user_status">
                                        <div class="state p-success">
                                            <label for="user_status">Status</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="hidden-things">
                                    Please Upgrade your package
                                </div>
                            </div> -->



                            <div class="col-md-6 {{this.dsk_access}}" *ngIf="this.admin_id == '128'">
                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" formControlName="dsk_access" (change)="dskAccess('add')">
                                        <div class="state p-success">
                                            <label for="dsk_access">DKB Access</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row hidden-things">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="dsk_username">DKB Username</label>
                                            <input type="text" class="form-control" formControlName="dsk_username">
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="dsk_password">DKB Password</label>
                                            <input type="text" class="form-control" formControlName="dsk_password">
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>

                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="submit" class="btn btn-primary add_agent" value="Add Agent"
                            [disabled]="!addAgent.valid">
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>

<div class="edit_agent_data">
    <input type="hidden" value="0" id="edit_agents_key" #edit_agent_id>
    <input type="hidden" value="0" id="edit_agents_button" (click)="editAgentForm(edit_agent_id)">
    <!-- <input type="hidden" value="0" id="edit_agents_button" (click)="editAgentForm(edit_agent_id)"> -->

</div>








































<div class="modal fade bd-example-modal-md" id="edit_agents_form">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form [formGroup]="editAgent" (ngSubmit)="editAgentData()">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Update Agent Details</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="user_name">User Name <span style="color: red;">*</span></label>
                                <input type="text" id="user_name" class="form-control" formControlName="user_name"
                                    readonly>
                            </div>
                        </div>


                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="user_name">User password <span style="color: red;">*</span></label>
                                <span toggle="#update_user_pwd" class="fa fa-fw fa-eye field-icon "
                                    (click)="toggleClasss()"></span>

                                <input type="password" id="update_user_pwd" value="{{this.a_pass}}"
                                    class="form-control">
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="agent_name">Name</label>
                                <input type="text" id="agent_name" class="form-control" formControlName="agent_name">
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="sip_login">Ext / Auth</label>
                                <input readonly type="text" id="sip_login" class="form-control"
                                    formControlName="sip_login" #sip_login>
                            </div>
                        </div>





                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="sip_username">SIP Username</label>
                                <input readonly type="text" id="sip_username" class="form-control"
                                    formControlName="sip_username">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="sip_username">SIP Password</label>
                                <span toggle="#u_sip_password" class="fa fa-fw fa-eye field-icon "
                                    (click)="toggleClasss()"></span>

                                <input readonly type="password" id="u_sip_password" value="{{this.sip_pass}}"
                                    class="form-control" formControlName="sip_password">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <button type="button" style="margin-top: 30px;" class="btn btn-primary"
                                    title="Retrive Sip Details From 3CX" (click)="retriveFrom3cx(sip_login.value)"
                                    *ngIf="this.hardware_id !=''"><i class="fas fa-download"
                                        style="padding: 0px;"></i></button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="email_id">Email Id <span style="color: red;">*</span></label>
                                <input type="email" id="email_id" class="form-control" formControlName="email_id">
                                <label class="error-message message-small"
                                    *ngIf="!editAgent.get('email_id').valid && editAgent.get('email_id').touched ">Please
                                    enter the valid email id</label>

                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="phone_number">Phone No</label>
                                <input type="text" id="phone_number" class="form-control"
                                    formControlName="phone_number">

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="reports">Select Report</label>
                                <p *ngIf="this.no_report" style="color: #ec8787;">Please Upgrade your package</p>

                                <select *ngIf="!this.no_report" name="reports" multiple id="edit_reports"
                                    class="form-control has-multiple-select">
                                    <ng-container *ngFor="let items of list_reports;let i=index">
                                        <option *ngIf="report_id && report_id.indexOf(items.id) > -1; else notSelected"
                                            (click)="eventupdate()" value="{{items.id}}">{{items.report_name}}</option>
                                    </ng-container>
                                    <ng-template #notSelected></ng-template>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4" *ngIf="upd_agent_3cx_rep">
                            <div class="form-group">
                                <label for="reports">Call Recording group</label>
                                <select name="reports" id="upd_recording_grp" class="form-control">
                                    <option *ngFor="let items of agent_grp;let i=index" value="{{items.id}}">
                                        {{items.group_name}}</option>

                                </select>
                            </div>
                        </div>

                        <!-- <div class="col-md-12" *ngIf="this.voice_3cx != 'disabled'">

                                <div class="form-group">
                                  
                                    <div class="input-group">
                                        <label><b>Dialer Widget</b></label>
                                    <input type="text" id="copyClipboard"  class="form-control"  value="{{this.customHtmls}}">
                                    <span class="input-group-addon">
                                             <div class="settings-btn-group fl">
                                                     <a href="{{this.customHtmls}}" class="btn btn-warning" target="_blank"><i class="fas fa-external-link-alt"></i> Try it out!</a>

                                            </div>
                                    </span>
                                       
                                    </div>
                                </div>
                            </div>  -->


                        <!-- <div class="col-md-12" *ngIf="this.h_sms!='disabled'">
                        <div class="form-group">
                            <div class="input-group">
                                <label ><b>SMS Widget</b></label>
                            <input type="text" id="copyClipboard"  class="form-control"  value="{{this.smsChat}}">
                            <span class="input-group-addon">
                                     <div class="settings-btn-group fl">
                                              <a href="{{this.smsChat}}" class="btn btn-warning" target="_blank"><i class="fas fa-external-link-alt"></i>Try it out!</a>
                                    </div>
                            </span>
                            </div>
                        </div>
                    </div>  -->



                        <!-- <div class="col-md-12">
                        <div class="form-group">
                              <div class="input-group">
                                <label ><b>Internal Chat Widget</b></label>
                                
                            <input type="text" id="copyClipboard"  class="form-control"  value="{{this.InternalChat}}">
                            <span class="input-group-addon">
                                     <div class="settings-btn-group fl">
                            <a href="{{this.InternalChat}}" class="btn btn-warning" target="_blank"><i class="fas fa-external-link-alt"></i> Try it out!</a>
                                             
                                    </div>
                            </span>
                               
                            </div>
                        </div>
                    </div>  -->


                    </div>

                    <br />

                    <div class="row has-row-space">
                        <div class="col-md-6 ">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="admin_permisions" formControlName="admin_permision"
                                        [checked]="this.admin_permision">
                                    <div class="state p-success">
                                        <label for="admin_permision">Has Admin Permision</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 {{this.voice_3cx}}">

                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="voice_3cx" formControlName="voice_3cx"
                                        [checked]=" this.voice_3cx" (click)="getCallTariffsListe()">
                                    <div class="state p-success">
                                        <label for="voice_3cx">Voice 3CX</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>
                        <!-- <div class="col-md-12" *ngIf="showCallTariffsDetEdit">
                        <div class="row" >
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="MPlanName">Call Tariff</label>
                                    <select class="chat-widget form-control" name="eMPlanName" id="eMPlanName">
                                        <option  *ngFor="let item of callTariffs" value="{{item.id}}">{{item.plan_name}}</option> 
                                    </select> 
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="call_rate" >Call Rate</label>
                                    <input type="text"  class="form-control"  id="ecall_rate" >
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="ecall_prefix">Call Prefix</label>
                                    <input type="text"  class="form-control"  id="ecall_prefix" >
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="valid_from">Valid From</label>
                                    <input type="date"  class="form-control"   id="evalid_from">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="valid_to">Valid To</label>
                                    <input type="date" class="form-control"  id="evalid_to">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="e_tax_name">Tax Name</label>
                                    <input type="text"  class="form-control"  id="e_tax_name" >
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="e_tax_per">Tax Percentage</label>
                                    <input type="text"  class="form-control"  id="e_tax_per" >
                                </div>
                            </div>
                        </div>
                    </div> -->



                        <div class="col-md-6 ">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="close_all_menu" formControlName="close_all_menu">
                                    <div class="state p-success">
                                        <label for="close_all_menu">Close all menu</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 {{this.H_PD}}">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="predective_dialer" formControlName="predective_dialer"
                                        [checked]="this.H_PD">
                                    <div class="state p-success">
                                        <label for="predective_dialer">Predective Dialer</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>



                        <div class="col-md-6 {{this.H_PD}}">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="predective_dialer_behave"
                                        formControlName="predective_dialer_behave"
                                        [checked]=" this.predective_dialer_behave">
                                    <div class="state p-success">
                                        <label for="predective_dialer_behave">Predective Dialer Behaviour</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>

                        <!-- <div class="col-md-6 {{this.lead}}">

            <div class="form-check form-check-inline">
               <div class="pretty p-switch p-fill">
                   <input type="checkbox" id="lead" formControlName="lead"  [checked]=" this.lead">
                   <div class="state p-success">
                       <label for="lead">Lead</label>
                   </div>
               </div>
           </div>
           <div class="hidden-things">
            Please Upgrade your package
        </div>
    </div>  -->


                        <div class="col-md-6 {{this.h_con}}">

                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="has_contact" formControlName="has_contact">
                                    <div class="state p-success">
                                        <label for="has_contact">Contact</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>
                        <div class="col-md-6 {{this.h_sms}}">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="has_sms" formControlName="has_sms">
                                    <div class="state p-success">
                                        <label for="has_sms">SMS</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>
                        <div class="col-md-6 {{this.h_chat}}">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="has_chat" formControlName="has_chat">
                                    <div class="state p-success">
                                        <label for="has_chat">Web Chat</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>

                        <div class="col-md-6 {{this.h_int_chat}}">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="has_int_chat" formControlName="has_internal_chat">
                                    <div class="state p-success">
                                        <label for="has_chat">Internal Chat</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>

                        <div class="col-md-6 {{this.h_wp}}">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="has_whatsapp" formControlName="has_whatsapp">
                                    <div class="state p-success">
                                        <label for="has_whatsapp">Whatsapp</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>
                        <div class="col-md-6 {{this.h_cbot}}">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="has_chatbot" formControlName="has_chatbot">
                                    <div class="state p-success">
                                        <label for="has_chatbot">Chatbot</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>
                        <div class="col-md-6 {{this.h_fb}}">

                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="has_fb" formControlName="has_fb">
                                    <div class="state p-success">
                                        <label for="has_fb">FB</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>

                        <div class="col-md-6 {{this.has_wechat}}">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="has_wechat" formControlName="has_wechat"
                                        [checked]=" this.has_wechat">
                                    <div class="state p-success">
                                        <label for="has_wechat">WeChat</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>

                        <div class="col-md-6 {{this.has_fax}}">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="has_fax" formControlName="has_fax"
                                        [checked]=" this.has_fax">
                                    <div class="state p-success">
                                        <label for="has_fax">Line</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>


                        <div class="col-md-6 {{this.has_telegram}}">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="has_telegram" formControlName="has_telegram"
                                        [checked]=" this.has_telegram">
                                    <div class="state p-success">
                                        <label for="has_telegram">Telegram</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>
                        <div class="col-md-6 {{this.i_tick}}">

                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="has_internal_ticket"
                                        formControlName="has_internal_ticket">
                                    <div class="state p-success">
                                        <label for="has_internal_ticket">Internal Ticket</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>
                        <div class="col-md-6 {{this.e_tic}}">

                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="has_external_ticket"
                                        formControlName="has_external_ticket">
                                    <div class="state p-success">
                                        <label for="has_external_ticket">External Ticket</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>

                        <div class="col-md-6 {{this.h_webinar}}">

                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="has_webinar" formControlName="has_webinar">
                                    <div class="state p-success">
                                        <label for="has_webinar">Webinar</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>
                        <div class="col-md-6 {{this.voice_3cx}} has-tooltip">
                            <span class="tooltip-modal" style="left: 50px;right: 50px;"> Incoming call will be answered
                                automatically after 5 seconds</span>
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="dialer_auto_answer" formControlName="dialer_auto_answer">
                                    <div class="state p-success ">
                                        <label for="dialer_auto_answer">
                                            Dialer Auto Answer
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>

                        <div class="col-md-6 {{this.wall_1}}">

                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="wallboard_one" formControlName="wallboard_one">
                                    <div class="state p-success">
                                        <label for="wallboard_one">Wallboard 1</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>

                        <div class="col-md-6 {{this.wall_2}}">

                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="wallboard_two" formControlName="wallboard_two">
                                    <div class="state p-success">
                                        <label for="wallboard_two">Wallboard 2</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>
                        <div class="col-md-6 {{this.wall_3}}">

                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="wallboard_three" formControlName="wallboard_three">
                                    <div class="state p-success">
                                        <label for="wallboard_three">Wallboard 3</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>
                        <div class="col-md-6 {{this.wall_4}}">

                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="wallboard_four" formControlName="wallboard_four">
                                    <div class="state p-success">
                                        <label for="wallboard_four">Wallboard 4</label>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>
                        </div>
                        <!-- <div class="col-md-6 {{this.has_sso}}">

    <div class="form-check form-check-inline">
        <div class="pretty p-switch p-fill">
            <input type="checkbox" id="has_sso" formControlName="has_sso"  [checked]=" this.has_sso">
            <div class="state p-success">
                <label for="has_sso">SSO (Single Sign On)</label>
            </div>
        </div>
    </div>
    <div class="hidden-things">
        Please Upgrade your package
    </div>
</div>  -->
                        <div class="col-md-6 {{this.h_2fa}}">

                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="two_factor" formControlName="two_factor">
                                    <div class="state p-success">
                                        <label for="two_factor">2FA</label>
                                    </div>
                                </div>
                            </div>

                            <div class="hidden-things">
                                Please Upgrade your package
                            </div>


                        </div>


                        <!-- <div class="col-md-6">

                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="user_status" formControlName="user_status">
                                    <div class="state p-success">
                                        <label for="user_status">Status</label>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-md-6 {{this.dsk_access}}" *ngIf="this.admin_id == '128'">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="dsk_access" formControlName="dsk_access"
                                        (change)="dskAccess('edit')">
                                    <div class="state p-success">
                                        <label for="dsk_access">DKB Access</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row hidden-things">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="dsk_username">DKB Username</label>
                                        <input type="text" id="dsk_username" class="form-control"
                                            formControlName="dsk_username">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="dsk_password">DKB Password</label>
                                        <input type="text" id="dsk_password" class="form-control"
                                            formControlName="dsk_password">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="hidden" formControlName="user_id">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="submit" class="btn btn-primary update_agent" value="Update Agent"
                            [disabled]="!editAgent.valid">
                        <!-- <input type="button" class="btn btn-primary" value="Update Billing Address" (click)="edit_billing_address()"> -->
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>












<input type="hidden" name="AgentsFrom3cx" id="AgentsFrom3cx" (click)="openAgentsFrom()">


<!-------------------------------
Modal Add Agent
-------------------------------->
<div class="modal fade bd-example-modal-md" id="addAllAgentsFrom3cx">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Select agents</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <table>
                            <tr>
                                <td class="search-text-tbl">

                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="row has-row-space">
                    <div class="col-md-12">
                        <div class="addAgent">
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped table-md">

                                    <tr>
                                        <th style="width: 20px;">

                                        </th>
                                        <th>Extension</th>
                                        <th>First Name</th>
                                    </tr>

                                    <tbody>
                                        <tr *ngFor="let item of dynamicUsers">
                                            <td style="width: 20px;">
                                                <div class="pretty p-default">
                                                    <input value={{item.sip_login}} type="checkbox" name="all3cxUsers"
                                                        class="all3cxUsers"
                                                        [attr.disabled]="sip_logins_main && sip_logins_main.indexOf(item.sip_login) == -1 ? null :'disabled'"
                                                        [attr.checked]="sip_logins_main && sip_logins_main.indexOf(item.sip_login) == -1 ? null: checked">
                                                    <ng-template #checked></ng-template>
                                                    <div class="state p-primary">
                                                        <label></label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{item.sip_login}}</td>
                                            <td>{{item.agent_name}}</td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="buttons">
                    <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                    <input type="submit" class="btn btn-primary update_agent" value="Update" (click)="postdatatoDB()">
                </div>
            </div>
        </div>
    </div>
</div>






<div class="modal fade bd-example-modal-md" style="overflow-y: scroll;" id="edit_billing_address">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Update Billing Address</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="econtact_person">Contact Person *</label>
                            <input type="text" id="econtact_person" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="e_monthly_charges">Monthly Charges</label>
                            <input type="number" id="e_monthly_charges" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="e_discount_per">Discount Percentage</label>
                            <input type="text" id="e_discount_per" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="eadd1">Address 1 *</label>
                            <input type="text" id="eadd1" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="eadd2">Address 2</label>
                            <input type="text" id="eadd2" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="ecity">City *</label>
                            <input type="text" id="ecity" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="estate">State *</label>
                            <input type="text" id="estate" class="form-control">
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="ecountry">Country *</label>
                            <input type="text" id="ecountry" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="ezip_code">Zip Code *</label>
                            <input type="text" id="ezip_code" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <label><b>Edit Shipping Address (cilck to edit shipping address)</b></label>
                            </div>
                        </div>
                        <div class="form-check form-check-inline">
                            <div class="pretty p-switch p-fill">
                                <input type="checkbox" id="eedit_ship" value="1" (click)="editShippingAddress()"
                                    #eedit_ship>
                                <div class="state p-success">
                                    <label for="eedit_ship"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><br> <br>
                <div class="row" id="editShippingAddresss" style="display:none">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="eship_contact">Contact Person *</label>
                            <input type="text" id="eship_contact" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="eship_to">Ship To *</label>
                            <input type="text" id="eship_to" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="eship_add1">Address 1 *</label>
                            <input type="text" id="eship_add1" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="ship_add2">Address 2</label>
                            <input type="text" id="eship_add2" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="ship_city">City *</label>
                            <input type="text" id="eship_city" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="eship_state">State *</label>
                            <input type="text" id="eship_state" class="form-control">
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="eship_country">Country *</label>
                            <input type="text" id="eship_country" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="eship_zip">Zip Code *</label>
                            <input type="text" id="eship_zip" class="form-control">
                        </div>
                    </div>


                </div>

            </div>

            <div class="modal-footer">
                <div class="buttons">
                    <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                    <input type="button" (click)="editShippingAddressMain()" class="btn btn-primary update_agent"
                        value="Update">
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal fade bd-example-modal-md" id="addLicence">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Activate 3CX</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="about-app">
                            <img src="assets/images/omni-channels-logo.jpg">
                        </div>
                        <div class="version-details">
                            <h5>Please, enter your Mr.VoIP Licence Key and press Submit or contact Admin</h5>
                        </div>
                        <div class="col-md-12 col-12">
                            <div class="form-group">
                                <label for="widget-name">Mr.VoIP Licence Key</label>
                                <input type="text" class="form-control" name="licence_key" id="licence_key">
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="modal-footer">
                <div class="buttons">
                    <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                    <input type="button" class="btn btn-primary update_agent" value="Submit"
                        (click)="activateLicenseKey()">
                </div>
            </div>

        </div>
    </div>
</div>


<div class="modal fade bd-example-modal-md" id="showGeneratedPdf">
    <div class="modal-dialog modal-md" style="max-width: 1200px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myLargeModalLabel">Invoice</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="min-height: 800px;">
                <embed [src]="this.pdfurl | safe: 'resourceUrl'" type="application/pdf" width="100%"
                    style="height: 575px;" />
            </div>



            <div class="modal-footer">
                <div class="buttons">
                    <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="close">
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade bd-example-modal-md" id="document_model">
    <div class="modal-dialog modal-md" style="max-width: 1200px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" style="color:crimson">Help Manual</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <!-- <iframe [src]="this.doc_link | safe: 'resourceUrl'"  width="100%" style="height: 575px;" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                <!-- style="margin-bottom: -6px;" width="100%" height="325"  -->
                <embed [src]="this.doc_link | safe: 'resourceUrl'" width="100%" style="height: 575px;" />
            </div>
            <div class="modal-footer">
                <div class="buttons">
                    <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="close">
                </div>
            </div>

        </div>
    </div>
</div>