<div class="section-body">
	<div class="row">
		<div class="col-xs-12 col-sm-12 col-xl-3">
			<div class="card fixedTop">
				<div class="body padding-15">
					<div id="mail-nav">
						<div class="search m-10">
							<input type="text" class="form-control" placeholder="Search...">
						</div>
						<ul class="ticket-reply-action-group">
							<li>
								<div class="form-group">
									<select class="form-control">
										<option value="Open">Open</option>
										<option value="In Progress">In Progress</option>
										<option value="Closed">Closed</option>
										<option value="Resolved">Resolved</option>
									</select>
								</div>
							</li>

							<li>
								<div class="green-bg-status status-bg"></div><strong class="text-uppercase">First Response Due</strong>
								<p><small>by Sat, 21 Mar 2020, 05:00 AM</small></p>
							</li>
							<li>
								<div class="red-bg-status status-bg"></div><strong class="text-uppercase"> Resolution Due</strong>
								<p><small>by Tue, 31 Mar 2020, 04:59 AM</small></p>
							</li>
						</ul>
						<a href="javascript:;" class="btn btn-success waves-effect btn-compose text-uppercase">Update</a>
					</div>
				</div>
			</div>
		</div>
		<div class="col-xs-12 col-sm-12 col-xl-9">
			<div class="card">
				<div class="boxs mail_listing">
					<div class="inbox-center table-responsive">
						<table class="noMargin table table-hover">
							<thead>
								<tr>
									<th colspan="1">
										<div class="inbox-header">
											<div class="animated-checkbox">
												<input type="checkbox" id="checkbox-2" />
												<label for="checkbox-2" class="check-box"></label> 
											</div>
										</div>
									</th>
									<th>
										<div class="email-btn-group">
										<button class="btn btn-secondary"><i class="fas fa-reply"></i> Reply</button>&nbsp;
										<button class="btn btn-secondary"><i class="fas fa-sticky-note"></i> Add Note</button>&nbsp;
										<button class="btn btn-secondary"><i class="fas fa-share"></i>Forward</button>&nbsp; &nbsp; &nbsp;
										<button class="btn btn-secondary"><i class="far fa-check-circle"></i> Close</button>
									</div>
									</th>
									<th>
										
									</th>

								</tr>
							</thead>
						</table>
					</div>
					<div class="row">
						<div class="col-lg-12">
							<div class="composeForm">
								<h6><span class="blk-clr">Covid-19, cellulari e privacy</span></h6>
								<p><strong>#580145</strong></p>
								<div class="form-group">
									<div class="form-line">
										<input type="text" id="email_address" class="form-control" placeholder="TO">
									</div>
								</div>
								<div class="form-group">
									<div class="form-line">
										<input type="text" id="email_address" class="form-control" placeholder="Bcc">
									</div>
								</div>
								<div class="form-group">
									<div class="form-line">
										<input type="text" id="subject" class="form-control" placeholder="Subject">
									</div>
								</div>

								<div class="form-group">
									<textarea placeholder="Message" class="form-control"></textarea>
								</div>

								<div class="compose-editor m-t-20">
									<div id="summernote"></div>
									<input type="file" class="default" multiple>
								</div>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="m-l-25 m-b-20">
								<button type="button" class="btn btn-success btn-border-radius waves-effect">Send</button>
								&nbsp;&nbsp;
								<button type="button" class="btn btn-danger btn-border-radius waves-effect">Discard</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>