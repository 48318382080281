   

   <div class="section-body edit-form">

    <div class="row">

        <div class="col-12 col-xl-7">
            <div class="card">
                <div class="card-header">
                    <h4>Upload SMS CSV</h4>
                </div>
                <div class="card-body p-0">
                    <div class="form-panel">
                        <div class="row">

                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="department">Select SMS Group</label>
                                    <select name="group_name"  id="group_name" class="form-control">
                                        <option value='0'> Select Department </option>
                                        <option *ngFor="let dept of queue_list" value="{{dept.group_name}}">{{dept.group_name}}</option>
                                    </select>
                                </div>
                            </div>


                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="contact_owner">CSV file</label>
                                    <input type="file" class="form-control" name="file" id="file" size="150">
                                </div>
                            </div>


                            <div class="col-md-6">
                                <div class="form-group">
                                    <button  id="submit" name="import"
                                    class="btn btn-primary" (click)="upload()">Upload Csv</button>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <a href="https://uatassaabloyccapi.mconnectapps.com/api/excel.csv">Click Here To Download Sample CSV Format</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
