<div class="section-body">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Wrap Up Codes</h4>
                    <div class="addBtn">
                        <button type="button" class="btn btn-primary" (click)="addDepartment()">+ Add Wrap Up
                            Code</button>
                    </div>
                    <!-- <a (click)="showdoc('https://uatassaabloyccapi.mconnectapps.com/api/v1.0/pro_contents/pro_wrapup.pdf')"
                        target="_blank"><i style="font-size: 24px;
                        float: right;" class="fa fa-question-circle"></i></a> -->
                    <!-- <a style="margin-left: 20px;" href="https://uatassaabloyccapi.mconnectapps.com/api/v1.0/pro_contents/pro_wrapup.pdf" target="_blank"><i style="font-size:24px;" class="fa fa-exclamation-circle"></i></a> -->

                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-md">

                            <tr>
                                <th>#</th>
                                <!-- <th>Wrap Up ID</th> -->
                                <th>Wrap Up Category</th>
                                <th>Wrap Up Code</th>
                                <th>Action</th>
                            </tr>

                            <tbody>
                                <tr *ngFor="let queue of new_queue_lists; let i=index">
                                    <td>{{0+i+1}}</td>
                                    <!-- <td>{{queue.id}}</td> -->
                                    <td>{{queue.category_name}}</td>
                                    <!-- <td>{{queue.auxcode_name}}</td> -->
                                    <td>
                                        <ul class="badge-list">
                                            <li *ngFor="let item of queue.auxcode_name"><span class="badge">{{item}}</span></li>
                                            <!-- <li><span class="badge">Code 2</span></li>
                                            <li><span class="badge">Code 3</span></li>
                                            <li><span class="badge">Code 4</span></li>
                                            <li><span class="badge">Code 5</span></li>
                                            <li><span class="badge">Code 6</span></li>
                                            <li><span class="badge">Code 7</span></li>
                                            <li><span class="badge">Code 8</span></li> -->
                                        </ul>
                                    </td>
                                    <td>
                                        <span  class="user-icon" style="cursor: pointer;"><i class="far fa-edit"
                                                (click)="editDepartmentSettings(queue.cat_id)"></i></span>
                                        <span class="user-icon" style="cursor: pointer;"><i class="far fa-trash-alt"
                                                (click)="deletedata(queue.cat_id)"></i></span>
                                    </td>
                                </tr>

                                <tr *ngIf="recordNotFound == true">
                                    <td colspan="12">Data not found</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>




<div class="modal fade bd-example-modal-md" id="edit_deptform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="editDept" (ngSubmit)="editDepartment(dep_id)">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Update Wrap Up Code</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="row">

                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="department">Category</label>
                                <select name="catecodes" id="catecodes" class="form-control">
                                    <option value='0'> Select Category </option>
                                    <option *ngFor="let cat of cat_query_list" value="{{cat.id}}">
                                        {{cat.category_name}}</option>
                                </select>
                            </div>
                        </div>


                        <!-- <div class="col-md-12">
                            <div class="form-group">
                                <label for="department_name">Wrap Up Code</label>
                                <input type="text" id="department_name" class="form-control"
                                    formControlName="department_name">
                            </div>
                        </div> -->

                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="department_name">Wrap Up Code <span style="color: brown;font-size: 12px;"> (By click <b>Enter </b> Add multiple Codes) </span></label>
                                <!-- <input type="text" id="department_name" class="form-control"
                                    formControlName="department_name"> -->

                                    <mat-form-field class="form-controls" appearance="none">
                                        <mat-chip-list    #editaddWrapCode>
                                          <mat-chip *ngFor="let wrapCode of editwrapUpCode" [selectable]="selectable"
                                                   [removable]="removable" (removed)="editremove(wrapCode)">
                                            {{wrapCode.name}}
                                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                          </mat-chip>
                                          <input  placeholder=""
                                                 [matChipInputFor]="editaddWrapCode"
                                                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                 [matChipInputAddOnBlur]="addOnBlur"
                                                 (matChipInputTokenEnd)="editadd($event)">
                                        </mat-chip-list>
                                      </mat-form-field>

                            </div>
                            <span *ngIf="show_email_errors"
                            style="margin: 10px;color: #ff0000d4;font-weight: 600;">{{email_error_msg}} </span>
                        </div>


                        <!-- <div class="col-md-12">
                            <div class="form-group">
                                <label for="status">Status</label>
                                <input type="checkbox" id="status" class="form-control" formControlName="status">
                            </div>
                        </div> -->
                    </div>

                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <!-- <input type="submit" class="btn btn-primary update_agent" value="Update"
                            [disabled]="!editDept.valid"> -->
                        <input type="submit" class="btn btn-primary update_agent" value="Update"
                            >
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>






<div class="modal fade bd-example-modal-lg" id="add_deptform">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form [formGroup]="addDept" (ngSubmit)="addDeptData()">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Add Wrap Up Code</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="add_cat_id">Category</label>
                                <select name="add_cat_id" id="add_cat_id" class="form-control">
                                    <option value=''> Select Category </option>
                                    <option *ngFor="let cat of cat_query_list" value="{{cat.id}}">
                                        {{cat.category_name}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="department_name">Wrap Up Code (By click Enter Add multiple Codes)</label>
                                <!-- <input type="text" id="department_name" class="form-control"
                                    formControlName="department_name"> -->

                                    <mat-form-field class="form-controls" appearance="none">
                                        <mat-chip-list #addWrapCode>
                                          <mat-chip *ngFor="let wrapCode of wrapUpCode" [selectable]="selectable"
                                                   [removable]="removable" (removed)="remove(wrapCode)" >
                                            {{wrapCode.name}}
                                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                          </mat-chip>
                                          <input  placeholder=""
                                                 [matChipInputFor]="addWrapCode"
                                                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                 [matChipInputAddOnBlur]="addOnBlur"
                                                 (matChipInputTokenEnd)="add($event)">
                                        </mat-chip-list>
                                      </mat-form-field>

                            </div>
                            <p class="error-colors" *ngIf="this.presentEmails == true;">This Wrap code Already entered</p>
                        </div>



                        <!-- <div class="col-md-12">
                            <div class="form-group">
                                <label for="status">Status</label>
                                <input type="checkbox" id="status" class="form-control" formControlName="status">
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <!-- <input type="submit" class="btn btn-primary update_agent" value="Update"
                            [disabled]="!addDept.valid"> -->
                        <input type="submit" class="btn btn-primary update_agent" value="Update"
                            >
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>

<div class="modal fade bd-example-modal-md" id="document_model">
    <div class="modal-dialog modal-md" style="max-width: 1200px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" style="color:crimson">Help Manual</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <!-- <iframe [src]="this.doc_link | safe: 'resourceUrl'"  width="100%" style="height: 575px;" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                <!-- style="margin-bottom: -6px;" width="100%" height="325"  -->
                <embed [src]="this.doc_link | safe: 'resourceUrl'" width="100%" style="height: 575px;" />
            </div>
            <div class="modal-footer">
                <div class="buttons">
                    <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="close">
                </div>
            </div>

        </div>
    </div>
</div>



<!-- =================================== CATEGORY TABLE ========================================== -->


<div class="section-body">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Wrap Up Code Category</h4>
                    <div class="addBtn">
                        <button type="button" class="btn btn-primary" (click)="addCategory()">+ Add Category
                            Code</button>
                    </div>
                    <a (click)="showdoc('https://uatassaabloyccapi.mconnectapps.com/api/v1.0/pro_contents/pro_wrapup.pdf')"
                        target="_blank"><i style="font-size: 24px;
                        float: right;" class="fa fa-exclamation-circle"></i></a>
                    <!-- <a style="margin-left: 20px;" href="https://uatassaabloyccapi.mconnectapps.com/api/v1.0/pro_contents/pro_wrapup.pdf" target="_blank"><i style="font-size:24px;" class="fa fa-exclamation-circle"></i></a> -->

                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-md">

                            <tr>
                                <th># </th>
                                <!-- <th>Catagory ID</th> -->
                                <th>Catagory Name</th>
                                <th>Department</th>
                                <th>Action</th>
                            </tr>

                            <tbody>
                                <tr *ngFor="let queue of new_cat_query_list; let i=index">
                                    <td>{{0+i+1}}</td>
                                    <!-- <td>{{queue.id}}</td> -->
                                    <td>{{queue.category_name}}</td>
                                    <td>
                                        <ul class="badge-list">
                                            <li *ngFor="let item of queue.department_name"><span class="badge">{{item}}</span></li>
                                            <!-- <li><span class="badge">Code 2</span></li>
                                            <li><span class="badge">Code 3</span></li>
                                            <li><span class="badge">Code 4</span></li>
                                            <li><span class="badge">Code 5</span></li>
                                            <li><span class="badge">Code 6</span></li>
                                            <li><span class="badge">Code 7</span></li>
                                            <li><span class="badge">Code 8</span></li> -->
                                        </ul>
                                    </td>
                                    <td>
                                        <span
                                            class="user-icon" style="cursor: pointer;"><i class="far fa-edit"
                                                (click)="editCat(queue.id)"></i></span>
                                        <span
                                            class="user-icon" style="cursor: pointer;"><i class="far fa-trash-alt"
                                                (click)="deleteCat(queue.id)"></i></span>
                                    </td>
                                </tr>

                                <tr *ngIf="recordNotFound == true">
                                    <td colspan="12">Data not found</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


<div class="modal fade bd-example-modal-md" id="add_catogoryform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="addCat" (ngSubmit)="addCatData()">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Add Catagory Code</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="cat_name">Wrap Up Code Catagory Name</label>
                                <input type="text" id="cat_name" class="form-control" formControlName="cat_name">
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="newdepartments">Department <span style="color: brown;font-size: 12px;">( <b>CTRL + CLICK </b> to select multiple department)</span></label>
                                <select style="height:250px;" name="newdepartments" id="newdepartments" class="form-control" multiple>
                                    <!-- <option value='0'> Select Department </option> -->
                                    <option *ngFor="let dp of dept_name_list" value="{{dp.dept_id}}">
                                        {{dp.department_name}}</option>
                                </select>
                            </div>
                        </div>

                        <!-- <div class="col-md-12">
                            <div class="form-group">
                                <label for="status">Status</label>
                                <input type="checkbox" id="status" class="form-control" formControlName="status">
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="submit" class="btn btn-primary update_agent" value="Update"
                            [disabled]="!addCat.valid">
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>


<div class="modal fade bd-example-modal-md" id="edit_catform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="editCategory" (ngSubmit)="UpdateCat(cate_id)">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Update Category Code</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="row">

                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="cat_name">Wrap Up Category Name</label>
                                <input type="text" id="cat_name" class="form-control" formControlName="cat_name">
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="departments">Department <span style="font-size: 12px;color: coral;">
                                    <!-- (press <b>CTRL+CLICK</b> to select multiple department) -->
                                 </span></label>
                                <select style="height: 250px;" name="departments" id="departments" class="form-control" multiple>
                                <!-- <select  name="departments" id="departments" class="form-control" multiple>  -->
                                    <!-- <option value='0'> Select Department </option> -->
                                    <option *ngFor="let dp of dept_name_list" value="{{dp.dept_id}}">
                                        {{dp.department_name}}</option>
                                </select>
                            </div>
                        </div>

                        <!-- <div class="col-md-12">
                            <div class="form-group">
                                <label for="status">Status</label>
                                <input type="checkbox" id="status" class="form-control" formControlName="status">
                            </div>
                        </div> -->
                    </div>

                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="submit" class="btn btn-primary update_agent" value="Update"
                            [disabled]="!editCategory.valid">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>