<div class="loader"></div>
<div class="main-body-content">

    <div id="app">
        <div class="main-wrapper main-wrapper-1 main_container" *ngIf="templateAuthView == false">
            <div class="navbar-bg"></div>
            <app-menu></app-menu>
            <div class="main-content" *ngIf="templateAuthView == false">
                <section class="section">
                    <router-outlet (activate)="onActivate($event)"></router-outlet>
                </section>
                <!-- //<app-setting-menu></app-setting-menu> -->
            </div>
            
          <app-footer></app-footer>
            <!-- <app-queueform *ngIf="file_path == '/queue'"></app-queueform> -->
            <!-- <app-agentsform *ngIf="file_path == '/agents'"></app-agentsform> -->
        </div>

        <div class="main-body-auth" *ngIf="templateAuthView == true">
            <router-outlet (activate)="onActivate($event)"></router-outlet>
        </div>

    </div>
</div>
