<div class="card padding-15" >
	<div class="row">
	  <div class="col-md-9">
		<a routerLink="/ticketing-system-new" href="#" class="badge badge-success mr-2">
		  <span class="icon">
			<i class="fas fa-ticket-alt"></i>
		  </span>
		  &nbsp; My Tickets
		</a>
		<a href="#" routerLink="/unassinged-tickets" class="badge badge-warning mr-2">
		  <span class="icon">
			<i class="fas fa-ticket-alt"></i>
		  </span>
		  &nbsp; Queue Tickets
		</a>

		<a *ngIf="this.user_type==2" href="#" routerLink="/ticket-template" class="badge badge-primary mr-2 ">
		  <span class="icon">
			<i class="fas fa-palette"></i>
		  </span>
		  &nbsp; Templates
		</a>

		<a *ngIf="this.user_type==2" routerLink="/email-settings" class="badge badge-info mr-2">
		  <span class="icon">
			<i class="fas fa-cog"></i>
		  </span>
		  &nbsp; Settings
		</a>

		<a *ngIf="user_type==2" href="#" routerLink="/spam-list" class="badge badge-danger mr-2 pointer-event-none">
		  <span class="icon">
			<i class="fas fa-user-times"></i>
		  </span>
		  &nbsp; Spam/Deleted
		</a>

	  </div>	 
	</div>
	</div>
<div class="section-body">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Email Spam list</h4>
                    <div class="addBtn">
                        <button type="button" class="btn btn-primary" (click)="addSpam()">+ Add Spam</button>
                    </div>             
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-md">
                          
                            <tr>
                                <th>#</th>
                                <!-- <th>Wrap Up ID</th> -->
                                <th>Mail ID</th>
                                <th>Spam and BlackList</th>
                                <!-- <th>Blacklist</th> -->
                                <th>Action</th>
                            </tr>

                            <tbody>

                                <tr *ngFor="let sp of spamlist; let i=index">
                                    <td *ngIf="sp.spam_status == 1">{{0+i+1}}</td>
                                    <!-- <td>{{queue.id}}</td> -->
                                    <td *ngIf="sp.spam_status == 1">{{sp.email}}</td>
                                    <!-- <td>{{queue.auxcode_name}}</td> -->
                                    <td *ngIf="sp.spam_status == 1">
                                        <!-- <ul class="badge-list" *ngIf="sp.spam_status == 1">
                                            <li><span class="badge">Active</span></li>
                                        </ul> -->
                                        <div class="form-check form-check-inline">
                                            <div class="pretty p-switch p-fill">
                                                <input type="checkbox"
                                                    checked="{{sp.spam_status == 1 ? 'checked' : ''}}"
                                                    (change)="changespamStatus('spam',$event, sp.email)">
                                                <div class="state p-success">
                                                    <label for="has_status"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </td>                                  
                                    <td *ngIf="sp.spam_status == 1">
                                        <span class="user-icon" style="cursor: pointer;"><i class="far fa-trash-alt"
                                                (click)="deletespam(sp.email)"></i></span>
                                    </td>
                                </tr>

                                <!-- <tr *ngIf="recordNotFound == true">
                                    <td colspan="12">No Data found</td>
                                </tr> -->
                            </tbody>

                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>



<!--------------------------------------
	Ticket Table View
	---------------------------------------->
    <div id="accordion" class="accordion">
        <div class="card mb-0">
            <div class="card-header show" data-toggle="collapse" href="#collapseOne">
                <h4>View Spam Emails</h4>
            </div>
            <div id="collapseOne" class="card-body collapse show" data-parent="#accordion" >
              
                <div id="table_views">
                    <div class="card ticket-table-view">
                        <div class="card-header">
                            <h4>Spam Emails</h4>
                            <div class="addBtn" style="padding-right: inherit;">
                                <a href="javascript:void(0)" class="btn btn-danger" (click)="deleteSpampermenent()" style="width: 40px;font-size: 15px;"
                                 title="Delete Ticket"><i class="fas fa-trash-restore"></i> </a>
                            </div>        
                        </div>
                        <div class="card-body p-0">
                            <!-- <div class="modal fade" id="testModal"> -->
                            <div id="loading" class="loading-hide"></div>
                            <!-- </div> -->
                            <table class="table table-striped table-md" style="color: #000;">
                                <tr class="has-color-label-bg">
                                    <td colspan="12" class="search-text-tbl">
                                        <div class="input-group"><input type="text" class="form-control" (keyup.enter)="my_spamtickets({'search_text':spam_search.value})" #spam_search>
                                            <div class="input-group-append">
                                                <button class="btn btn-primary" (click)="my_spamtickets({'search_text':spam_search.value})"><i class="fas fa-search"></i></button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="ticket-table-header">
                                    <th><input type="checkbox" (click)="slectAllSpam()" id="selectAllSpam" name="checkbox"></th>                            
                                    <th>Subject</th>
                                    <th style="padding-left: 20px;">State</th>
                                    <!-- <th>Group</th> -->
                                    <th>Department</th>
                                    <th style="padding-left: 20px;">Priority</th>
                                    <th>Status</th>
                                </tr>
                
                
                
                
                                <tbody>
                                    <tr *ngFor="let tickets of new_queue_list; let i = index"
                                        [ngClass]="{'closed-ticket' : tickets.ticket_status =='Closed','unread-ticket':tickets.ticket_status != 'Closed'}">
                                        <td><input (click)="slectuniqueSpam()" type="checkbox" [ngStyle]="{'position':tickets.ticket_status =='Closed' ? 'absolute' : '' }"   value="{{tickets.ticket_no}}"
                                                id="{{tickets.ticket_no}}" name="emailtickets" class="emailtickets" name="checkbox">
                                        </td>                                        
                                        <td (click)="viewMyTicket(tickets.ticket_no)">
                                            <a href="javascript:;" class="pointer fl">
                                                <span [ngClass]="(tickets.ticket_new_status == '1')? 'blk-clr':''">{{tickets.subject}}
                                                </span>
                                                <div style="font-size: 12px;color: gray;" clsass="ticket-id dark-grey-clr">
                                                    {{tickets.ticket_created_at}}
                                                </div>
                                            </a>
                                        </td>
                                        <td style="width: 19%;">
                                            <div class="status-tag-wrap status-wrapper">
                                                <span
                                                    [class]="tickets.ticket_status == 'Active' ? 'tag-active tag' : tickets.ticket_status == 'In Active' ? 'tag-inactive tag' :  tickets.ticket_status == 'New' ? 'tag-new tag' :  tickets.ticket_status == 'Pending' ? 'tag-pending tag': tickets.ticket_status == 'Un Assigned' ? 'tag-UnAssigned tag': tickets.ticket_status == 'Assigned' ? 'tag-Assigned tag': tickets.ticket_status == 'Progress' ? 'tag-UnAssigned tag': tickets.ticket_status == 'Completed' ? 'tag-Completed tag':tickets.ticket_status == 'Archived' ? 'tag-archived tag':tickets.ticket_status == 'Closed' ? 'tag-closed tag':'tag-new tag'">
                                                    {{tickets.ticket_status}}
                                                </span>
                                                <span
                                                    *ngIf="tickets.closed_at !='' && tickets.closed_at !=null && tickets.ticket_status != 'Closed'"
                                                    class="tag-reopened tag"> Re Opened</span>
                                            </div>
                                        </td>
                                        <td style="width: 30%;">
                                            <div class="form-group fl">
                                                <div class="dropdown select-option" style="display: flex;">
                                                    <div class="select-option-label" class="dropdown-toggle">
                                                        <span class="dropdown-label-icon"> {{tickets.department}}</span>
                                                    </div>
                                                   
                                                    <div *ngIf="tickets.ticket_assigned_to !='' && tickets.ticket_assigned_to != null"
                                                        class="status-tag-wrap status-wrapper">
                                                        <span style="margin-left: 27px;	font-size: 11px;"
                                                            [class]="tickets.ticket_status == 'Active' ? 'tag-active tag' : tickets.ticket_status == 'In Active' ? 'tag-inactive tag' :  tickets.ticket_status == 'New' ? 'tag-new tag' :  tickets.ticket_status == 'Pending' ? 'tag-pending tag': tickets.ticket_status == 'Un Assigned' ? 'tag-UnAssigned tag': tickets.ticket_status == 'Assigned' ? 'tag-Assigned tag': tickets.ticket_status == 'Progress' ? 'tag-UnAssigned tag': tickets.ticket_status == 'Completed' ? 'tag-Completed tag':tickets.ticket_status == 'Archived' ? 'tag-archived tag':tickets.ticket_status == 'Closed' ? 'tag-closed tag':'tag-new tag'">
                                                            {{tickets.ticket_assigned_to}}
                                                        </span>
                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                
                
                                        <td>
                                            <div class="form-group fl">
                                                <div class="dropdown select-option">
                                                    <div class="select-option-label"  class="dropdown-toggle">
                                                        <span class="dropdown-label-icon">
                                                            <span
                                                                [class]="tickets.priority == 'Low' ? 'green-bg-status status-bg' : tickets.priority == 'Medium' ? 'blue-bg-status status-bg' :  tickets.priority == 'High' ? 'yellow-bg-status status-bg' :  tickets.priority == 'Urgent' ? 'red-bg-status status-bg':'' "></span>
                                                            {{tickets.priority}}
                                                        </span>
                                                    </div>                                   
                                                </div>
                                            </div>
                                        </td>
                
                                        <td>
                                            <div class="form-group fl">
                                                <div class="dropdown select-option">
                                                    <div class="select-option-label"  class="dropdown-toggle">
                                                        <span class="dropdown-label-icon">{{tickets.ticket_status}}</span>
                                                    </div>
                
                                 
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="recordNotFound == true">
                                        <td colspan="12"> No Data found</td>
                                    </tr>
                                </tbody>
                            </table>
                
                        </div>
                
                
                        <div class="card-footer text-right">
                            <nav class="d-inline-block">
                                <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">
                    
                                    <li class="page-item">
                                        <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? my_spamtickets({'search_text':spam_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                                    </li>
                                    <li class="page-item" *ngIf="paginationData.backtab =='show'">
                                        <a class="page-link" href="javascript:void(0)" (click)="my_spamtickets({'search_text':spam_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                                    </li>
                    
                    
                                    <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                                        <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? my_spamtickets({'search_text':spam_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                    </li>
                    
                                    <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                                        <a class="page-link" href="javascript:void(0)" (click)="my_spamtickets({'search_text':spam_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                                    </li>
                    
                                    <li class="page-item">
                                        <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? my_spamtickets({'search_text':spam_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                
                
                
                    </div>
                </div>                   
            
    </div>


</div>

</div>
<!--------------------------------------
    Ticket Table View End
    ---------------------------------------->


<div class="modal fade bd-example-modal-md" id="add_spamform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="addSpams" (ngSubmit)="createSpamList()">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Add Spam List</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="row">
                        <div class="col-md-12">
                            <h6>Email ID</h6>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <input type="text" id="emailids" class="form-control" formControlName="emailids">
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="newdepartments">Select List Type</label>
                                <select name="newdepartments" id="status" class="form-control" formControlName="status">
                                    <option value="spam"> Spam List </option>
                                    <!-- <option value="black"> BlackList </option> -->
                                </select>
                            </div>
                        </div>


                    </div>

                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="submit" class="btn btn-primary update_agent" value="Update"
                            [disabled]="!addSpams.valid">
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>


<!-- Deleted Tickets -->
 <div id="accordion2" class="accordion">
        <div class="card mb-0">
            <div class="card-header show" data-toggle="collapse" (click)="my_deletedtickets2({})" href="#collapseOne2">
                <h4>Manage Deleted Emails</h4>
                
        </div>
            <div id="collapseOne2" class="card-body collapse" data-parent="#accordion2" >
              
                <div id="table_views">
                    <div class="card ticket-table-view">
                        <div class="card-header">
                            <h4>Deleted Emails</h4>
                            <div class="addBtn" style="padding-right: inherit;">
                                <a href="javascript:void(0)" class="btn btn-danger" (click)="deletepermenent()" style="width: 40px;font-size: 15px;"
                                 title="Delete Ticket"><i class="fas fa-trash-restore"></i> </a>
                               </div>                        
                        </div>
                        <div class="card-body p-0">
                            <!-- <div class="modal fade" id="testModal"> -->
                            <div id="loading" class="loading-hide"></div>
                            <!-- </div> -->
                            <table class="table table-striped table-md" style="color: #000;">
                                <tr  class="has-color-label-bg">
                                    <td colspan="12" class="search-text-tbl">
                                        <div class="input-group"><input type="text" class="form-control" (keyup.enter)="my_deletedtickets({'search_text':queue_search.value})" #queue_search>
                                            <div class="input-group-append">
                                                <button class="btn btn-primary" (click)="my_deletedtickets({'search_text':queue_search.value})"><i class="fas fa-search"></i></button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="ticket-table-header">
                                    <th><input type="checkbox" id="selectAllDel" (click)="selectAllDel()"   name="checkbox"></th>
                                    <th></th>                                    
                                    <th>Subject</th>
                                    <th style="padding-left: 20px;">State</th>
                                    <!-- <th>Group</th> -->
                                    <th>Department</th>
                                    <th style="padding-left: 20px;">Priority</th>
                                    <th>Status</th>
                                </tr>
                                <tbody>
                                    <tr *ngFor="let tickets of delete_queue_list; let i = index"
                                        [ngClass]="{'closed-ticket' : tickets.ticket_status =='Closed','unread-ticket':tickets.ticket_status != 'Closed'}">
                                        <td><input type="checkbox" (click)="selectuniqueDel()" [ngStyle]="{'position':tickets.ticket_status =='Closed' ? 'absolute' : '' }" value="{{tickets.ticket_no}}"
                                                id="{{tickets.ticket_no}}" name="emailtickets" class="emailtickets2" name="checkbox">
                                        </td>
                                        <td>
                
                                        </td>
                                        <td (click)="viewMyTicket(tickets.ticket_no)">
                                            <a href="javascript:;" class="pointer fl">
                                                <span [ngClass]="(tickets.ticket_new_status == '1')? 'blk-clr':''">{{tickets.subject}}
                                                </span>
                                                <div style="font-size: 12px;color: gray;" clsass="ticket-id dark-grey-clr">
                                                    {{tickets.ticket_created_at}}
                                                </div>
                                            </a>
                                        </td>
                                        <td style="width: 19%;pointer-events: none;">
                                            <div class="status-tag-wrap status-wrapper">
                                                <span
                                                    [class]="tickets.ticket_status == 'Active' ? 'tag-active tag' : tickets.ticket_status == 'In Active' ? 'tag-inactive tag' :  tickets.ticket_status == 'New' ? 'tag-new tag' :  tickets.ticket_status == 'Pending' ? 'tag-pending tag': tickets.ticket_status == 'Un Assigned' ? 'tag-UnAssigned tag': tickets.ticket_status == 'Assigned' ? 'tag-Assigned tag': tickets.ticket_status == 'Progress' ? 'tag-UnAssigned tag': tickets.ticket_status == 'Completed' ? 'tag-Completed tag':tickets.ticket_status == 'Archived' ? 'tag-archived tag':tickets.ticket_status == 'Closed' ? 'tag-closed tag':'tag-new tag'">
                                                    {{tickets.ticket_status}}
                                                </span>
                                                <span
                                                    *ngIf="tickets.closed_at !='' && tickets.closed_at !=null && tickets.ticket_status != 'Closed'"
                                                    class="tag-reopened tag"> Re Opened</span>
                                            </div>
                                        </td>
                                        <td style="width: 30%;">
                                            <div class="form-group fl">
                                                <div class="dropdown select-option" style="display: flex;pointer-events: none;">
                                                    <div class="select-option-label" class="dropdown-toggle">
                                                        <span class="dropdown-label-icon"> {{tickets.department}}</span>
                                                    </div>
                                                    <div *ngIf="tickets.ticket_assigned_to !='' && tickets.ticket_assigned_to != null"
                                                        class="status-tag-wrap status-wrapper">
                                                        <span style="margin-left: 27px;	font-size: 11px;"
                                                            [class]="tickets.ticket_status == 'Active' ? 'tag-active tag' : tickets.ticket_status == 'In Active' ? 'tag-inactive tag' :  tickets.ticket_status == 'New' ? 'tag-new tag' :  tickets.ticket_status == 'Pending' ? 'tag-pending tag': tickets.ticket_status == 'Un Assigned' ? 'tag-UnAssigned tag': tickets.ticket_status == 'Assigned' ? 'tag-Assigned tag': tickets.ticket_status == 'Progress' ? 'tag-UnAssigned tag': tickets.ticket_status == 'Completed' ? 'tag-Completed tag':tickets.ticket_status == 'Archived' ? 'tag-archived tag':tickets.ticket_status == 'Closed' ? 'tag-closed tag':'tag-new tag'">
                                                            {{tickets.ticket_assigned_to}}
                                                        </span>
                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                
                
                                        <td>
                                            <div class="form-group fl">
                                                <div class="dropdown select-option" style="display: flex;pointer-events: none;">
                                                    <div class="select-option-label" >
                                                        <span class="dropdown-label-icon">
                                                            <span
                                                                [class]="tickets.priority == 'Low' ? 'green-bg-status status-bg' : tickets.priority == 'Medium' ? 'blue-bg-status status-bg' :  tickets.priority == 'High' ? 'yellow-bg-status status-bg' :  tickets.priority == 'Urgent' ? 'red-bg-status status-bg':'' "></span>
                                                            {{tickets.priority}}
                                                        </span>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </td>
                
                                        <td>
                                            <div class="form-group fl">
                                                <div class="dropdown select-option">
                                                    <div class="select-option-label"  class="dropdown-toggle">
                                                        <span class="dropdown-label-icon">{{tickets.ticket_status}}</span>
                                                    </div>
                
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="recordNotFound2 == true">
                                        <td colspan="12">No Data found</td>
                                    </tr>
                                </tbody>
                            </table>
                
                        </div>
                
                   <div class="card-footer text-right">
                            <nav class="d-inline-block">
                                <ul class="pagination mb-0" *ngIf="paginationData2.info == 'show'">
                    
                                    <li class="page-item">
                                        <a [class]="paginationData2.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.backtab=='show' ? my_deletedtickets({'search_text':queue_search.value,'offset':paginationData2.start}) : false"><i class="fas fa-angle-left"></i></a>
                                    </li>
                                    <li class="page-item" *ngIf="paginationData2.backtab =='show'">
                                        <a class="page-link" href="javascript:void(0)" (click)="my_deletedtickets({'search_text':queue_search.value,'offset':paginationData2.back})"><i class="fas fa-angle-double-left"></i></a>
                                    </li>
                    
                    
                                    <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData2.data;let i=index">
                                        <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? my_deletedtickets({'search_text':queue_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                                    </li>
                    
                                    <li class="page-item" *ngIf="paginationData2.nexttab =='show'">
                                        <a class="page-link" href="javascript:void(0)" (click)="my_deletedtickets({'search_text':queue_search.value,'offset':paginationData2.next})"><i class="fas fa-angle-double-right"></i></a>
                                    </li>
                    
                                    <li class="page-item">
                                        <a [class]="paginationData2.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.nexttab =='show' ? my_deletedtickets({'search_text':queue_search.value,'offset':paginationData2.end}) : false"><i class="fas fa-angle-right"></i></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                  
                
                
                     
                
                
                    </div>
                </div>                 
            
    </div>


</div>

</div>