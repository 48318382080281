<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
    <div class="card fullHeightCard">
      <div class="body">
        <div id="plist" class="people-list chat-search-list">
          <div class="chat-search">

            <div class="input-group">
              <input type="text" class="form-control" placeholder="Search..." #chat_search
                (keyup.enter)="chatPanelList(chat_search.value)">
              <input type="hidden" name="open_chat_detail_id" id="open_chat_detail_id" value="0" #open_chat_detail_id
                (click)='chatPanelDetail(open_chat_detail_id.value,1)'>
              <input type="hidden" name="close_chat_detail_id" id="close_chat_detail_id" value="0" #close_chat_detail_id
                (click)='chatPanelDetail(close_chat_detail_id.value, 2)'>

              <input type="hidden" id="chatPanelView" (click)='chatPanelView("all")'>
              <span></span>
              <div class="input-chat_search-append">
                <button class="btn btn-primary chat_list_search" (click)="chatPanelList(chat_search.value)"><i
                    class="fas fa-search"></i></button>
              </div>
            </div>

          </div>

          <div class="chat-search">
            <div class="row" style="align-items:center">
              <div class="col-md-12" style="text-align:center;">
                <!-- <div class="form-check form-check-inline col-md-10"> -->
                <div style="display: none;" class="pretty p-switch p-fill">
                  <input type="checkbox" id="voice_3cx" [checked]="" (click)='chatPanelView("all")'>
                  <div class="state p-success">
                    <label for="voice_3cx" style="font-weight: 800;">CLOSED</label>
                  </div>
                </div>
<!-- style=" margin-left: -40px; margin-right: -102px;"  -->
                <div class="tabs">
                  <ul style="display: flex;padding-left: 0; list-style: none;" class="tabs-nav">
                    <li id="sect1" class="tab-active" (click)="tabsection('active')"><a>ACTIVE</a></li>
                    <li id="sect2" (click)="tabsection('close')"><a>CLOSED</a></li>
                    <li id="sect3" (click)="tabsection('missed')"><a> MISSED</a></li>
                    <li *ngIf="admin_permission==1||user_type==2" id="sect4" (click)="tabsection('queue')"><a> QUEUE</a></li>
                  </ul>
                </div>
              </div>
              <!-- <div class="col-md-4" style="padding-left: 0px;">

                <button (click)="chatPanelView('all')" class="btn btn-secondary" style="width: 40px;"> <i class="fa fa-redo"></i></button>

              </div> -->
            </div>
          </div>
          <!-- <div class="chat-search">
            <div class="input-group">
              <button class="btn btn-primary" (click)="getAgentsList()">Active</button>&nbsp;&nbsp;&nbsp;

              <button class="btn btn-primary" (click)="getGroupList()">Closed</button>

            </div>
          </div> -->
          <div class="m-b-20">
            <div id="chat-scroll">
              <ul class="chat-list list-unstyled m-b-0 chat_list_data">
                <li class="clearfix " *ngFor="let chat_list of chat_panel_list;let i=index"
                  [id]="'chat_'+chat_list.chat_id" (click)="chatPanelDetail(chat_list.chat_id,chat_list.chat_status )">

                  <div class="about">
                    <div [ngClass]="chat_list.read_status == '1' ? 'bold-name' : 'name'">{{chat_list.customer_name}}
                      <span *ngIf="!chat_list.customer_name">Unknown</span> <i *ngIf="chat_list.chat_status=='2'"
                        class="far fa-times-circle" aria-hidden="true" style="color: red;font-size: 15px;"></i>
                    </div>
                    <div class=""
                      [ngClass]="chat_list.read_status == '1' ? 'bold-name text-small text-muted' : 'text-small text-muted'">
                      <div class="bullet"></div>{{chat_list.chat_dt}}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9" id="chat_details_view">
    <div class="card fullHeightCard keep-connect-chat-content" *ngIf="chat_panel_detail_type=='chat_screen'">
      <a (click)="showdoc('https://youtu.be/RZMa__ya-e8')" target="_blank"><i style="font-size: 24px;
                            float: right;" class="fa fa-question-circle"></i></a>
      <!-- <a href="https://uatassaabloyccapi.mconnectapps.com/api/v1.0/pro_contents/pro_chat.pdf"  target="_blank"><i style="font-size: 24px;padding-right: 20px;
                            padding-top: 20px;float: right;" class="fa fa-exclamation-circle"></i></a> -->
      <div class="card-body">

        <div class="keep-connect">

          <!-- <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel"> -->

          <div class="connect-logo">
            <img src="assets/img/logo.png">
            <span class="logo-name">Omni Web Chat</span>
          </div>
          <h3>Keep your device connected</h3>
          <!-- <p>To reduce the data usage,<br>connect your device to Wi-Fi.</p> -->

        </div>
      </div>
    </div>
    <!-- class="card {{this.chat_status_detail_id}}"  -->
    <div *ngIf="chat_panel_detail_type=='chat_detail'"
      [id]="'chat_detail_'+this.chat_status_detail_id">
      <div class="chat message-thread-panel light-blue-bg mb-0">
        <!-- <div class="chat-header clearfix">
          <img src="assets/images/user.jpg" alt="avatar">
          <div class="chat-about">
            <div class="chat-with">{{customer_name}} <span *ngIf="!customer_name">Unknown</span></div>
            <div class="chat-num-messages">{{this.widget_name}}</div>
          </div>
          <div class="end-chat-pannel">
             <li>
               <span *ngIf="this.is_chat_closed == false&&this.bot_status == false" style="cursor: pointer;" class="badge badge-success chat_list_search" (click)="transferpopup(this.chat_detail_key)"><i  title="Transfer"  class="fas fa-random"></i> Transfer</span>
            </li> 
            <li>
              <span *ngIf="this.is_chat_closed == false&&this.bot_status == false" class="end-cross-icon" title="End Chat"
                (click)="deletedata(this.chat_detail_key)"><i class="fa fa-times" aria-hidden="true"></i>
              </span>
           </li>
           <li>
              <span class="end-cross-icon" title="Mail to Users" data-toggle="modal" data-target="#MailtoUser_form"><i class="fas fa-at" aria-hidden="true"></i>
              </span>
           </li>
            <span>
              <img src="assets/img/mc-dashboard/chat.png" alt="avatar">
            </span>
          </div>

        </div> -->
        <div class="message-thread-header">
          <div class="row">
              <div class="col-md-3">
                  <div class="thread-details-section fl">
                      <div class="ticket-message-details fl"
                          style="padding: 5px 25px;">
                          <strong
                              style="float: left; padding-right: 10px;">Customer</strong>
                          <span style="text-align: center;">
                              <span class="blk-clr">
                                  <b>: {{customer_name}}</b>
                              </span>
                          </span>
                      </div>
                  </div>
              </div>

              <div class="col-lg-3">
                  <div class="thread-details-section fl">
                      <div class="ticket-message-details fl"
                          style="padding: 5px 25px;">
                          <strong style="float: left; padding-right: 10px;">Widget</strong>
                          <span style="text-align: center;">
                              <span class="blk-clr"><b>: {{this.widget_name}}</b>
                              </span>
                          </span>
                      </div>
                  </div>
              </div>


              <div class="col-lg-6">
                <span style="text-align: center;">
                  <span class="blk-clr"><b>Chat ID : ##{{this.chat_detail_key}}</b>
                  </span>
              </span>
                  <div class="ticket-message-details" style="padding: 5px 25px; float: right;">
                      <!-- <strong
                          style="float: left; padding-right: 10px;">Status</strong>
                      <span style="text-align: center;">
                          <span class="badge badge-success">OPEN</span>
                      </span> -->
                      <span *ngIf="this.is_chat_closed == false&&this.bot_status == false" style="cursor: pointer;" class="badge badge-success chat_list_search" (click)="transferpopup(this.chat_detail_key)"><i  title="Transfer"  class="fas fa-random"></i> Transfer</span>
                      <span *ngIf="this.is_chat_closed == false&&this.bot_status == false" class="end-cross-icon" title="End Chat"
                      (click)="deletedata(this.chat_detail_key)"><i class="fa fa-times" aria-hidden="true"></i>
                    </span>
                    <span class="end-cross-icon" title="Mail to Users" data-toggle="modal" data-target="#MailtoUser_form"><i class="fas fa-at" aria-hidden="true"></i>
                    </span>
                    <!-- <span>
                      <img class="img-right" src="assets/img/mc-dashboard/chat.png" alt="avatar">
                    </span> -->
                      <!-- <div class="end-chat-pannel">
                        <li>
                          <span *ngIf="this.is_chat_closed == false&&this.bot_status == false" style="cursor: pointer;" class="badge badge-success chat_list_search" (click)="transferpopup(this.chat_detail_key)"><i  title="Transfer"  class="fas fa-random"></i> Transfer</span>
                       </li> 
                       <li>
                         <span *ngIf="this.is_chat_closed == false&&this.bot_status == false" class="end-cross-icon" title="End Chat"
                           (click)="deletedata(this.chat_detail_key)"><i class="fa fa-times" aria-hidden="true"></i>
                         </span>
                      </li>
                      <li>
                         <span class="end-cross-icon" title="Mail to Users" data-toggle="modal" data-target="#MailtoUser_form"><i class="fas fa-at" aria-hidden="true"></i>
                         </span>
                      </li>
                       <span>
                         <img src="assets/img/mc-dashboard/chat.png" alt="avatar">
                       </span>
                     </div> -->
                  </div>
              </div>

          </div>
      </div>
      <div class="message-thread-header">
        <div class="row">
            <div class="col-lg-3">
              <div class="thread-details-section fl">
                <div class="ticket-message-details fl"
                    style="padding: 5px 25px;">
                    <strong style="float: left; padding-right: 10px;">Status</strong>
                    <span style="text-align: center;"><span class="blk-clr">
                            <b>: {{chat_status_name}}</b></span></span>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="thread-details-section fl">
                <div class="ticket-message-details fl"
                    style="padding: 5px 25px;">
                        <strong
                            style="float: left; padding-right: 10px; font-size: 14px;">Agent</strong>
                     
                            <span class="dropdown-label-icon badge badge-warning p-1" *ngIf="next_assign_for!='0'">{{chat_agent_name}}</span>
                             <span  class="dropdown-label-icon badge badge-warning p-1" *ngIf="next_assign_for=='0'">Not Assigned</span>
                      
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
              <div class="thread-details-section fl">
                <div class="ticket-message-details fl"
                    style="padding: 5px 25px;">
                        <strong
                            style="float: left; padding-right: 10px; font-size: 14px;">Department
                        </strong>                       
                            <span class="dropdown-label-icon badge badge-danger p-1">
                              {{chat_department}}<span *ngIf="!chat_department">Not Assigned</span> </span>
                        
                    <img class="img-right" src="assets/img/mc-dashboard/chat.png" alt="avatar">

                    </div>
                </div>
              
            </div>
        </div>
    </div>
      </div>
      <div class="chat-box" id="mychatbox">
        <div class="card-body chat-content">
          <div *ngFor="let chat_details of chat_panel_details;let i=index">


            <!-- <div class="chat-item chat-left" *ngIf="chat_details.msg_user_type == '1' || (chat_details.msg_user_type == '2' && chat_details.msg_user_id != loginUser)"> -->
            <div class="chat-item chat-left" *ngIf="chat_details.msg_user_type == '1'">
              <img src="assets/images/user.jpg">
              <div class="chat-details">
                <div class="chat-text" *ngIf="i=='0'">
                  <br><span>Name : {{chat_details.customer_name}} <span
                    *ngIf="!chat_details.customer_name">Unknown</span></span>
                  <br><span>Email : {{chat_details.customer_email}}</span>
                  <br><span>Department : {{chat_details.department_name}}</span>
                  <br><span>Question : {{chat_details.chat_msg}}</span>
                  <br><span>IP : {{chat_details.created_ip}}</span>
                  <br><span>URL : {{chat_details.chatUrl}}</span>
                  <br><span>Country : {{chat_details.country}}</span>
                </div>
                <div class="chat-text" *ngIf="i!='0'">
                  <div *ngIf="chat_details.chat_msg != ''">
                    <!-- <div [innerHTML]="DecryptText(chat_details.chat_msg)"></div> -->
                    <div [innerHTML]="chat_details.chat_msg"></div>
                  </div>

                  <!-- <div *ngIf="chat_details.chat_msg == ''">
                    <img style="max-width: 70px;" src="{{chat_details.chat_images}}">
                  </div> -->
                  <div
                    *ngIf="chat_details.extension== 'doc' ||chat_details.extension=='docx'||chat_details.extension == 'pdf'||chat_details.extension == 'csv' || chat_details.extension =='txt';"
                    style="white-space: pre-line; max-width: 70px;">
                    <div *ngIf="chat_details.chat_images" style="max-width: 650px;"> <a target="_blank"
                        href="{{chat_details.chat_images}}"><img src="assets/images/icons/quickView.png">
                        <!-- <b>{{chat_details.chat_images}}</b> -->
                      </a>
                    </div>
                  </div>
                  <div
                    *ngIf="chat_details.extension!= 'doc' && chat_details.extension != 'docx' && chat_details.extension != 'pdf' &&chat_details.extension != 'csv' && chat_details.extension !='txt';"
                    style="white-space: pre-line; max-width: 70px;">
                    <div *ngIf="chat_details.chat_images" style="max-width: 650px;"> <a target="_blank"
                        href="{{chat_details.chat_images}}"><img src="{{chat_details.chat_images}}" onerror=" this.src='assets/images/icons/quickView.png'">
                      </a></div>
                    <!-- {{chat_details.chat_images}} -->
                  </div>
                </div>
                <!-- <div class="chat-time">{{chat_details.chat_time}}</div> -->
                <div class="chat-time" *ngIf="i=='0'">{{chattime}}</div>
                <div class="chat-time" *ngIf="i!='0'">{{chat_details.chat_time}}</div>
                <!-- <div class="chat-time">{{this.serverService.toTimeZone(chat_details.chat_time)}}</div> -->

              </div>
            </div>


            <div class="chat-item chat-right" *ngIf="chat_details.msg_user_type == '4'">
              <img src="assets/images/bot.png">
              <div class="chat-details">
                <div style="max-width: 600px;" class="chat-text">
                  <div *ngIf="chat_details.chat_msg != ''">
                    <!-- <p [innerHTML]="DecryptText(chat_details.chat_msg)"></p> -->
                    <p [innerHTML]="chat_details.chat_msg"></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-center" *ngIf="chat_details.msg_user_type == '6'||chat_details.msg_user_type == '7'">             
              <!-- <div class="badge badge-custom" [innerHTML]="DecryptText(chat_details.chat_msg)"></div> -->
              <div class="badge badge-custom" [innerHTML]="chat_details.chat_msg"></div>
            </div>
            <!-- <div class="chat-item chat-right" *ngIf="chat_details.msg_user_type == '2' && chat_details.msg_user_id == loginUser"> -->
            <div class="chat-item chat-right" *ngIf="chat_details.msg_user_type == '2'">
              <img src="{{chat_details.profile_image}}" onerror="this.src='assets/images/user.jpg'">
              <div class="chat-details">
                <!-- <div *ngIf="chat_details.chat_msg != ''">
                    {{chat_details.chat_msg}}
                  </div> -->
                <!-- <div class="chat-text" *ngIf="chat_details.chat_msg != ''">
                  <div style="font-weight:800;">
                   <span *ngIf="chat_details.agent_name !='' && chat_details.agent_name !=null" >  {{chat_details.agent_name}}</span>
                   <span *ngIf="chat_details.agent_name =='' ||chat_details.agent_name ==null">  {{chat_details.user_name}}</span>
                  </div>
                  {{chat_details.chat_msg}}
                </div>
                <div class="chat-text" *ngIf="chat_details.chat_msg == ''">
                  <div style="font-weight:800;">
                   <span *ngIf="chat_details.agent_name !='' && chat_details.agent_name !=null" >  {{chat_details.agent_name}}</span>
                   <span *ngIf="chat_details.agent_name =='' ||chat_details.agent_name ==null">  {{chat_details.user_name}}</span>
                  </div>
                  <img style="max-width: 70px;"
                    src="{{chat_details.chat_images}}">
                </div> -->

                <div
                  *ngIf="chat_details.extension== 'doc' ||chat_details.extension=='docx'||chat_details.extension == 'pdf'||chat_details.extension == 'csv' || chat_details.extension =='txt';"
                  class="chat-text" style="white-space: pre-line;">
                  <div style="font-weight:800;">
                    <span *ngIf="chat_details.agent_name !='' && chat_details.agent_name !=null">
                      {{chat_details.agent_name}}</span>
                    <span *ngIf="chat_details.agent_name =='' ||chat_details.agent_name ==null">
                      {{chat_details.user_name}}</span>
                  </div>
                  <div *ngIf="chat_details.chat_images" style="max-width: 650px;"> <a target="_blank"
                      href="{{chat_details.chat_images}}"><img src="assets/images/icons/quickView.png">
                      Click to View File
                    </a>
                  </div>
                </div>

                <div
                  *ngIf="chat_details.extension!= 'doc' && chat_details.extension != 'docx' && chat_details.extension != 'pdf' &&chat_details.extension != 'csv' && chat_details.extension !='txt';"
                  class="chat-text" style="white-space: pre-line;">
                  <div style="font-weight:800;">
                    <span *ngIf="chat_details.agent_name !='' && chat_details.agent_name !=null">
                      {{chat_details.agent_name}}</span>
                    <span *ngIf="chat_details.agent_name =='' ||chat_details.agent_name ==null">
                      {{chat_details.user_name}}</span>
                  </div>
                  <div *ngIf="chat_details.chat_images" style="max-width: 650px;"> <a target="_blank"
                      href="{{chat_details.chat_images}}"><img style="max-width: 70px;" src="{{chat_details.chat_images}}"
                        onerror="this.src='assets/images/icons/quickView.png'"> </a>
                    <p style="display: table-caption; min-width: 73px;"> Click to View </p>
                  </div>

                  <!-- <div [innerHTML]="DecryptText(chat_details.chat_msg)"></div> -->
                  <div [innerHTML]="chat_details.chat_msg"></div>
                </div>

                <!-- <div class="chat-time" *ngIf="i=='0'">{{chattime}}</div> -->
                <div class="chat-time" *ngIf="i!='0'">{{chat_details.chat_time}}</div>

                <!-- <div class="chat-time">{{this.serverService.toTimeZone(chat_details.chat_time)}}</div>  -->
              </div>
            </div>

          </div>


        </div>
        <div class="card-footer chat-form" *ngIf="this.is_chat_closed == false&&this.bot_status == false">
          <!-- <form id="chat-form"> -->
          <textarea class="form-control" id="chat_msg" placeholder="Type a message"
            style="height: 50px;padding-right:150px;" (keydown)="onMessageSend($event)" #chat_message></textarea>
          <input type="hidden" name="chat_id" id="chat_detail_id" value="{{chat_detail_key}}" #chat_detail_id>
          <button type="button" class="btn btn-primary" data-toggle="modal" (click)="template()" style="right: 85px;">
            <i class="fas fa-pencil-ruler"></i>
            <!-- <i class="fas fa-fill-drip"></i> -->
          </button>
          <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#createNewWidget"
            style="right: 40px;">
            <i class="fas fa-link"></i>
          </button>
          <button type="button" class="btn btn-primary" (click)="sendImage()">
            <i class="far fa-paper-plane"></i>
          </button>


          <h4 *ngIf="this.is_chat_closed">Chat Closed</h4>
          <h4 *ngIf="this.bot_status">Currently responds via AI</h4>

          <!-- <h3 class="chat-closed"> Chat closed </h3> -->

        </div>
        <div class="card-footer chat-form" *ngIf="this.is_chat_closed">
          <h4 class="close-text" *ngIf="this.is_chat_closed">Chat Closed</h4>
        </div>
        <div class="card-footer chat-form" *ngIf="this.bot_status&&this.is_chat_closed == false">
          <h4 class="close-text" >Currently responds via AI</h4>
        </div>
      </div>
    </div>
    <audio id="beepaud" src="https://omni.mconnectapps.com/webchat/images/beep.mp3" preload="auto"></audio>

  </div>
</div>


<div class="modal fade bd-example-modal-md" id="video_play">
  <div class="modal-dialog modal-md" style="max-width: 1200px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" style="color:crimson" id="myLargeModalLabel">mConnect Chat Solutions</h5>
        <button type="button" class="close" (click)="stop()" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <iframe class='myvideo' [src]="this.doc_link | safe: 'resourceUrl'" width="100%" style="height: 575px;" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
        <!-- style="margin-bottom: -6px;" width="100%" height="325"  -->
        <!-- <embed [src]="this.video_link | safe: 'resourceUrl'"  width="100%" style="height: 575px;" /> -->
      </div>
      <div class="modal-footer">
        <div class="buttons">
          <input type="button" data-dismiss="modal" (click)="stop()" class="btn btn-outline-secondary" value="close">
        </div>
      </div>

    </div>
  </div>
</div>
<!---------------------
Add New Widget Modal
------------------>
<div class="modal fade" id="createNewWidget">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="myLargeModalLabel">Upload Media File (Image, Video, Text and PDF files up to 5 mb)
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-md-12">
            <div class="row">
              <div class="col-md-12 col-12">
                <div class="form-group">
                  <!-- <input type="file" class="form-control" name="chat_media" id="chat_media" multiple> -->
                  <input type="file" class="form-control" name="chat_media" id="chat_media">
                </div>
              </div>


            </div>
          </div>

        </div>
      </div>

      <div class="modal-footer">
        <div class="buttons">
          <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
          <input type="button" class="btn btn-primary update_agent" value="Submit" (click)="sendImage()">
        </div>
      </div>
    </div>
  </div>
</div>

<!---------------------
Add  Chat Transfer Modal
------------------>
<div class="modal fade bd-example-modal-md" id="chatTransfer">
  <div class="modal-dialog modal-md">
    <div class="modal-content">

      <form>
        <div class="modal-header">
          <h5 class="modal-title" id="myLargeModalLabel"> TRANSFER TO</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>


        <div class="modal-body">

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="edit_sender_id">Department</label>

                <select (change)="get_agents_by_department(select_department,select_agents)" class="form-control" name="select_department" [(ngModel)]="select_department">
                  <option value=''> Select Department </option>
                  <option *ngFor="let send of email_dept" value="{{send.dept_id}}">
                  {{send.department_name}}</option>
                </select>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group custom-seleted">
                <label for="edit_sender_id">Agent</label>
                <select class="form-control"  name="select_agents" [(ngModel)]="select_agents">
                  <option value=''> Select Agent </option>
                  <option *ngFor="let item of agent_options" value="{{item.user_id}}" id="{{item.chat_ticket}}">
                  {{item.agent_name}}</option>
                </select>
              </div>
            </div>

          </div>
        </div>

        <div class="modal-footer">
          <div class="buttons">
            <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
            <input type="button" class="btn btn-primary update_agent" value="Submit" (click)="TransferChatAgent(select_agents,select_department)">
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!---------------------
Add  Chat Transfer Modal END
------------------>

<!---------------------
MAIL  Chat Modal
------------------>
<div class="modal fade bd-example-modal-md" id="MailtoUser_form">
  <div class="modal-dialog modal-md">
    <div class="modal-content">

      <form>
        <div class="modal-header">
          <h5 class="modal-title" id="myLargeModalLabel"> Mail History to User</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>


        <div class="modal-body">

          <div class="row">           
           

            <div class="col-md-12">
              <div class="form-group">
                <label for="edit_sender_id">Select USER</label>
                <select   id="select_mail_agent" class="form-control"  name="select_mail_agent" [(ngModel)]="select_mail_agent">
                  <option value=''> Select Agent </option>
                  <option *ngFor="let item of all_agent_list" value="{{item.user_id}}">
                  {{item.agent_name}}</option>
                </select>
              </div>
            </div>

          </div>
        </div>

        <div class="modal-footer">
          <div class="buttons">
            <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
            <input type="button" data-dismiss="modal" class="btn btn-primary update_agent" value="Submit" (click)="MailtoAgent(select_mail_agent)">
          </div>
        </div>
      </form>
    </div>
  </div>
</div>