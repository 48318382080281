
<div class="section-body">

 
    <div class="row">


        <div class="col-12 col-lg-12"> 
			<div class="card">
				<div class="card-header">
					<h4>CALL Tariffs</h4> 
				</div> 
				<div class="card-body p-30">
					<div class="row">
						<div class="col-12 col-lg-10">
							<select class="chat-widget form-control" id="MPlanName" (change)="getSingleTariffs($event.target.value)">
								<option *ngFor="let item of smsTariffs" value="{{item.id}}">{{item.plan_name}}</option> 
							</select> 
						</div>
						<div class="col-12 col-lg-2">
							<span class="user-icon" style="cursor: pointer;"><i class="far fa-trash-alt" (click)="deletedata(this.planID)"></i></span>
                            <button type="button" class="btn btn-success" data-toggle="modal" data-target="#createNewWidget">+ Add Tariff</button>
                            <a (click)="showdoc('https://youtu.be/2gSAkVPZT2E')"  target="_blank"><i style="font-size: 24px;
                                float: right;" class="fa fa-question-circle"></i></a>
						</div>
					</div>
				</div>
			</div>
		</div>





        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>CALL tariff</h4>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-striped table-md">
                            <!-- <tr>
                                <td colspan="12" class="search-text-tbl">
                                    <div class="input-group"><input type="text" class="form-control" (keyup.enter)="searchData({'search_text':call_history_search.value})" #call_history_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="searchData({'search_text':call_history_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr> -->
                            <tr>
                                <th>S.no</th>
                                <th>Country</th>
                                <th>Phone code</th>
                                <th>Call Amount ($)</th>
                                <th>Action</th>
                                
                            </tr>
                            <tr *ngFor="let history of call_history_list;let i=index" >
                                <td>{{offset_count+i+1}}</td>
                                <td>{{history.name}}</td>
                                <td>{{history.phonecode}}</td>
                                <td>{{history.tarrif}}</td>
                                <td>
                                    <span class="user-icon" style="cursor: pointer;"><i class="far fa-edit" (click)="openUpdateTariff(history.id,history.name,history.tarrif)"></i></span>
                                </td>
                            </tr>
                            
                            <tr *ngIf="recordNotFound == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                      
                        </table>
                    </div>
                </div>
             
            </div>
        </div>
    </div>


</div>









<div class="modal fade bd-example-modal-md" id="add_deptform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Add CALL Cost</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                <div class="row">
                    <div class="col-md-12">
                        <h6>Country Name</h6>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <input type="text" id="queue_number" class="form-control"  value="{{this.country}}" readonly>
                        </div>
                    </div>
                </div> 

                <div class="row">
                    <div class="col-md-12">
                        <h6>CALL Cost ($)</h6>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <input type="text" id="sms_cost" class="form-control"  value="{{this.sms_tarrif}}">
                        </div>
                    </div>
                </div>


                

           
        </div>

        <div class="modal-footer">
            <div class="buttons">
                <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                <input type="submit" class="btn btn-primary update_agent" value="Update" (click)="insertTariff(this.id)">
            </div>
        </div>

</div>
</div>
</div>





<!---------------------
Add New Widget Modal
------------------>
<div class="modal fade" id="createNewWidget">
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="myLargeModalLabel">Create New CALL Tariff</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-12 col-md-12">
						<div class="row">
							<div class="col-md-12 col-12">
								<div class="form-group">
									<label for="widget-name">Tariff Name</label>
									<input type="text" class="form-control" name="widget-name" id="widget_name">
								</div>
							</div>
                            
                            <div class="col-md-12 col-12">
								<div class="form-group">
									<label for="widget_currency">Tariff Currency</label>
									<input type="text" class="form-control" name="widget_currency" id="widget_currency">
								</div>
							</div>
                            <div class="col-md-12 col-12">
								<div class="form-group">
									<label for="widget_desc">Tariff Desc</label>
									<input type="text" class="form-control" name="widget_desc" id="widget_desc">
								</div>
                            </div>
						</div>
					</div>

				</div>	
			</div>

			<div class="modal-footer">
				<div class="buttons">
					<input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
					<input type="button" class="btn btn-primary update_agent" value="Submit"  (click)="addChatWidget()">
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal fade bd-example-modal-md" id="video_play">
    <div class="modal-dialog modal-md"  style="max-width: 1200px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" style="color:crimson" id="myLargeModalLabel" >Call Tariff(Billing) solution for 3CX | mConnect | Omnichannel</h5>
          <button type="button" class="close" (click)="stop()" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
                  <iframe class='myvideo' [src]="this.doc_link | safe: 'resourceUrl'"  width="100%" style="height: 575px;" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  <!-- style="margin-bottom: -6px;" width="100%" height="325"  -->
                <!-- <embed [src]="this.video_link | safe: 'resourceUrl'"  width="100%" style="height: 575px;" /> -->
        </div>     
        <div class="modal-footer" >
            <div class="buttons">
                <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" (click)="stop()" value="close">
            </div>
        </div>
  
      </div>
    </div>
  </div>  