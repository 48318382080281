<div class="section-body" id="ticketing-system-panel">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header ticket-group-panel">
                    <div style="width: 40%; float: right;">
                        <div class="ticket-action-group">
                            <!-- <input type="hidden" id="BAssignTickets" (click)="BAssignTickets()"> -->
                            <div class="ticket-assign-btn">
                                <ul>
                                    <!-- <li><input type="checkbox"  (click)="slectAll()" id="selectAllQ"  /></li> -->
                                    <li>
                                        <!-- <a class="btn btn-danger" href="javascript:;" (click)="BulkAssign()"><i
                                                class="fas fa-users"></i> Assign</a> -->
                                    </li>
                                    <!-- <li><a href="javascript:;" class="gradient-btn"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="app-icon"><path d="M24.403 3.003a1.2 1.2 0 01-1.301 2.017c-5.13-3.31-11.989-2.627-16.349 1.734-5.084 5.084-5.084 13.41 0 18.494s13.41 5.084 18.494 0c2.779-2.779 4.118-6.62 3.755-10.487a1.2 1.2 0 112.39-.224c.429 4.571-1.156 9.118-4.447 12.409-6.021 6.021-15.867 6.021-21.889 0s-6.021-15.867 0-21.889C10.221-.108 18.328-.915 24.403 3.004zM10.47 14.64l4.244 4.467 12.73-11.981a1.2 1.2 0 011.645 1.747l-13.6 12.8a1.2 1.2 0 01-1.692-.047L8.73 16.293a1.201 1.201 0 011.74-1.654z"></path></svg> Close</a></li> -->
                                    <!-- <li><a href="javascript:;" class="gradient-btn"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" class="app-icon"><path d="M5.945 24.349L24.35 5.944c-5.121-4.253-12.795-3.982-17.591.814s-5.067 12.47-.814 17.591zm20.11-16.698L7.65 26.056c5.121 4.253 12.795 3.982 17.591-.814s5.067-12.47.814-17.591zM5.052 26.948c-6.025-6.025-6.025-15.87 0-21.896s15.87-6.025 21.896 0c6.025 6.025 6.025 15.87 0 21.896s-15.87 6.025-21.896 0z"></path></svg> Spam</a></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style="width: 60%; float: right;">
                        <!----------------------------------
		Filter
	------------------------------------>

                        <!----------------------------------
			Filter End
		------------------------------------>

                        <div style="padding-right: 35px;" class="dropdown select-option header-select-dropdown pt-1">
                            <div class="select-option-label" data-toggle="dropdown"
                                class="dropdown-toggle filter-btn badge badge-info">
                                <div class="dropdown-title">Filter Messages<i class="fas fa-chevron-down"></i> &nbsp;</div>
                            </div>
                            <div class="dropdown-menu">
                                <div class="pullDown">
                        <a href="javascript:void(0)" class="dropdown-item"><div class="green-bg-status status-bg"></div>All</a>
						<a href="javascript:void(0)" class="dropdown-item" ><div class="yellow-bg-status status-bg"></div>New</a>
						<a href="javascript:void(0)" class="dropdown-item"><div class="blue-bg-status status-bg"></div>Read</a>
						<a href="javascript:void(0)" class="dropdown-item"><div class="red-bg-status status-bg"></div>Unread</a>
                                </div>
                            </div>
                        </div>


                        <div class="dropdown select-option header-select-dropdown pt-1 mr-3">
                            <div class="select-option-label" data-toggle="dropdown"
                                class="dropdown-toggle filter-btn info badge badge-success">
                                <div class="dropdown-title">Select Department <i class="fas fa-chevron-down"></i> &nbsp;
                                </div>
                            </div>
                            <div class="dropdown-menu">
                                <!-- <div class="pullDown">
                                    <a href="javascript:void(0)" class="dropdown-item"
                                        (click)="filterByDepartment('All')">All</a>
    
                                    <a href="javascript:void(0)" *ngFor="let item of department ;let i = index"
                                        class="dropdown-item"
                                        (click)="filterByDepartment(item.department_id)">{{item.department_name}}</a>
                                </div> -->
                            </div>
                        </div>

                        <a href="https://uatassaabloyccapi.mconnectapps.com/api/v1.0/pro_contents/Email-ticket.pdf"
                        target="_blank"><i class="info-icon fa fa-exclamation-circle"></i></a>

                    </div>
                    <!----------------------------------
		Filter depart
	------------------------------------>
                 
                    <!-- <a href="javascript:void(0)" class="btn btn-success" routerLink="/ticket-create-new">Create Ticket</a> -->
                    <!----------------------------------
				Filter End
			------------------------------------>
           
                  
                </div>
            </div>

            <div id="table_view">
                <div class="card ticket-table-view">
                    <div class="card-body p-0">
                        <table class="table table-striped table-md" style="color: #000;">
                            <tr class="ticket-table-header">
                                <th></th>
                                <th>Agent</th>
                                <th>Chat ID</th>
                                <th>Messgae</th>
                                <th>Status</th>
                                <th>Department</th>
                                <th>Assign To</th>
                            </tr>
                            <tbody>

                                <tr class="unread-ticket" *ngFor="let sl of sms_list" (click)="redirectView(sl.chat_id)">
                                    <td><input type="checkbox" name="checkbox" class="emailtickets" value="271" id="271"></td>
                                    <td>
                                        <div class="agent-name">
                                            <div class="round-block bg-1 d-flex pointer"  style="background-color: #fc544b; color: #fff;">J</div>
                                        </div>
                                    </td>
                                            <td>
                                                <span class=""><b>chat#{{sl.app_chat_id}}</b></span>
                                            </td>

                                            <td>
                                                <span class="ticket-title short-message">{{sl.chat_message}}</span>
                                            </td>
                                    <td>
                                        <div class="status-tag-wrap status-wrapper">
                                            <span class="badge badge-danger">New{{sl.read_status}}</span>
                                         </div>
                                    </td>
                                    <td>
                                        <div class="form-group fl">
                                            <div class="dropdown select-option">
                                                <div data-toggle="dropdown" class="dropdown-toggle"><span
                                                        class="dropdown-label-icon"> marketing</span></div>
                                                <div class="dropdown-menu" id="ticket_271">
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Websupport</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Technical</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Sales</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">sales
                                                            department</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">marketing
                                                            division</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">giving a
                                                            department name</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Marketing
                                                            Dept</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                  
                                    <td>
                                        <div class="form-group fl">
                                            <div class="dropdown select-option">
                                                <div data-toggle="dropdown" class="dropdown-toggle"><span
                                                        class="dropdown-label-icon">Un Assigned</span></div>
                                                <div class="dropdown-menu" id="ticket_271">
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Active</a></div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">In Active</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">New</a></div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Pending</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item active" class="dropdown-item active">Un
                                                            Assigned</a></div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Assigned</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Progress</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Completed</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Closed</a></div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Archived</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr class="unread-ticket">
                                    <td><input type="checkbox" name="checkbox" class="emailtickets" value="271"
                                            id="271"></td>
                                    <td>
                                        <div class="agent-name">
                                            <div class="round-block bg-1 d-flex pointer"  style="background-color: #fc544b; color: #fff;">C</div>
                                        </div>
                                    </td>
                                            <td>
                                                <span class=""><b>chat#001</b></span>
                                            </td>

                                            <td>
                                                <span class="ticket-title short-message">Hi Team May I know about the Instllation Cost for Omni channel</span>
                                            </td>
                                    <td>
                                        <div class="status-tag-wrap status-wrapper">
                                            <span class="badge badge-danger">Unread</span>
                                         </div>
                                    </td>
                                    <td>
                                        <div class="form-group fl">
                                            <div class="dropdown select-option">
                                                <div data-toggle="dropdown" class="dropdown-toggle"><span
                                                        class="dropdown-label-icon"> Websupport</span></div>
                                                <div class="dropdown-menu" id="ticket_271">
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Websupport</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Technical</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Sales</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">sales
                                                            department</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">marketing
                                                            division</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">giving a
                                                            department name</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Marketing
                                                            Dept</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                  
                                    <td>
                                        <div class="form-group fl">
                                            <div class="dropdown select-option">
                                                <div data-toggle="dropdown" class="dropdown-toggle"><span
                                                        class="dropdown-label-icon">Un Assigned</span></div>
                                                <div class="dropdown-menu" id="ticket_271">
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Active</a></div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">In Active</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">New</a></div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Pending</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item active" class="dropdown-item active">Un
                                                            Assigned</a></div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Assigned</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Progress</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Completed</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Closed</a></div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Archived</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr class="read-ticket">
                                    <td><input type="checkbox" name="checkbox" class="emailtickets" value="271"
                                            id="271"></td>
                                    <td>
                                        <div class="agent-name">
                                            <div class="round-block bg-1 d-flex pointer"  style="background-color: #54ca68; color: #fff;">C</div>
                                        </div>
                                    </td>
                                            <td>
                                                <span class="">chat#002</span>
                                            </td>

                                            <td>
                                                <span class="ticket-title short-message">Hi Team May I know about the Instllation Cost for Omni channel</span>
                                            </td>
                                    <td>
                                        <div class="status-tag-wrap status-wrapper">
                                            <span class="badge badge-success">Read</span>
                                         </div>
                                    </td>
                                    <td>
                                        <div class="form-group fl">
                                            <div class="dropdown select-option">
                                                <div data-toggle="dropdown" class="dropdown-toggle"><span
                                                        class="dropdown-label-icon"> Websupport</span></div>
                                                <div class="dropdown-menu" id="ticket_271">
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Sales</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Technical</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Sales</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">sales
                                                            department</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">marketing
                                                            division</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">giving a
                                                            department name</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Marketing
                                                            Dept</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                  
                                    <td>
                                        <div class="form-group fl">
                                            <div class="dropdown select-option">
                                                <div data-toggle="dropdown" class="dropdown-toggle"><span
                                                        class="dropdown-label-icon">Un Assigned</span></div>
                                                <div class="dropdown-menu" id="ticket_271">
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Active</a></div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">In Active</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">New</a></div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Pending</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item active" class="dropdown-item active">Un
                                                            Assigned</a></div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Assigned</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Progress</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Completed</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Closed</a></div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Archived</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr class="closed-ticket">
                                    <td><input type="checkbox" name="checkbox" class="emailtickets" value="271"
                                            id="271"></td>
                                    <td>
                                        <div class="agent-name">
                                            <div class="round-block bg-1 d-flex pointer">C</div>
                                        </div>
                                    </td>
                                            <td>
                                                <span class=""><b>chat#003</b></span>
                                            </td>

                                            <td>
                                                <span class="ticket-title short-message">Hi Team May I know about the Instllation Cost for Omni channel</span>
                                            </td>
                                    <td>
                                        <div class="status-tag-wrap status-wrapper">
                                            <span class="badge badge-info">Closed</span>
                                         </div>
                                    </td>
                                    <td>
                                        <div class="form-group fl">
                                            <div class="dropdown select-option">
                                                <div data-toggle="dropdown" class="dropdown-toggle"><span
                                                        class="dropdown-label-icon"> Websupport</span></div>
                                                <div class="dropdown-menu" id="ticket_271">
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Websupport</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Technical</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Sales</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">sales
                                                            department</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">marketing
                                                            division</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">giving a
                                                            department name</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Marketing
                                                            Dept</a>
                                                        <div class="dropdown-divider"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                  
                                    <td>
                                        <div class="form-group fl">
                                            <div class="dropdown select-option">
                                                <div data-toggle="dropdown" class="dropdown-toggle"><span
                                                        class="dropdown-label-icon">Un Assigned</span></div>
                                                <div class="dropdown-menu" id="ticket_271">
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Active</a></div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">In Active</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">New</a></div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Pending</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item active" class="dropdown-item active">Un
                                                            Assigned</a></div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Assigned</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Progress</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Completed</a>
                                                    </div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Closed</a></div>
                                                    <div class="pullDown"><a href="javascript:void(0)"
                                                            class="dropdown-item" class="dropdown-item">Archived</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>










        </div>
    </div>
</div>