<div class="section-body">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div id="wizard_vertical" class="wizard vertical">
                        <div class="steps">
                            <div class="col-md-12" style="text-align:center;">
                                 <div style="display: none;" class="pretty p-switch p-fill">
                                  <!-- <input type="checkbox" id="voice_3cx" [checked]="" (click)='chatPanelView("all")'> -->
                                  <div class="state p-success">
                                    <label for="voice_3cx" style="font-weight: 800;">CLOSED</label>
                                  </div>
                                </div>
                                 <div class="tabs">
                                  <!-- <ul style="display: flex;padding-left: 0; list-style: none;" class="tabs-nav">
                                    <li id="sect1" class="tab-active" (click)="tabsection('active')"><a>ACTIVE</a>
                                    </li>
                                    <li id="sect2" (click)="tabsection('close')"><a>CLOSED</a></li>
                                    <li id="sect3" (click)="tabsection('missed')"><a> MISSED</a></li>
                                    <li id="sect4" (click)="tabsection('queue')"><a> QUEUE</a></li>
                                  </ul> -->
                                </div>
                              </div>
                            <ul class="nav nav-tabs msg-inbox">
                                <li class="nav-item">
                                    <a class="nav-link active" data-toggle="tab" href="#phone_1">
                                        +91 780850 08500</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#phone_2">
                                        +65 1520 1144</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#phone_3">
                                        +65 4215 0124</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#phone_4">
                                        +65 0120 3251</a>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#phone_5">
                                        +65 1234 5678</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#phone_5">
                                        +65 1234 5678</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#phone_5">
                                        +65 1234 5678</a>
                                </li>  <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#phone_5">
                                        +65 1234 5678</a>
                                </li>  <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#phone_5">
                                        +65 1234 5678</a>
                                </li>  <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" href="#phone_5">
                                        +65 1234 5678</a>
                                </li>
                            </ul>
                        </div>
                        <!-- Create New Ticket -->
                        <div class="tab-content social-marketing content">
                            <div id="newTicket" class="body tab-pane active">
                                <div class="card-header padding-0 no-border-bottom">

                                    <div class="message-thread-panel no-margin-bottom light-blue-bg fl">
                                        <div class="message-thread-header">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="thread-details-section fl">
                                                        <div class="ticket-message-details fl"
                                                            style="padding: 5px 25px;">
                                                            <strong
                                                                style="float: left; padding-right: 10px;">Agent</strong>
                                                            <span style="text-align: center;">
                                                                <span class="blk-clr">
                                                                    <b>: Naveen J</b>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4">
                                                    <div class="thread-details-section fl">
                                                        <div class="ticket-message-details fl"
                                                            style="padding: 5px 25px;">
                                                            <strong style="float: left; padding-right: 10px;">Chat
                                                                ID</strong>
                                                            <span style="text-align: center;">
                                                                <span class="blk-clr"><b>: chat_#002 </b>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-lg-4">
                                                    <div class="ticket-message-details fr" style="padding: 5px 25px;">
                                                        <strong
                                                            style="float: left; padding-right: 10px;">Status</strong>
                                                        <span style="text-align: center;">
                                                            <span class="badge badge-success">OPEN</span>
                                                        </span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="message-thread-body msg-chat-header fl">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <div class="ticket-message-details fl">
                                                        <strong style="float: left; padding-right: 10px;">From</strong>
                                                        <span style="text-align: center;"><span class="blk-clr">
                                                                <b>: +91 780850 08500</b></span></span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="form-group fl">
                                                        <div class="dropdown select-option">
                                                            <strong
                                                                style="float: left; padding-right: 10px; font-size: 14px;">Assign
                                                                To </strong>
                                                            <div data-toggle="dropdown" class="dropdown-toggle">
                                                                <span class="dropdown-label-icon badge badge-warning">
                                                                    Naveen J <i class="fas fa-angle-down"></i></span>
                                                            </div>
                                                            <div class="dropdown-menu" id="ticket_271">
                                                                <div class="pullDown"><a href="javascript:void(0)"
                                                                        class="dropdown-item"
                                                                        class="dropdown-item">Websupport</a>
                                                                    <div class="dropdown-divider"></div>
                                                                </div>
                                                                <div class="pullDown"><a href="javascript:void(0)"
                                                                        class="dropdown-item"
                                                                        class="dropdown-item">Technical</a>
                                                                    <div class="dropdown-divider"></div>
                                                                </div>
                                                                <div class="pullDown"><a href="javascript:void(0)"
                                                                        class="dropdown-item"
                                                                        class="dropdown-item">Sales</a>
                                                                    <div class="dropdown-divider"></div>
                                                                </div>
                                                                <div class="pullDown"><a href="javascript:void(0)"
                                                                        class="dropdown-item"
                                                                        class="dropdown-item">sales
                                                                        department</a>
                                                                    <div class="dropdown-divider"></div>
                                                                </div>
                                                                <div class="pullDown"><a href="javascript:void(0)"
                                                                        class="dropdown-item"
                                                                        class="dropdown-item">marketing
                                                                        division</a>
                                                                    <div class="dropdown-divider"></div>
                                                                </div>
                                                                <div class="pullDown"><a href="javascript:void(0)"
                                                                        class="dropdown-item"
                                                                        class="dropdown-item">giving a
                                                                        department name</a>
                                                                    <div class="dropdown-divider"></div>
                                                                </div>
                                                                <div class="pullDown"><a href="javascript:void(0)"
                                                                        class="dropdown-item"
                                                                        class="dropdown-item">Marketing
                                                                        Dept</a>
                                                                    <div class="dropdown-divider"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4">
                                                    <div class="form-group fr">
                                                        <div class="dropdown select-option">
                                                            <strong
                                                                style="float: left; padding-right: 10px; font-size: 14px;">Department
                                                            </strong>
                                                            <div data-toggle="dropdown" class="dropdown-toggle">
                                                                <span class="dropdown-label-icon badge badge-danger">
                                                                    marketing <i class="fas fa-angle-down"></i></span>
                                                            </div>
                                                            <div class="dropdown-menu" id="ticket_271">
                                                                <div class="pullDown"><a href="javascript:void(0)"
                                                                        class="dropdown-item"
                                                                        class="dropdown-item">Websupport</a>
                                                                    <div class="dropdown-divider"></div>
                                                                </div>
                                                                <div class="pullDown"><a href="javascript:void(0)"
                                                                        class="dropdown-item"
                                                                        class="dropdown-item">Technical</a>
                                                                    <div class="dropdown-divider"></div>
                                                                </div>
                                                                <div class="pullDown"><a href="javascript:void(0)"
                                                                        class="dropdown-item"
                                                                        class="dropdown-item">Sales</a>
                                                                    <div class="dropdown-divider"></div>
                                                                </div>
                                                                <div class="pullDown"><a href="javascript:void(0)"
                                                                        class="dropdown-item"
                                                                        class="dropdown-item">sales
                                                                        department</a>
                                                                    <div class="dropdown-divider"></div>
                                                                </div>
                                                                <div class="pullDown"><a href="javascript:void(0)"
                                                                        class="dropdown-item"
                                                                        class="dropdown-item">marketing
                                                                        division</a>
                                                                    <div class="dropdown-divider"></div>
                                                                </div>
                                                                <div class="pullDown"><a href="javascript:void(0)"
                                                                        class="dropdown-item"
                                                                        class="dropdown-item">giving a
                                                                        department name</a>
                                                                    <div class="dropdown-divider"></div>
                                                                </div>
                                                                <div class="pullDown"><a href="javascript:void(0)"
                                                                        class="dropdown-item"
                                                                        class="dropdown-item">Marketing
                                                                        Dept</a>
                                                                    <div class="dropdown-divider"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-body sms-ticket-chat-panel">

                                        <div *ngFor="let chat_details of chat_panel_details;let i=index">
                                            <!------------------------------------
                    Customer Chat
                    ------------------------------------------->
                                            <div class="row"
                                                *ngIf="chat_details.msg_user_type == 'customer' || (chat_details.msg_user_type == 'agent' && chat_details.msg_user_id != loginUser)">
                                                <div class="col-lg-11">
                                                    <div class="message-thread-panel fl">
                                                        <div class="message-thread-header light-org-bg">
                                                            <div class="row">
                                                                <div class="col-md-11">
                                                                    <div class="thread-details-section fl">
                                                                        <div class="ticket-details fl">
                                                                            <ul class="ticket-thread-header-details">
                                                                                <li class="fl">
                                                                                    <h6>
                                                                                        <!-- <span class="blk-clr"
                                                                                            style="padding: 4px 12px;">+60
                                                                                            1235 4520</span>
                                                                                        <small class="fr"><i>3 hours
                                                                                                ago</i></small> -->
                                                                                        <span class="blk-clr"
                                                                                            style="padding: 4px 12px;">{{chat_details.customer_name}}</span>
                                                                                        <small class="fr"><i>{{chat_details.chat_dt}}
                                                                                                {{chat_details.chat_time}}</i></small>
                                                                                    </h6>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="message-thread-body fl">
                                                            <div class="row">
                                                                <div class="col-xl-12">
                                                                    <!-- <p>Test Message !!</p> -->
                                                                    <p>{{chat_details.chat_msg}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!------------------------------------
                    Customer Chat End
                    ------------------------------------------->



                                            <!------------------------------------------
                    Notes
                    ------------------------------------------->
                                            <div class="row notes-row">
                                                <div class="col-lg-1"></div>
                                                <div class="col-lg-10">
                                                    <div
                                                        class="card-header chat-notes-panel padding-0 no-border-bottom">
                                                        <div
                                                            class="message-thread-panel no-margin-bottom light-blue-bg fl">
                                                            <div class="thread-details-section fl">
                                                                <div class="ticket-message-details fl"
                                                                    style="padding: 5px 25px;">
                                                                    <strong
                                                                        style="float: left; padding-right: 10px; font-style: italic;">Notes
                                                                        added by Naveen J at June 7, 2020</strong>
                                                                    <span style="text-align: center;">
                                                                        <span class="blk-clr">
                                                                            : Lorem Ipsum is simply dummy text of the
                                                                            printing and typesetting industry. Lorem
                                                                            Ipsum
                                                                            has been the industry's standard dummy text
                                                                            ever
                                                                            since the 1500s,
                                                                            when an unknown printer took a galley of
                                                                            type
                                                                            and scrambled it to make a type specimen
                                                                            book.
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <!------------------------------------------
                    Notes End
                    ------------------------------------------->

                                            <!------------------------------------
                    Agent Chat
                    ------------------------------------------->
                                            <div class="row"
                                                *ngIf="chat_details.msg_user_type == 'agent' && chat_details.msg_user_id == loginUser">
                                                <div class="col-lg-1"></div>
                                                <div class="col-lg-11">
                                                    <div class="message-thread-panel fl">
                                                        <div class="message-thread-header light-green-bg">
                                                            <div class="row">
                                                                <div class="col-md-11">
                                                                    <div class="thread-details-section fl">
                                                                        <div class="ticket-details fl">
                                                                            <ul class="ticket-thread-header-details">
                                                                                <li class="fl">
                                                                                    <h6>
                                                                                        <span class="blk-clr"
                                                                                            style="padding: 4px 12px;">Naveen
                                                                                            J</span>
                                                                                        <!-- <small class="fr"><i>3 hours
                                                                                                ago</i></small> -->
                                                                                        <small class="fr"><i>{{chat_details.chat_dt}}
                                                                                                {{chat_details.chat_time}}</i></small>
                                                                                    </h6>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="message-thread-body fl">
                                                            <div class="row">
                                                                <div class="col-xl-12">
                                                                    <!-- <p>Test Message !!</p> -->
                                                                    <p>{{chat_details.chat_msg}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!------------------------------------
                    Agent Chat End
                    ------------------------------------------->

                                            <!------------------------------------------
                    Notes
                    ------------------------------------------->
                                            <div class="row notes-row">
                                                <div class="col-lg-1"></div>
                                                <div class="col-lg-10">
                                                    <div
                                                        class="card-header chat-notes-panel padding-0 no-border-bottom">
                                                        <div
                                                            class="message-thread-panel no-margin-bottom light-blue-bg fl">
                                                            <div class="thread-details-section fl">
                                                                <div class="ticket-message-details fl text-center"
                                                                    style="padding: 5px 25px;">
                                                                    <strong
                                                                        style="padding-right: 10px; font-style: italic;">This
                                                                        chat was assigned to Thilak at June 7,
                                                                        2020</strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-1"></div>
                                            </div>
                                            <!------------------------------------------
                    Notes End
                    ------------------------------------------->

                                            <!------------------------------------------
                    Agent Chat
                    ------------------------------------------->
                                            <div class="row">
                                                <div class="col-lg-1"></div>
                                                <div class="col-lg-11">
                                                    <div class="message-thread-panel fl">
                                                        <div class="message-thread-header light-green-bg">
                                                            <div class="row">
                                                                <div class="col-md-11">
                                                                    <div class="thread-details-section fl">
                                                                        <div class="ticket-details fl">
                                                                            <ul class="ticket-thread-header-details">
                                                                                <li class="fl">
                                                                                    <h6>
                                                                                        <span class="blk-clr"
                                                                                            style="padding: 4px 12px;">Thilak</span>
                                                                                        <small class="fr"><i>3 hours
                                                                                                ago</i></small>
                                                                                    </h6>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="message-thread-body fl">
                                                            <div class="row">
                                                                <div class="col-xl-12">
                                                                    <p><b>Lorem Ipsum</b> is simply dummy text of the
                                                                        printing and typesetting industry. Lorem Ipsum
                                                                        has
                                                                        been the industry's standard dummy text ever
                                                                        since
                                                                        the 1500s, when an unknown</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!------------------------------------
                    Agent Chat End
                    ------------------------------------------->
                                        </div>

                                        <div class="card-footer">
                                            <textarea class="richTextArea">
                    <b>Dear  { Customer Name }</b> <br /><br />
                    A request for support has been created and assigned ticket <b>#%{ticket.number}</b> with the following automatic reply
                </textarea>
                                            <br />
                                            <div class="buttons">
                                                <button type="submit" class="btn btn-success"><i
                                                        class="fas fa-paper-plane"></i> Send Message</button>
                                                <button type="submit" class="btn btn-warning"><i class="fas fa-pen"></i>
                                                    Add
                                                    Notes</button>
                                                <button type="submit" class="btn btn-danger"><i
                                                        class="fas fa-trash"></i>
                                                    Discard</button>
                                            </div>

                                        </div>

                                    </div>



                                </div>
                                <!-- Create New Ticket End -->




                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>