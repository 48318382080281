<input type="hidden" id="fbname">
<input type="hidden" id="fbId">
<input type="hidden" id="fbaccessToken">

<div class="section-body">
	<div class="row">
		<div class="col-12 col-lg-12">
			<div class="card omni-fb-config-panel">
				<div class="card-header">
					<h4>Configure</h4>
				</div>
				<div class="card-body p-30">
					<form [formGroup]="singleConfigForm" >
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label for="customer_name">1. Set a name for the integration.</label>
									<input type="text" class="form-control" id="customer_name" name="customer_name"
										placeholder="Customer Name" formControlName="integrate_name">
								</div>
							</div>
							<div class="col-md-12">
								<div class="form-group">
									<label>2. Click on the button below to authorize access to Facebook Page Messaging.
										The Access Token and Page ID fields will be populated automatically when you
										complete this authorization.</label>
									<button type="submit" class="btn btn-primary" (click)="submitLogin()"><i
											class="fab fa-facebook-f"></i>Integrate with Facebook</button>
								</div>
							</div>
							<div class="col-md-12">
								<div class="form-group">
									<label for="access_token">Access Token</label>
									<input type="text" class="form-control" id="access_token" name="access_token"
										placeholder="Access Token" formControlName="access_tokens" readonly onfocus="this.blur()">
								</div>
							</div>
							<div class="col-md-12">
								<div class="form-group">
									<label for="page_id">Page ID</label>
									<input type="text" class="form-control" id="page_id" name="page_id"
										placeholder="Page ID" formControlName="pages_id" readonly onfocus="this.blur()">
								</div>
							</div>
							<div class="col-12">
								<div class="form-group">
									<label for="office_in_time">Department </label>
									<select class="form-control time-zone" id="dept_list" name="dept_list">
										<option value="custom" value="0">Select Department</option>
										<option value="custom" *ngFor="let item of dept_list" value="{{item.dept_id}}">{{item.department_name}}</option>
									</select>
								</div>
							</div>
							
						</div>
						<div class="buttons mt-3">
							<input type="button" class="btn btn-primary" value="Save Changes" type="submit" (click)="updatefbConfig()">
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>

    <!--=============================== Robin LIST ================================== -->

    <div class="row">
        <div class="col-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Incoming Chat Strategy</h4>
                </div>
                <div class="card-body p-30">

                    <div class="row">
                        <div class="col-12 col-md-3">
                            <div class="fl">
                                <h6 style="margin-bottom: 0;" class="font-medium3">All Agents in Department</h6>
                            </div>
                        </div>

                        <div class="col-12 col-md-1">
                            <!-- <div class="connecting-arrow text-center"> -->
                            <div>
                                <i class="fas fa-arrows-alt-h"></i>
                            </div>
                        </div>

                        <div class="col-12 col-md-1">
                            <div class="form-check form-check-inline">
                                <div  class="pretty p-switch p-fill">
                                    <input type="checkbox" id="round_rob_set" (change)="changeChatRobinStatus()">
                                    <div class="state p-success">
                                        <label for="round_rob_set"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-1">
                            <!-- <div class="connecting-arrow text-center"> -->
                            <div>
                                <i class="fas fa-arrows-alt-h"></i>
                            </div>
                        </div>
                        <div class="col-12 col-md-2">
                            <div class="fl">
                                <h6 style="margin-bottom: 0;" class="font-medium2">Round Robin</h6>
                              
							</div>
						</div>
						<div class="col-12 col-md-2" *ngIf="has_round_robin">
							<form [formGroup]="limit_count_form" >												
							<div class="input-group">
								<input type="text" id="fb_limit_count" placeholder="Enter Value"  class="form-control" name="fb_limit_count" formControlName="fb_limit_count">
								<div class="input-group-append">
								   <button type="submit" (click)="robin_update()" class="btn btn-primary">Submit</button>
								</div>
							  </div>
							</form>		
						</div>
                    </div>
                    
                </div>
            </div>
        </div>

      

    </div>
    <!--=============================== END Robin LIST ================================== -->

<div class="modal fade bd-example-modal-md" id="page_subscription">
	<div class="modal-dialog modal-md" style="max-width: 750px;">
		<div class="modal-content">
			<div class="modal-header">

			</div>
			<div class="modal-body text-center">

				<table style="width: 100%;margin-bottom: 15px;">
					<tbody>
						<tr style="border-bottom: 0px;">
							<td valign="middle">
								<img src="../../assets/img/fb.svg" width="100">
							</td>
							<td valign="middle">
								<img style="opacity: 20%; width: 80px;" src="../../assets/img/connection.svg">
							</td>
							<td valign="middle">
								<img style="margin-left: 0px;" class="img-radius" src="../../assets/img/logo.png"
									width="90">
							</td>
						</tr>	
					</tbody>
				</table>

				<h4>Choose a Page to Connect</h4>
				<p style="line-height: 1.5;">Below you'll find a list of Facebook pages to which you have admin access.
					Please select the page you wish to connect now. To connect to other pages, add additional Facebook
					providers in MConnects.</p>

				<div>
					<ul class="list-group">
						<li class="list-group-item" *ngFor="let item of pageselection" style="border: 0px;">
							<input type="radio" id="fb_names_{{item.id}}" value="{{item.id}}"
								(change)="onItemChange($event)">
							<label for="fb_names_{{item.id}}" class="pl-3"
								style="font-size: 16px;">{{item.name}}</label>
						</li>
					</ul>
				</div>


				<div>
					<button type="button" class="btn btn-primary" (click)="subscribeApps()">Enable Facebook
						Integration</button>
				</div>

			</div>
			<!-- <div class="modal-footer" >
			<div class="buttons">
				<input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="close">
			</div>
		</div> -->

		</div>
	</div>
</div>