
<div class="section-body">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Reports</h4>
                    <div class="addBtn">
                        <button type="button" class="btn btn-primary" (click)="addDepartment()">+ Add Report</button>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                       <table class="table table-bordered table-striped table-md">
                         
                        <tr>
                            <th>S.no </th>
                            <th>Report ID</th>
                            <th>Report Name</th>
                            <th>Action</th>
                        </tr>

                        <tbody>
                            <tr *ngFor="let queue of queue_list; let i=index">
                                <td>{{0+i+1}}</td>
                                <td>{{queue.id}}</td>
                                <td>{{queue.report_name}}</td>
                                
                                    <td>
                                        <span class="user-icon" style="cursor: pointer;"><i class="far fa-edit" (click)="editDepartmentSettings(queue.id)"></i></span>
                                    </td>
                                </tr>

                            <tr *ngIf="recordNotFound == true">
                             <td colspan="12">Data not found</td>
                         </tr>
                     </tbody>
                     
                 </table>
             </div>
         </div>
         
     </div>
 </div>
</div>
</div>




<div class="modal fade bd-example-modal-md" id="edit_deptform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="editDept" (ngSubmit)="editDepartment(dep_id)">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Update Report</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="department_name">Report Name</label>
                                <input type="text" id="department_name" class="form-control" formControlName="department_name" >
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="report_path">Report Path</label>
                                <input type="text" id="report_path" class="form-control" formControlName="report_path" >
                            </div>
                        </div>

                        
                        <!-- <div class="col-md-12">
                            <div class="form-group">
                                <label for="status">Status</label>
                                <input type="checkbox" id="status" class="form-control" formControlName="status">
                            </div>
                        </div> -->
                    </div>

                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="submit" class="btn btn-primary update_agent" value="Update" [disabled]="!editDept.valid">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>






<div class="modal fade bd-example-modal-md" id="add_deptform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="addDept" (ngSubmit)="addDeptData()">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Add Report</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="department_name">Report Name</label>
                                <input type="text" id="department_name" class="form-control" formControlName="department_name" >
                            </div>
                        </div>  
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="report_path">Report Path</label>
                                <input type="text" id="report_path" class="form-control" formControlName="report_path" >
                            </div>
                        </div>

                        

                        <!-- <div class="col-md-12">
                            <div class="form-group">
                                <label for="status">Status</label>
                                <input type="checkbox" id="status" class="form-control" formControlName="status">
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="submit" class="btn btn-primary update_agent" value="Update" [disabled]="!addDept.valid">
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>