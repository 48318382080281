<div class="section-body">
    <div class="row">
        <input type="hidden" id="datagetsucc" (click)="datagetsucc()">
        <input type="hidden" id="datagetfailed" (click)="datagetfailed()">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Departments Queue</h4>
                    <div class="addBtn">
                        <button type="button" class="btn btn-primary" (click)="addDepartment()">+ Add
                            Department</button>
                        <button type="button" class="btn btn-primary"  (click)="RetrieveDept()">+ Retrieve From 3CX</button>
                    </div>
                 <!-- <a href="https://uatassaabloyccapi.mconnectapps.com/api/v1.0/pro_contents/pro_department.pdf" target="_blank"><i style="font-size:24px;padding-left: 20px;" class="fa fa-exclamation-circle"></i></a> -->

                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-md">

                            <tr>
                                <th># </th>
                                <!-- <th>Dept ID</th> -->
                                <th>Dept/Queue Name</th>
                                <th>Dept Alias</th>
                                <!-- <th>Calls</th> -->
                                <th>Email</th>
                                <th>Chat</th>
                                <th>Internal Chat</th>
                                <th>WhatsApp</th>
                                <th>Facebook</th>
                                <th>Action</th>
                            </tr>

                            <tbody>
                                <tr *ngFor="let queue of queue_list; let i=index">
                                    <td>{{0+i+1}}</td>
                                    <!-- <td>{{queue.dept_id}}</td> -->
                                    <td>{{queue.department_name}}</td>
                                    <td>{{queue.alias_name}}</td>
                                    <!-- <td>
                                        <button type="button" class="btn btn-primary" (click)="editDepartmentSettings(queue.dept_id)">Edit</button>
                                    </td> -->
                                    <td><div class="form-check form-check-inline">
                                        <div class="pretty p-switch p-fill">
                                            <input type="checkbox"  id="email_{{queue.dept_id}}" checked="{{queue.has_email == 1 ? 'checked' : ''}}" (click)="changeStatus('email',queue.dept_id)"> 
                                            <div class="state p-success">
                                                <label for="has_status"></label>
                                            </div>
                                        </div>
                                    </div>
                                    </td>
                                    <td><div class="form-check form-check-inline">
                                        <div class="pretty p-switch p-fill">
                                            <input type="checkbox"   id="chat_{{queue.dept_id}}" checked="{{queue.has_chat == 1 ? 'checked' : ''}}" (click)="changeStatus('chat',queue.dept_id)"> 
                                            <div class="state p-success">
                                                <label for="has_status"></label>
                                            </div>
                                        </div>
                                    </div>
                                    </td>
                                    <td><div class="form-check form-check-inline">
                                        <div class="pretty p-switch p-fill">
                                            <input disabled type="checkbox"  id="wp_{{queue.dept_id}}"  checked="{{queue.user_status == 1 ? 'checked' : ''}}" (click)="changeStatus('user_status',queue.dept_id)"> 
                                            <div class="state p-success">
                                                <label for="has_status"></label>
                                            </div>
                                        </div>
                                    </div>
                                    </td>
                                    <td><div class="form-check form-check-inline">
                                        <div class="pretty p-switch p-fill">
                                            <input disabled type="checkbox"  id="fb_{{queue.dept_id}}"  checked="{{queue.wp == 1 ? 'checked' : ''}}" (click)="changeStatus('user_status',queue.dept_id)"> 
                                            <div class="state p-success">
                                                <label for="has_status"></label>
                                            </div>
                                        </div>
                                    </div>
                                    </td>
                                    <td><div class="form-check form-check-inline">
                                        <div class="pretty p-switch p-fill">
                                            <input type="checkbox"  id="fb_{{queue.dept_id}}"  checked="{{queue.has_fb == 1 ? 'checked' : ''}}" (click)="changeStatus('user_status',queue.dept_id)"> 
                                            <div class="state p-success">
                                                <label for="has_status"></label>
                                            </div>
                                        </div>
                                    </div>
                                    </td>
                                    <td>
                                        <span class="user-icon" style="cursor: pointer;"><i class="far fa-edit"
                                                (click)="editDepartmentSettings(queue.dept_id)"></i></span>
                                        <span class="user-icon" style="cursor: pointer;"><i class="far fa-trash-alt"
                                                (click)="deletedata(queue.dept_id)"></i></span>
                                    </td>
                                </tr>

                                <tr *ngIf="recordNotFound == true">
                                 <td colspan="12">Data not found</td>
                             </tr>
                         </tbody>

                     </table>
                 </div>
             </div>

         </div>
     </div>
 </div>
</div>




<div class="modal fade bd-example-modal-xl" id="edit_deptform">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <form [formGroup]="editDept" (ngSubmit)="editDepartment(dep_id)">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Update Departments Queue</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <h6>Department Name</h6>
                        </div>
                        <div class="col-md-6">
                            <h6>Department Alias Name</h6>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" id="department_name" class="form-control"
                                    formControlName="department_name">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" id="department_alias_name" class="form-control"
                                    formControlName="department_alias_name">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-6">
                            <div class="form-group">
                                <label for="department_users">Select Department Users</label>
                                <input type="text" id="department_users" class="form-control" formControlName="department_users">
                            </div>
                            
                        </div> -->

                    <div class="row">
                        <div class="col-md-12">
                            <h6>Department Users</h6>
                        </div>
                    </div>


                    <div class="card reorder-card mt-3">

                        <div class="row has-row-space">
                            <!-- <div class="col-md-12">
                            <div class="checkbox-row" *ngFor="let agent of agents_list">
                                <div class="form-check form-check-inline">
                                    <div class="pretty p-switch p-fill">
                                        <input type="checkbox" id="has_wechat"
                                            [attr.checked]="userchecked && userchecked.indexOf(agent.user_id) == -1 ? checked : ''"
                                            class="ads_Checkbox" value="{{agent.user_id}}" name="listusers[]">
                                        <div class="state p-success">
                                            <label for="listusers">{{agent.user_name}} - {{agent.agent_name}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                            <div class="col-md-12">
                                <div class="reorder-panel">

                                    <div #activeList="cdkDropList" class="box-list" cdkDropList
                                        cdkDropListOrientation="vertical" [cdkDropListData]="activeCustomers"
                                        [cdkDropListConnectedTo]="[inactiveList]" (cdkDropListDropped)="drop($event)">
                                        <h6>Active agent (Queue Order)</h6>
                                        <div class="drag-box" *ngFor="let customer of activeCustomers" cdkDrag>
                                             {{customer | slice:5:150}}
                                        </div>
                                    </div>


                                    <!-- <div #inactiveList="cdkDropList" class="box-list inactiveCustomers" cdkDropList
                                    cdkDropListOrientation="vertical" [cdkDropListData]="inactiveCustomers"
                                    [cdkDropListConnectedTo]="[activeList]" (cdkDropListDropped)="drop($event)">
                                    <h6>InActive customers</h6>
                                    <div class="drag-box" *ngFor="let customer of inactiveCustomers" cdkDrag>
                                        {{customer}}
                                    </div>
                                </div> -->
                                    <div #inactiveList="cdkDropList" class="box-list inactiveCustomers" cdkDropList
                                        cdkDropListOrientation="vertical" [cdkDropListData]="inactiveCustomers"
                                        [cdkDropListConnectedTo]="[activeList]" (cdkDropListDropped)="drop($event)">
                                        <h6>InActive agent</h6>
                                        <div class="drag-box" *ngFor="let customer of inactiveCustomers" cdkDrag>
                                            {{customer | slice:5:150}}
                                        </div>
                                    </div>

                                    <!-- <pre>{{pre}}</pre> -->



                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- <div class="row has-row-space">

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="reports">Select Wraup Catagory</label>
                                <select name="reports"  id="update_reports" class="form-control" >
                                    <option *ngFor="let report of wraup_cat_list" value="{{report.id}}" >{{report.category_name}}</option>
                                </select>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="row">
                            <div class="col-md-12">
                                <h6>Status</h6>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="checkbox-row">
                                    <div class="form-check form-check-inline">
                                        <div class="pretty p-switch p-fill">
                                            <input type="checkbox" id="status" class="form-control" formControlName="status" >
                                            <div class="state p-success">
                                                <label for="status">Status</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="submit" class="btn btn-primary update_agent" value="Update"
                            [disabled]="!editDept.valid">
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>






<div class="modal fade bd-example-modal-xl" id="add_deptform">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <form [formGroup]="addDept" (ngSubmit)="addDeptData()">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Add Departments Queue</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="row">
                        <div class="col-md-6">
                            <h6>Department Name</h6>
                        </div>
                        <div class="col-md-6">
                            <h6>Department Alias Name</h6>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" id="department_name" class="form-control"
                                    formControlName="department_name">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" id="department_alias_name" class="form-control"
                                    formControlName="department_alias_name">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <h6>Department Users</h6>
                        </div>
                    </div>
                    <div class="card reorder-card mt-3">

                        <div class="row has-row-space">
                            <!-- <div class="col-md-12">
                        <div class="checkbox-row" *ngFor="let agent of agents_list">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" class="add_Checkbox" value="{{agent.user_id}}" name="addlistusers[]">
                                    <div class="state p-success">
                                        <label for="listusers">{{agent.user_name}} - {{agent.agent_name}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  -->
                            <div class="col-md-12">
                                <div class="reorder-panel">

                                    <div #showactiveList="cdkDropList" class="box-list" cdkDropList
                                        cdkDropListOrientation="vertical" [cdkDropListData]="ShowactiveCustomers"
                                        [cdkDropListConnectedTo]="[showinactiveList]" (cdkDropListDropped)="drop($event)">
                                        <h6>Active agent (Queue Order)</h6>
                                        <div class="drag-box" *ngFor="let customer of ShowactiveCustomers" cdkDrag>
                                            {{customer | slice:5:150}}
                                        </div>
                                    </div>

                                <!-- <div #inactiveList="cdkDropList" class="box-list inactiveCustomers" cdkDropList
                                cdkDropListOrientation="vertical" [cdkDropListData]="inactiveCustomers"
                                [cdkDropListConnectedTo]="[activeList]" (cdkDropListDropped)="drop($event)">
                                <h6>InActive customers</h6>
                                <div class="drag-box" *ngFor="let customer of inactiveCustomers" cdkDrag>
                                    {{customer}}
                                </div>
                            </div> -->
                                <div #showinactiveList="cdkDropList" class="box-list inactiveCustomers" cdkDropList
                                    cdkDropListOrientation="vertical" [cdkDropListData]="ShowInactiveCustomers"
                                    [cdkDropListConnectedTo]="[showactiveList]" (cdkDropListDropped)="drop($event)">
                                    <h6>InActive agent</h6>
                                    <div class="drag-box" *ngFor="let customer of ShowInactiveCustomers" cdkDrag>
                                        {{customer | slice:5:150}}
                                    </div>
                                </div>

                                <!-- <pre>{{activepre}}</pre> -->



                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- <div class="row has-row-space">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="reports">Select Wraup Catagory</label>
                                <select name="reports" id="edit_reports" class="form-control" >
                                    <option *ngFor="let report of wraup_cat_list" value="{{report.id}}" >{{report.category_name}}</option>
                                </select>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="row">
                    <div class="col-md-12">
                        <h6>Status</h6>
                    </div>

                    <div class="col-md-12">
                         <div class="checkbox-row">
                            <div class="form-check form-check-inline">
                                <div class="pretty p-switch p-fill">
                                    <input type="checkbox" id="status" class="form-control" formControlName="status" >
                                    <div class="state p-success">
                                        <label for="status">Status</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> -->
                </div>

                <div class="modal-footer">
                    <div class="buttons">
                        <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                        <input type="submit" class="btn btn-primary update_agent" value="Update"
                            [disabled]="!addDept.valid">
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>

<!-- <div class="modal fade bd-example-modal-md" id="document_model">
    <div class="modal-dialog modal-md" style="max-width: 1200px;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" style="color:crimson">Help Manual</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">            
                <embed [src]="this.doc_link | safe: 'resourceUrl'" width="100%" style="height: 575px;" />
            </div>
            <div class="modal-footer">
                <div class="buttons">
                    <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="close">
                </div>
            </div>

        </div>
    </div>
</div> -->