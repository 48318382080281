<div class="section-body upload-section">
	<div class="row">
		<div class="col-12 col-xl-12">
			<div class="card">
				<div class="card-header">
					<h4>Predictive dailer Versions</h4>
				</div>
				<div class="card-body">
					<div class="form-group">
						<label>Upload Document</label>
						<input type="file" class="form-control" name="pd_main" id="pd_main">
					</div>
					<div class="view-btn-panel">
						<ul>
							<li>
								<a href="{{this.prdic_dialer_main}}" class="btn btn-info" target="_blank" ><i class="fas fa-eye"></i></a>
							</li>
							<li>
								<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('pd_main')"><i class="fas fa-trash-alt"></i></a>
							</li>
						</ul>
					</div>
				</div>
				<div class="card has-m-30">
					<div class="card-header">
						<h4>Predictive dailer Version {{this.pd_version}}</h4>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<div class="form-group">
									<label>Version</label>
									<input type="text" name="pd_version" id="pd_version" class="form-control">
								</div>
							</div>
							<div class="col-xl-6 col-12">
								<div class="form-group">
									<label>Upload Campaingn</label>
									<input type="file" class="form-control" name="camp_1" id="camp_1">
								</div>
								<div class="view-btn-panel" >
									<ul>
										<li>
											<a href="{{this.pd_camp_1}}" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
										</li>
										<li>
											<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('camp_1')"><i class="fas fa-trash-alt"></i></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- <div class="card has-m-30">
					<div class="card-header">
						<h4>Predictive dailer Version 2.0</h4>
						<div class="addBtn">
							<button class="btn btn-info">
								<div class="pretty p-icon p-smooth"><input type="checkbox"><div class="state p-warning"><i class="icon material-icons">done</i><label></label></div></div>
								Mark as Default
							</button>
							<div class="display-options">
								<div class="form-check form-check-inline">
									<div class="pretty p-switch p-fill">
										<input type="checkbox">
										<div class="state p-success">
											<label _ngcontent-hyj-c9="" for="has_status"></label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card-body">
						<div class="row upload-section">
							<div class="col-xl-6 col-12">
								<div class="form-group">
									<label>Upload Camp 1</label>
									<input type="file" class="form-control">
								</div>
								<div class="view-btn-panel">
									<ul>
										<li>
											<a href="https://www.calncall.com/wp-content/uploads/2018/10/CALNCALL-FAX.pdf" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
										</li>
										<li>
											<a href="javascript:;" class="btn btn-danger" target="_blank"><i class="fas fa-trash-alt"></i></a>
										</li>
									</ul>
								</div>
							</div>
							<div class="col-xl-6 col-12">
								<div class="form-group">
									<label>Upload Camp 2</label>
									<input type="file" class="form-control">
								</div>
								<div class="view-btn-panel">
									<ul>
										<li>
											<a href="https://www.calncall.com/wp-content/uploads/2018/10/CALNCALL-FAX.pdf" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
										</li>
										<li>
											<a href="javascript:;" class="btn btn-danger" target="_blank"><i class="fas fa-trash-alt"></i></a>
										</li>
									</ul>
								</div>
							</div>
							<div class="col-xl-6 col-12">
								<div class="form-group">
									<label>Upload Camp 3</label>
									<input type="file" class="form-control">
								</div>
								<div class="view-btn-panel">
									<ul>
										<li>
											<a href="https://www.calncall.com/wp-content/uploads/2018/10/CALNCALL-FAX.pdf" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
										</li>
										<li>
											<a href="javascript:;" class="btn btn-danger" target="_blank"><i class="fas fa-trash-alt"></i></a>
										</li>
									</ul>
								</div>
							</div>
							<div class="col-xl-6 col-12">
								<div class="form-group">
									<label>Upload Camp 4</label>
									<input type="file" class="form-control">
								</div>
								<div class="view-btn-panel">
									<ul>
										<li>
											<a href="https://www.calncall.com/wp-content/uploads/2018/10/CALNCALL-FAX.pdf" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
										</li>
										<li>
											<a href="javascript:;" class="btn btn-danger" target="_blank"><i class="fas fa-trash-alt"></i></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div> -->

				<div class="single-btn-group">
					<button type="button" class="btn btn-success" (click)="uploadPredictive()">Save Changes</button> &nbsp;&nbsp;
					<!-- <button type="button" class="btn btn-info">+ Add New Version</button> -->
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-12 col-xl-12">
			<div class="card">
				<div class="card-header">
					<h4>Proactive Dialler (Agent first) </h4>
				</div>
				<div class="card-body">
					<div class="form-group">
						<label>Upload Document</label>
						<input type="file" class="form-control" name="pro_pd_main" id="pro_pd_main">
					</div>
					<div class="view-btn-panel">
						<ul>
							<li>
								<a href="{{this.pro_main}}" class="btn btn-info" target="_blank" ><i class="fas fa-eye"></i></a>
							</li>
							<li>
								<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('pro_main')"><i class="fas fa-trash-alt"></i></a>
							</li>
						</ul>
					</div>
				</div>
				<div class="card has-m-30">
					<div class="card-header">
						<h4>Proactive Dialler Version {{this.pro_version}}</h4>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<div class="form-group">
									<label>Version</label>
									<input type="text" name="pro_pd_version" id="pro_pd_version" class="form-control">
								</div>
							</div>
							<div class="col-xl-6 col-12">
								<div class="form-group">
									<label>Upload Campaingn</label>
									<input type="file" class="form-control" name="pro_camp_1" id="pro_camp_1">
								</div>
								<div class="view-btn-panel" >
									<ul>
										<li>
											<a href="{{this.pro_camp_1}}" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
										</li>
										<li>
											<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('pro_camp_1')"><i class="fas fa-trash-alt"></i></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>


				<div class="single-btn-group">
					<button type="button" class="btn btn-success" (click)="uploadProacctive()">Save Changes</button> &nbsp;&nbsp;
					<!-- <button type="button" class="btn btn-info">+ Add New Version</button> -->
				</div>
			</div>
		</div>
	</div>


	<div class="row">
		<div class="col-12 col-xl-12">
			<div class="card">
				<div class="card-header">
					<h4>Broadcast Dialler</h4>
				</div>
				<div class="card-body">
					<div class="form-group">
						<label>Upload Document</label>
						<input type="file" class="form-control" name="bro_pd_main" id="bro_pd_main">
					</div>
					<div class="view-btn-panel">
						<ul>
							<li>
								<a href="{{this.bd_main}}" class="btn btn-info" target="_blank" ><i class="fas fa-eye"></i></a>
							</li>
							<li>
								<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('bd_main')"><i class="fas fa-trash-alt"></i></a>
							</li>
						</ul>
					</div>
				</div>
				<div class="card has-m-30">
					<div class="card-header">
						<h4>Broadcast Dialler Version  {{this.bd_version}}</h4>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<div class="form-group">
									<label>Version</label>
									<input type="text" name="bro_pd_version" id="bro_pd_version" class="form-control">
								</div>
							</div>
							<div class="col-xl-6 col-12">
								<div class="form-group">
									<label>Upload Campaingn 1</label>
									<input type="file" class="form-control" name="bro_camp_1" id="bro_camp_1">
								</div>
								<div class="view-btn-panel" >
									<ul>
										<li>
											<a href="{{this.bd_camp_1}}" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
										</li>
										<li>
											<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('bd_camp_1')"><i class="fas fa-trash-alt"></i></a>
										</li>
									</ul>
								</div>
								<div class="form-group">
									<label>Upload Campaingn 2</label>
									<input type="file" class="form-control" name="bro_camp_2" id="bro_camp_2">
								</div>
								<div class="view-btn-panel" >
									<ul>
										<li>
											<a href="{{this.bd_camp_2}}" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
										</li>
										<li>
											<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('bd_camp_2')"><i class="fas fa-trash-alt"></i></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>


				<div class="single-btn-group">
					<button type="button" class="btn btn-success" (click)="uploadBroacctive()">Save Changes</button> &nbsp;&nbsp;
					<!-- <button type="button" class="btn btn-info">+ Add New Version</button> -->
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-12 col-xl-12">
			<div class="card">
				<div class="card-header">
					<h4>Broadcast Survey Dialler</h4>
				</div>
				<div class="card-body">
					<div class="form-group">
						<label>Upload Document</label>
						<input type="file" class="form-control" name="bros_pd_main" id="bros_pd_main">
					</div>
					<div class="view-btn-panel">
						<ul>
							<li>
								<a href="{{this.bds_main}}" class="btn btn-info" target="_blank" ><i class="fas fa-eye"></i></a>
							</li>
							<li>
								<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('bds_main')"><i class="fas fa-trash-alt"></i></a>
							</li>
						</ul>
					</div>
				</div>
				<div class="card has-m-30">
					<div class="card-header">
						<h4>Broadcast Survey Dialler Version {{this.bs_version}}</h4>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<div class="form-group">
									<label>Version</label>
									<input type="text" name="bros_pd_version" id="bros_pd_version" class="form-control">
								</div>
							</div>
							<div class="col-xl-6 col-12">
								<div class="form-group">
									<label>Upload Campaingn 1</label>
									<input type="file" class="form-control" name="bros_camp_1" id="bros_camp_1">
								</div>
								<div class="view-btn-panel" >
									<ul>
										<li>
											<a href="{{this.bds_camp_1}}" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
										</li>
										<li>
											<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('bds_camp_1')"><i class="fas fa-trash-alt"></i></a>
										</li>
									</ul>
								</div>
								<div class="form-group">
									<label>Upload Campaingn 2</label>
									<input type="file" class="form-control" name="bros_camp_2" id="bros_camp_2">
								</div>
								<div class="view-btn-panel" >
									<ul>
										<li>
											<a href="{{this.bds_camp_2}}" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
										</li>
										<li>
											<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('bds_camp_2')"><i class="fas fa-trash-alt"></i></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>


				<div class="single-btn-group">
					<button type="button" class="btn btn-success" (click)="uploadBroacctives()">Save Changes</button> &nbsp;&nbsp;
					<!-- <button type="button" class="btn btn-info">+ Add New Version</button> -->
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-12 col-xl-12">
			<div class="card">
				<div class="card-header">
					<h4>Agent Rating Versions</h4>
				</div>
				<div class="card-body">
					<div class="form-group">
						<label>Upload Document</label>
						<input type="file" class="form-control" name="agent_rating_main" id="agent_rating_main">
					</div>
					<div class="view-btn-panel">
						<ul>
							<li>
								<a href="{{this.agent_rating_main}}" class="btn btn-success" target="_blank"><i class="fas fa-eye"></i></a>
							</li>
							<li>
								<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('agent_rating_main')"><i class="fas fa-trash-alt"></i></a>
							</li>
						</ul>
					</div>
				</div>
				<div class="card has-m-30">
					<div class="card-header">
						<h4>Agent Rating Version 1.0</h4>
						<!-- <div class="addBtn">
							<button class="btn btn-success">
								<div class="pretty p-icon p-smooth"><input type="checkbox" name="default_ar" id="default_ar"><div class="state p-warning"><i class="icon material-icons">done</i><label></label></div></div>
								Mark as Default
							</button>
							<div class="display-options">
								<div class="form-check form-check-inline">
									<div class="pretty p-switch p-fill">
										<input type="checkbox" name="toggle_ar" id="toggle_ar">
										<div class="state p-success">
											<label _ngcontent-hyj-c9="" for="has_status"></label>
										</div>
									</div>
								</div>
							</div>
						</div> -->
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<div class="form-group">
									<label>Version</label>
									<input type="text" name="agent_rating_version" id="agent_rating_version" class="form-control">
								</div>
							</div>
							<div class="col-xl-6 col-12">
								<div class="form-group">
									<label>Agent Rating 1</label>
									<input type="file" class="form-control" name="agent_rating_1" id="agent_rating_1">
								</div>
								<div class="view-btn-panel">
									<ul>
										<li>
											<a href="{{this.ar_1}}" class="btn btn-success" target="_blank"><i class="fas fa-eye"></i></a>
										</li>
										<li>
											<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('agent_rating_1')"><i class="fas fa-trash-alt"></i></a>
										</li>
									</ul>
								</div>
							</div>
							<div class="col-xl-6 col-12">
								<div class="form-group">
									<label>Agent Rating 2</label>
									<input type="file" class="form-control"  name="agent_rating_2" id="agent_rating_2">
								</div>
								<div class="view-btn-panel">
									<ul>
										<li>
											<a href="{{this.ar_2}}" class="btn btn-success" target="_blank"><i class="fas fa-eye"></i></a>
										</li>
										<li>
											<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('agent_rating_2')"><i class="fas fa-trash-alt"></i></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="single-btn-group">
					<button type="button" class="btn btn-success" (click)="uploadAgent()">Save Changes</button> &nbsp;&nbsp;
					<!-- <button type="button" class="btn btn-info">+ Add New Version</button> -->
				</div>
			</div>
		</div>
	</div>


	<div class="row">
		<div class="col-12 col-xl-12">
			<div class="card">
				<div class="card-header">
					<h4>Mr.VoIP </h4>
				</div>
				<div class="card-body">
					<div class="form-group">
						<label>Upload Document</label>
						<input type="file" class="form-control" name="mrvoip_main" id="mrvoip_main">
					</div>
					<div class="view-btn-panel">
						<ul>
							<li>
								<a href="{{this.mrvoip_main}}" class="btn btn-success" target="_blank"><i class="fas fa-eye"></i></a>
							</li>
							<li>
								<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('mrvoip_main')"><i class="fas fa-trash-alt"></i></a>
							</li>
						</ul>
					</div>
				</div>
				<div class="card has-m-30">
					<div class="card-header">
						<h4>Mr.Voip Document Upload</h4>
						<!-- <div class="addBtn">
							<button class="btn btn-warning">
								<div class="pretty p-icon p-smooth"><input type="checkbox" id="mdf_mrvoip"><div class="state p-warning"><i class="icon material-icons">done</i><label></label></div></div>
								Mark as Default
							</button>
							<div class="display-options">
								<div class="form-check form-check-inline">
									<div class="pretty p-switch p-fill">
										<input type="checkbox" id="d_toggle_mrvoip">
										<div class="state p-success">
											<label _ngcontent-hyj-c9="" for="has_status"></label>
										</div>
									</div>
								</div>
							</div>
						</div> -->
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<div class="form-group">
									<label>Version</label>
									<input type="text" name="mrvoip_version" id="mrvoip_version" class="form-control">
								</div>
							</div>

							<div class="col-xl-6 col-12">
								<div class="form-group">
									<label><i class="fab fa-linux"></i> Linux File</label>
									<input type="file" name="linux_document_1" id="linux_document_1" class="form-control">
								</div>
								<div class="view-btn-panel">
									<ul>
										<li>
											<a href="{{this.mrvoip_lin_file}}" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
										</li>
										<li>
											<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('linux_document_1')"><i class="fas fa-trash-alt"></i></a>
										</li>
									</ul>
								</div>
							</div>
							
							<div class="col-xl-6 col-12">
								<div class="form-group">
									<label><i class="fab fa-windows"></i> Windows File</label>
									<input type="file" name="windows_document_1" id="windows_document_1" class="form-control">
								</div>
								<div class="view-btn-panel">
									<ul>
										<li>
											<a href="{{this.mrvoip_win_file}}" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
										</li>
										<li>
											<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('windows_document_1')"><i class="fas fa-trash-alt"></i></a>
										</li>
									</ul>
								</div>
							</div>

							<div class="col-xl-6 col-12">
								<div class="form-group">
									<label><i class="fab fa-linux"></i> Linux Document</label>
									<input type="file" name="mrvoip_linux" id="mrvoip_linux" class="form-control">
								</div>
								<div class="view-btn-panel">
									<ul>
										<li>
											<a href="{{this.mrvoip_lin}}" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
										</li>
										<li>
											<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('mrvoip_linux')"><i class="fas fa-trash-alt"></i></a>
										</li>
									</ul>
								</div>
							</div>
							<div class="col-xl-6 col-12">
								<div class="form-group">
									<label><i class="fab fa-windows"></i> Windows Document</label>
									<input type="file" name="mrvoip_windows" id="mrvoip_windows" class="form-control">
								</div>
								<div class="view-btn-panel">
									<ul>
										<li>
											<a href="{{this.mrvoip_win}}" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
										</li>
										<li>
											<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('mrvoip_windows')"><i class="fas fa-trash-alt"></i></a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

<!-- Version 2 Upload Start -->
<div class="card-body">
	<div class="row">
		<div class="col-12">
			<div class="form-group">
				<label>Version</label>
				<input type="text" name="mrvoip_version2" id="mrvoip_version2" class="form-control">
			</div>
		</div>

		<div class="col-xl-6 col-12">
			<div class="form-group">
				<label><i class="fab fa-linux"></i> Linux File 2</label>
				<input type="file" name="linux2_file" id="linux2_file" class="form-control">
			</div>
			<div class="view-btn-panel">
				<ul>
					<li>
						<a href="{{this.mrvoip_lin2}}" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
					</li>
					<li>
						<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('linux2_file')"><i class="fas fa-trash-alt"></i></a>
					</li>
				</ul>
			</div>
		</div>
		
		<div class="col-xl-6 col-12">
			<div class="form-group">
				<label><i class="fab fa-windows"></i> Windows File 2</label>
				<input type="file" name="window_file" id="window_file" class="form-control">
			</div>
			<div class="view-btn-panel">
				<ul>
					<li>
						<a href="{{this.mrvoip_win2}}" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
					</li>
					<li>
						<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('windows2_doc')"><i class="fas fa-trash-alt"></i></a>
					</li>
				</ul>
			</div>
		</div>

		<div class="col-xl-6 col-12">
			<div class="form-group">
				<label><i class="fab fa-linux"></i> Linux Document 2</label>
				<input type="file" name="linux2_doc" id="linux2_doc" class="form-control">
			</div>
			<div class="view-btn-panel">
				<ul>
					<li>
						<a href="{{this.mrvoip_lin_file2}}" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
					</li>
					<li>
						<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('linux2_doc')"><i class="fas fa-trash-alt"></i></a>
					</li>
				</ul>
			</div>
		</div>
		<div class="col-xl-6 col-12">
			<div class="form-group">
				<label><i class="fab fa-windows"></i> Windows Document 2</label>
				<input type="file" name="windows2_doc" id="windows2_doc" class="form-control">
			</div>
			<div class="view-btn-panel">
				<ul>
					<li>
						<a href="{{this.mrvoip_win_file2}}" class="btn btn-info" target="_blank"><i class="fas fa-eye"></i></a>
					</li>
					<li>
						<a href="javascript:;" class="btn btn-danger"  (click)="deletedata('windows2_doc')"><i class="fas fa-trash-alt"></i></a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>

<!-- Version 2 file Upload END -->


				</div>
	<input type="hidden" id="reload" (click)="allDocuments()">
				<div class="single-btn-group">
					<button type="button" class="btn btn-success"  (click)="uploadNRVOIP()">Save Changes</button> &nbsp;&nbsp;
					<!-- <button type="button" class="btn btn-info">+ Add New Version</button> -->
				</div>
			</div>
		</div>
	</div>
</div>