
<div class="section-body">
    <div class="row">
        <div class="col-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4> Call Tariff Report</h4>
                  
                </div>
                <div class="card-body p-30">
                <form [formGroup]="getRep" >
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="from_date">From Date</label>
                                <input type="date" id="from_dt" class="form-control" formControlName="from_dt">
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="to_date">To Date</label>
                                <input type="date" id="to_dt" class="form-control" formControlName="to_dt">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="from_no">From num</label>
                                <input type="text" id="from_no" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="to_no">To num</label>
                                <input type="text" id="to_no" class="form-control" >
                            </div>
                        </div>
                        <!-- <div class="col-md-6">
                            <div class="form-group">
                                <label for="rep_format">Report Format</label>
                               <select class="form-control" formControlName="rep_format">
                                   <option value="html">Html</option>
                                   <option value="excel">Excel</option>
                               </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="report_name">Report Name</label>
                                <input type="report_name"  class="form-control" formControlName="report_name">
                            </div>
                        </div> -->
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <button type="button" class="btn btn-primary" style="margin-right: 15px;" (click)="searchData()">Search Report</button>

                                <button type="button" class="btn btn-primary" (click)="genReport()">Generate Report</button>
                            </div>
                        </div>
                    </div>
                    
                </form>
                </div>
            </div>
            
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>Tariff History</h4>
                    <!-- <div class="addBtn">
                        <button type="button" class="btn btn-primary" >SMS Balance: ${{this.price_sms}}</button>
                    </div> -->
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-striped table-md">
                            <!-- <tr>
                                <td colspan="12" class="search-text-tbl">
                                    <div class="input-group"><input type="text" class="form-control" (keyup.enter)="searchData({'search_text':call_history_search.value})" #call_history_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="searchData({'search_text':call_history_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr> -->
                            <tr>
                                <th>S.no</th>
                                <th>Call to</th>
                                <th>Call By</th>
                                <th>Duration</th>
                                <th>Call Rate</th>
                                <th>Call Start</th>
                                <th>Call End</th>
                                <th>Billing Code</th>
                                <th>Billing Country</th>
                                
                            </tr>
                            <tr *ngFor="let history of call_history_list;let i=index" >
                                <td>{{offset_count+i+1}}</td>
                                <td>{{history.to}}</td>
                                <td>{{history.from_no}}</td>
                                <td>{{history.duration}}</td>
                                <td>{{history.call_rate}}</td>
                                <td>{{history.call_start}}</td>
                                <td>{{history.call_end}}</td>
                                <td>{{history.billing_code}}</td>
                                <td>{{history.billing_country}}</td>
                            </tr>
                            <tr *ngIf="recordNotFound == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                      
                        </table>
                    </div>
                </div>
             
            </div>
        </div>
    </div>


</div>