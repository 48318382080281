<div class="section-body edit-form">
	<input type="hidden" id="dup_user_number">
	<input type="hidden" id="dup_wrapup_callID">
	<input type="hidden" id="dup_queue_ids3">
	<input type="hidden" id="dup_con_reconnect_socket" (click)="initSocket()">

	<div style="margin-left: 27px;" class="card">
		<div class="card-header">
			<h4><i class="fa fa-address-book" aria-hidden="true"></i> {{contact_name}}</h4>
			<!-- <p>Contact</p> -->

		</div>
	</div>
	<form *ngIf="alladmin">

		<div class="row call-contact-inner">
			<div class="col-12 col-md-6">
				<div class="card">

					<div class="card-body p-0">
						<div class="form-panel">
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label for="acc_name">Account Name </label>
										<input type="text" id="dup_acc_name" class="form-control" disabled>
									</div>
								</div>
								<div class="col-md-12">
									<div class="form-group">
										<!-- <label for="account_ids">Account ID </label> -->
										<label for="ax_name">AX Account </label>
										<input type="hidden" id="dup_account_ids" class="form-control">
										<input type="text" id="dup_ax_name" class="form-control" disabled>
									</div>
								</div>
								<div class="col-md-12">
									<div class="form-group">
										<label for="first_name">First Name </label>
										<input type="text" id="dup_first_name" class="form-control">
									</div>
								</div>
								<div class="col-md-12">
									<div class="form-group">
										<label for="last_name">Last Name </label>
										<input type="text" id="dup_last_name" class="form-control">
									</div>
								</div>
								<div class="col-md-12">
									<div class="form-group">
										<label for="job_title">Job Title </label>
										<input type="text" id="dup_job_title" class="form-control">
									</div>
								</div>
								<!-- <div class="col-md-12">
									<div class="form-group">
										<label for="account_name">Account Name </label>
										<input type="text" id="dup_account_name"  class="form-control"  >
									</div>
								</div>				 -->
								<!-- <i class="fas fa-envelope-open-text"></i>				 -->
								<div class="col-md-12">
									<div class="form-group">
										<label for="email"><i class="fas fa-envelope-open-text"></i>Email</label>
										<input type="text" id="dup_email" class="form-control">
										<!-- <label class="error-message message-small" *ngIf="!editContact.get('email').valid && editContact.get('email').touched ">Please enter the valid email id</label> -->
									</div>
								</div>

								<div class="col-md-10">
									<div class="form-group" style="position: absolute;">
										<label for="business_phone"> Business Phone<i title="Click to call"
												class="fa fa-phone-square" aria-hidden="true" (click)="clictToCall()"
												style="cursor: pointer;"></i>
										</label>
										<input type="tel" id="dup_business_phone_dup" class="form-control">
									</div>
									<div *ngIf="showphoneloader">
										<img class="loading-phone" src="assets/images/test.gif">
									</div>
								</div>
								<div class="col-md-2">
									<div class="form-group" style="margin-top: 24px;">
										<button class="btn btn-primary" (click)="SearchContacts()" title="search_text"
											style="float: right;padding-right: revert;"><i
												class="fas fa-search"></i></button>
									</div>
								</div>
								<div class="col-md-12">
									<a *ngIf="refineshow" class="refine-text" (click)="openRefinement()">Refined
										More</a>
								</div>
								<i class="fas fa-phone-alt"></i>
								<div class="col-md-12">
									<div class="form-group">
										<label for="mobile_phone"> Mobile Phone<i title="Click to call"
												class="fa fa-phone-square" aria-hidden="true" (click)="clictToCall2()"
												style="cursor: pointer;"></i>
										</label>
										<input type="text" id="dup_mobile_phone" class="form-control">
									</div>
								</div>
								<!-- <i class="fas fa-fax"></i> -->
								<div class="col-md-12">
									<div class="form-group">
										<label for="fax"> <i class="fas fa-fax"></i>Fax</label>
										<input type="text" id="dup_fax" class="form-control">
										<!-- <label class="error-message message-small" *ngIf="!editContact.get('email').valid && editContact.get('email').touched ">Please enter the valid email id</label> -->
									</div>
								</div>
								<!-- <div class="col-md-12">
									<div class="buttons form-group">
										<input type="button" class="btn btn-secondary"  value="Update Wrapup" (click)="AddContact()" value="Close" type="button">
									
									</div>
								</div> -->

							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="card">

					<div class="card-body p-0">
						<div class="form-panel" style="height: 689px;
						overflow: scroll;">
							<div class="row">
								<div class="card-header">
									<h4 style="font-size: 30px;">Timeline</h4>
									<div class="addBtn">
										<button class="btn btn-primary" (click)="editConatcts()" title="Refresh"
											style="float: right;padding-right: revert;"><i
												class="fas fa-sync-alt"></i></button>
									</div>
								</div>
								<!-- <div class="col-md-12"> -->
								<div class="card ticket-status-open ticket-card"
									*ngFor="let item of phone_activity; let i = index">
									<div class="form-group">
										<h5>
											<span *ngIf="item.subject=='Inbound Call'" class="callType"><img
													src="assets/images/incoming-call.svg"></span>
											<span *ngIf="item.subject=='Outbound Call'" class="callType"><img
													src="assets/images/outgoing-call.svg"></span>

											<i *ngIf="item.subject!='Outbound Call' && item.subject!='Inbound Call'"
												style="font-size: 17px;" class="fa fa-phone"></i>
											Phone Call
										</h5>
										<h6>{{item.subject}}</h6>
										<p>{{item.description}}</p>
										<p style="float: right;padding-right: 19px;"><i class="far fa-clock"></i><b>
												{{item.modifiedon | date: 'dd/MM/yyyy h:MM:ss'}}</b></p>
										<button style="float: left;padding-right: 19px;margin-top: -7px;"
											class="btn btn-secondary" (click)="gettimedetails(item.activityid)"
											title="Refresh"><i class="fas fa-eye"></i>View</button>

										<!-- <p ><i class="far fa-clock"></i><b>   {{item.modifiedon | date: 'dd/MM/yyyy h:MM:ss'}}</b></p> -->

									</div>
								</div>
								<div class="card ticket-status-open ticket-card" *ngIf="recordNotFound == true">
									<div class="form-group">
										<h5>No Activities Found</h5>

									</div>
								</div>


							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</form>
</div>



<div class="card" *ngIf="dailyfood">
	<div class="card-header" *ngIf="this.has_external_contact == '0'">
		<h4>Has DSK Access</h4>
	</div>
	<div class="card-header" *ngIf="this.has_external_contact == '1'">
		<h4>Contact</h4>
	</div>
	<div class="card-body p-0" *ngIf="this.has_external_contact == '0'">
		<iframe [src]="this.dailyfoodurl" id="dup_dailyfIframe" class="dailyfIfram" width="100%" height="100%"
			frameborder="0" allowfullscreen></iframe>
	</div>
	<div class="card-body p-0" *ngIf="this.has_external_contact == '1'">
		<div id="dup_dailyfIframes">

		</div>
	</div>
</div>

<div class="settingSidebar">
	<a href="javascript:void(0)" class="settingPanelToggle" (click)="toggleClass()">
		<span title="Add Wrapup Code" class="plus-icon"><i class="fas fa-plus" style="margin: 0px;"></i></span>
		<span class="minus-icon"><i class="fas fa-minus" style="margin: 0px;"></i></span>
	</a>

	<div class="settingSidebar-body ps-container ps-theme-default">
		<form>
			<div class=" fade show active">
				<button *ngIf="this.no_contact==true" class="btn btn-primary" (click)="AddContact()"
					style="float: right;margin: 9px 20px 0px 0px;"> Add Contact</button>

				<div class="setting-panel-header">Wrapup Code


				</div>
				<div class="p-15">

					<div class="row">
						<!-- <div class="col-md-12" *ngFor="let item of questions">
       					<div class="form-group">
       						<label for="department">Question</label>
       						<p>{{item.question}}</p>
       					</div>	
       				</div> -->
						<div class="col-md-12">
							<div class="form-group">
								<label for="department">Wrap Up Category</label>
								<select (change)="getAuxCode()" name="auxcodes" id="dup_auxcodes_pop_dup" class="form-control">
									<option value='0'> Select Category </option>
									<option *ngFor="let auxcode of auxcode_catagory" value="{{auxcode.id}}">
										{{auxcode.category_name}}</option>
								</select>
							</div>
						</div>
						<div class="col-md-12">
							<div class="form-group">
								<label for="department">Wrap Up Code</label>
								<select name="auxcodes" id="dup_auxcodes_subcat_dup" class="form-control">
									<option value='0'> Select Wrapup Code </option>
									<option *ngFor="let auxcode of auxcodes" value="{{auxcode.auxcode_name}}">
										{{auxcode.auxcode_name}}</option>
								</select>
							</div>
						</div>
						<div class="col-md-12">
							<div class="form-group">
								<label for="notes">Notes <i class="far fa-edit"></i></label>
								<textarea id="dup_notes" class="form-control"></textarea>
							</div>
						</div>
						<div class="col-md-12">
							<div class="buttons form-group">
								<input type="button" class="btn btn-secondary" (click)="toggleClass()" value="Close"
									type="button">
								<input type="button" class="btn btn-primary" value="Update Wrapup"
									(click)="addWrapupcode()">
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>

		<div class="dialer-call-action">
			<div class="row">
				<!-- <div class="col-md-12" *ngIf="show_ans">
					<div class="pulse call-attend-btn" (click)="acceptIncomeCall()">
						<a href="javascript:;" class="btn btn-success">
							<img src="assets/images/custom-wallboard/call-answered.svg" height="18"
								class="mr-2 shake-animation"> Answer Call from {{get_dailer_value}}</a>
					</div>
				</div>
				<div class="col-md-6" (click)="closeModelPopup()" *ngIf="show_mini_butons">
					<a href="javascript:;" class="btn btn-warning fl"><i class="far fa-window-maximize"></i>
						Minimize</a>
				</div>
				<div class="col-md-6" (click)="declineIncomeCall()" *ngIf="show_del">
					<a href="javascript:;" class="btn btn-danger fl"><i class="fas fa-phone-slash"></i> Decline</a> -->
				<div class="col-md-12 d-flex justify-content-center" *ngIf="show_ans">
					<h6>Incoming Call form {{get_dailer_value}}</h6>
				</div>
			</div>
			<br />
			<div class="row">
				<div class="col-md-4 d-flex justify-content-center" *ngIf="show_ans">
					<div class="call-attend-btn" (click)="acceptIncomeCall()">
						<a href="javascript:;" class="pulse btn btn-success">
							<i class="fas fa-phone shake-animation"></i>
							<span class="btn-action-text">Answer</span></a>
						<!-- <span class="btn-action-text">Answer Call from {{get_dailer_value}}</span></a> -->
					</div>
				</div>
				<div class="col-md-4 d-flex justify-content-center" (click)="declineIncomeCall()" *ngIf="show_del">
					<a href="javascript:;" class="btn btn-danger fl"><i class="fas fa-phone-slash"></i>
						<span class="btn-action-text">Decline</span> </a>
				</div>
				<div class="col-md-4 d-flex justify-content-center" (click)="closeModelPopup()"
					*ngIf="show_mini_butons">
					<a href="javascript:;" class="btn btn-warning fl"><i class="far fa-window-maximize"></i>
						<span class="btn-action-text">Minimize </span></a>
				</div>
			</div>
		</div>


	</div>

</div>



<div class="modal fade bd-example-modal-lg" id="dup_add_refinement">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<form>
				<div class="modal-header">
					<h5 class="modal-title" id="dup_myLargeModalLabel">Refinement Search</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">

					<div class="row">


						<!-- <img src="assets/images/test.gif" alt=""> -->

						<div class="col-md-11">

							<div class="form-group">
								<label>Account Name <span style="color:brown;font-size:12px;">(click
										enter after typing account name)</span></label>
								<!-- <select name="add_cat_id" id="dup_add_cat_id" class="form-control">
									<option value='0'> Select Category </option>
									<option *ngFor="let cat of cat_query_list" value="{{cat.id}}">
										{{cat.category_name}}</option>
									</select> -->

								<!-- <input list="cities" type="text" id="dup_account_name" class="form-control"
									placeholder="Enter atleast 5 Character" (keyup.enter)="searchnumAccount()"
									(input)="autosuggestAccName()" />
									<datalist id="dup_cities">
										<option *ngFor="let test of listsuggestion" value="{{test.accountid}}" >
									</datalist>
										<span *ngIf="show_failed" style="color: red;">No contact Found</span> -->

								<!-- <input list="datasid" name="city" value="" id="dup_account_name" class="form-control"
									placeholder="Enter atleast 5 Character" (keyup.enter)="searchnumAccount()"
									(input)="autosuggestAccName()"> -->
								<!-- <input list="datasid" name="city" value="" id="dup_account_name" class="form-control"
									placeholder="Enter atleast 5 Character" (keyup.enter)="autosuggestAccName()"
									> -->
								<input  name="city" value="" id="dup_account_name" class="form-control"
									placeholder="Enter atleast 5 Character" (input)="autosuggestAccName()" (keyup.enter)="searchnumAccount()"
									>

								<!-- <datalist id="dup_datasid">
									<i class="fas fa-angle-left"></i>
									<option value="{{ls.name}}" *ngFor="let ls of listsuggestion">{{ls.name}}</option>
              
								</datalist> -->
								<div class="card padding-15" *ngIf="showdatalist"> 
									<ul class="pagination mb-0" *ngFor="let ls of listsuggestion">
										<li class="page-item" style="cursor: pointer;" (click)="sentDataInInput(ls.name)">{{ls.name}}</li>
									</ul>
									<ul class="pagination mb-3 mt-2">
										<li><a class="page-link" href="javascript:void(0)" (click)="prevPage()"><i class="fas fa-angle-left"></i></a></li>
										<li><a  class="page-link" href="javascript:void(0)" (click)="nextPage()"><i class="fas fa-angle-right"></i></a></li>
									</ul>
								</div>

							</div>

						</div>

						<div class="col-md-1" style="padding-top:4%" *ngIf="showloading">
							<img src="assets/images/test.gif" style="width: 40px;">
						</div>

					</div>

					<div class="col-md-12" *ngIf="show_contact_value">
						<div class="form-group">
							<label for="add_acc_num">Contact Name</label>
							<select name="add_acc_num" id="dup_add_acc_num" class="form-control">
								<option value='0'> Select contact </option>
								<option *ngFor="let conc of conc_query_list" value="{{conc.contactid}}">
									{{conc.fullname}}</option>
							</select>
						</div>
					</div>

				</div>

				<div class="modal-footer" *ngIf="show_contact_value">
					<div class="buttons">
						<input type="button" (click)="cancelbutton()" class="btn btn-outline-secondary" value="Cancel">
						<input type="button" class="btn btn-primary update_agent" value="Update"
							(click)="searchGetContact()">
						<!-- <input type="submit" class="btn btn-primary update_agent" value="Update"> -->
					</div>
				</div>
			</form>

		</div>
	</div>
</div>







<div class="modal fade bd-example-modal-xl" id="dup_showpop_timeline">
	<div class="modal-dialog modal-xl">
		<div class="modal-content">
			<div class="modal-body">

				<div id="dup_accordion" class="accordion">
					<div class="card mb-0">
						<div class="card-header show" data-toggle="collapse" href="#collapseOne">
							<h4><i class="fa fa-address-book" aria-hidden="true"></i> Call Details</h4>
						</div>
						<div id="dup_collapseOne" class="card-body collapse show" data-parent="#accordion">
							<div class="modal-body">

								<div class="message-thread-header">
									<div class="row">
										<div class="col-md-12">
											<div class="thread-details-section fl">
												<div style="padding: 5px 25px;" class="ticket-message-details fl">
													<strong style="min-width: 72px; float: left;">Subject</strong>
													<span style="text-align: center;">
														<span class="blk-clr"><b>: &nbsp;&nbsp;
																{{this.Tsubject}}</b></span>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="message-thread-header">
									<div class="row">
										<div class="col-md-9">
											<div class="thread-details-section fl">
												<div style="padding: 5px 25px;" class="ticket-message-details fl">
													<strong style="min-width: 72px; float: left;">Phone</strong>
													<span style="text-align: center;">
														<span class="blk-clr"><b>: &nbsp;&nbsp;
																{{this.Tphone}}</b></span>
													</span>
												</div>
											</div>
										</div>
										<div class="col-lg-3">
											<div style="padding: 5px 25px;" class="ticket-message-details fl"><strong
													style="min-width: 72px; float: left;">Direction</strong>
												<span style="text-align: center;">
													<span *ngIf="this.Tsubject=='Inbound Call'" class="callType"
														style="color: #3d77a7;"><b>:&nbsp;&nbsp;<img
																src="assets/images/incoming-call.svg">
															Incoming</b></span>
													<span *ngIf="this.Tsubject=='Outbound Call'" class="callType"
														style="color: #3d77a7; "><b>:&nbsp;&nbsp; <img
																src="assets/images/outgoing-call.svg">
															Outgoing</b></span>
													<span
														*ngIf="this.Tsubject!='Outbound Call' && this.Tsubject!='Inbound Call'"
														class="callType" style="color: #3d77a7;"><b>:&nbsp;&nbsp;<i
																style="font-size: 17px;" class="fa fa-phone"></i>
															{{this.Tsubject}}</b></span>
												</span>
											</div>
										</div>
									</div>
								</div>
								<div class="message-thread-header">
									<div class="row">
										<div class="col-md-12">
											<div class="thread-details-section fl">
												<div style="padding: 5px 25px;" class="ticket-message-details fl">
													<strong style="min-width: 72px; float: left;">Description</strong>
													<span style="text-align: center;">
														<span class="blk-clr"><b>: &nbsp;&nbsp;
																{{this.Tdescription}}</b></span>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div *ngIf="this.user_type == 'Admin'" class="message-thread-header">
									<div class="row">
										<div class="col-md-12">
											<div class="thread-details-section fl">
												<div style="padding: 5px 25px;" class="ticket-message-details fl">
													<strong style="min-width: 72px; float: left;">Recording :</strong>
													<span style="text-align: center;">
														<a style="cursor: pointer;color: aqua;" target="_blank"
															href="{{this.Trecord}}" class="blk-clr"><b> &nbsp;&nbsp;
																{{this.Trecord}}</b></a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="message-thread-header">
									<div class="row">
										<div class="col-md-12">
											<div class="thread-details-section fl">
												<div style="padding: 5px 25px;" class="ticket-message-details fl">
													<strong style="min-width: 72px; float: left;">Wrapup </strong>
													<span style="text-align: center;">
														<span class="blk-clr"><b>: &nbsp;&nbsp;
																{{this.Twrapcode}}</b></span>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="message-thread-header">
									<div class="row">
										<div class="col-md-12">
											<div class="thread-details-section fl">
												<div style="padding: 5px 25px;" class="ticket-message-details fl">
													<strong style="min-width: 72px; float: left;">Note <i
															class="fas fa-list-alt"></i></strong>
													<span style="text-align: center;">
														<span class="blk-clr"><b>: &nbsp;&nbsp;
																{{this.Twrapnote}}</b></span>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>

					</div>
				</div>
			</div>
			<div class="modal-footer">
				<div class="buttons">
					<input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="close">
					<!-- <input type="button" class="btn btn-success update_agent" value="Submit" (click)="AddNote()"> -->
				</div>
			</div>
		</div>
	</div>
</div>