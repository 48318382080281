<!--  <div class="loader"></div>-->
  <!-- <div id="app">
    <section class="section">
      <div class="container pt-50">
        <div class="row" *ngIf="loginFormTemp">
          <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div class="card card-primary">
              <div class="card-header">
                <h4>Login</h4>
              </div>
              <div class="card-body">

                <div class="login-logo">
                  <img src="assets/img/logo.png"> 
                  <span class="logo-name">Omni Channel</span>
                </div>

				  <p style="width: 100%;margin-top: .25rem;font-size: 80%;color: #dc3545;"></p>
                <form method="POST" [formGroup]="loginForm" (ngSubmit)="userLogin()">
                  <div class="form-group">
                    <label for="company_name">Company Name</label>
                    <input id="company_name" type="text" class="form-control" formControlName="company_name" autocomplete="off">
                    <div class="error-message message-small" *ngIf="!loginForm.get('company_name').valid && loginForm.get('company_name').touched">
                      Please enter your company name
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="username">Username</label>
                    <input id="username" type="text" class="form-control" formControlName="user_name" autocomplete="off">
                    <div class="error-message message-small" *ngIf="!loginForm.get('user_name').valid && loginForm.get('user_name').touched">
                      Please enter your username
                    </div>
                  </div>
                  <div class="form-group" style="display: block !important;">
                    <div class="d-block">
                      <label for="password" class="control-label" >Password</label>
                    </div>
                    <input id="password" type="password" formControlName="password" class="form-control">
                    <div class="error-message message-small" *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched">
                      please enter your password
                    </div>
                  </div>  
                  <div class="form-group">
                    <span class="login-message success-message message-small">{{loginSuccess}}</span>
                     <span class="login-message error-message message-small">{{loginError}}</span>
                 <input type="submit" value="Login" class="btn btn-primary btn-lg btn-block" tabindex="4">
               <div class="fl ms-signin text-center mt-3"> 
                 <span class="signin-text">OR SIGN IN WITH</span>
               </div>
               
                 <div class="ms-img-holder fl text-center mt-3 mb-2">
                   <a href="https://uatassaabloyccapi.mconnectapps.com/ms-sso/simplesamlphp/index_omni.php"> 
                     <img width="130" class=""src="assets/img/zoho_crm/microsoft_logo.svg">
                   </a>
                 </div>
                
                 
               </div>    
                 
                </form>
                <div class="float-right">
                  <a href="javascript:void(0)" routerLink="/forgot-pwd" class="text-small">
                    Forgot Password?
                  </a>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div class="row" *ngIf="sendotp">
          <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div class="card card-primary">
              <div class="card-header">
                <h4>Send OTP Via</h4>
              </div>
              <div class="card-body">
                <div class="login-logo" style="padding-bottom: 45px;">
                  <img src="assets/img/logo.png"> 
                  <span class="logo-name">Omni Bus</span>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-4 ">
                    <img src="assets/images/sms.svg"> 
                  </div>
                  <div class="col-12 col-lg-8 ">
                   <button type="button" class="btn btn-primary btn-lg btn-block" (click)="loginVia('sms')">SMS</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-4 ">
                    <img src="assets/images/gmail.svg">
                  </div>
                  <div class="col-12 col-lg-8 ">
                   <button  type="button" class="btn btn-primary btn-lg btn-block" (click)="loginVia('email')">Email</button>
                  </div>
                </div>
                <div class="row">
         
                  <div class="col-12 ">
                   <button type="button" class="btn btn-primary btn-lg btn-block" (click)="backToLogin()">Back to Login</button>
                  </div>
                </div>
                
              </div>
            </div>
            
          </div>
        </div>
        <div class="row" *ngIf="sendotpmain">
          <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div class="card card-primary">
              <div class="card-header">
                <h4>OTP</h4>
              </div>
              <div class="card-body">

                <div class="login-logo">
                  <img src="assets/img/logo.png"> 
                  <span class="logo-name">Omni Bus</span>
                </div>

				  <p style="width: 100%;margin-top: .25rem;font-size: 80%;color: #dc3545;"></p>
                  <div class="form-group">
                    <label for="username">Enter OTP</label>
                    <input id="otp" type="text" class="form-control" id="otp" autocomplete="off">
                    
                  </div>
                  
                 
                  <div class="form-group">
                       <span class="login-message success-message message-small">{{loginSuccess}}</span>
                        <span class="login-message error-message message-small">{{loginError}}</span>
                    <input type="submit" value="Login" class="btn btn-primary btn-lg btn-block" tabindex="4" (click)="sendOTPLogin()">
                    
                  </div>


                  <div class="float-left">
                    <a href="javascript:void(0)" routerLink="/forgot-pwd" class="text-small">
                      resend otp
                    </a>
                  </div>

                <div class="float-right">
                  <a href="javascript:void(0)" routerLink="/forgot-pwd" class="text-small">
                    Forgot Password?
                  </a>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  </div> -->


  <!--  <div class="loader"></div>-->
  <div id="app">
    <section class="section">
      <!-- <div class="container pt-50"> -->
    <div class="main-bg">


      <div class="card card-primary">
        
      <div class="login-logo">
        <img src="assets/img/logo.png"> 
        <span class="logo-name">Omni Channel</span>
      </div>
     
      <!-- ms-img-holder fl text-center mt-3 mb-2  -->
        <!-- <div class="ms-img-holder fl text-center mb-2">
          <a href="https://uatassaabloyccapi.mconnectapps.com/ms-sso/simplesamlphp/index_omni.php"> 
            <img width="130" class="logo-name"src="assets/img/zoho_crm/microsoft_logo.svg">
          </a>
        </div>
        <div class="fl ms-signin text-center"> 
          <span class="signin-text ">OR SIGN IN WITH</span>
        </div> -->
          <div class="">
              <ul class="tabs nav nav-tabs">
                <li   (click)=" tab = 1 " [ngClass]=" {'active-tab': tab==1 }"  class="width50 aligncenter pointer line50"><span >MOBILE APP</span></li>
                <li    (click)=" tab = 2 " [ngClass]=" {'active-tab': tab==2 }"  class="width50 aligncenter pointer line50"><span>PASSWORD LOGIN</span></li>
            </ul>
            <div class="">
                    <div class="tab-panel" id="tabPanel2" *ngIf="tab==2" >
                      <div class="row" *ngIf="loginFormTemp">
                          <div class="card card-primary">
                            <!-- <div class="card-header">
                              <h4>Login</h4>
                            </div> -->
                            <div class="card-body" style="padding:0px 0px">
              
                              <!-- <div class="login-logo">
                                <img src="assets/img/logo.png"> 
                                <span class="logo-name">Omni Channel</span>
                              </div> -->
              
                        <p style="width: 100%;margin-top: .25rem;font-size: 80%;color: #dc3545;"></p>
                              <form method="POST" [formGroup]="loginForm" (ngSubmit)="userLogin()">
                                <div class="form-group">
                                  <label for="company_name">Company Name</label>
                                  <input id="company_name" type="text" class="form-control" formControlName="company_name" autocomplete="off">
                                  <div class="error-message message-small" *ngIf="!loginForm.get('company_name').valid && loginForm.get('company_name').touched">
                                    Please enter your company name
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label for="username">Username</label>
                                  <input id="username" type="text" class="form-control" formControlName="user_name" autocomplete="off">
                                  <div class="error-message message-small" *ngIf="!loginForm.get('user_name').valid && loginForm.get('user_name').touched">
                                    Please enter your username
                                  </div>
                                </div>
                                <div class="form-group" style="display: block !important;">
                                  <div class="d-block">
                                    <label for="password" class="control-label" >Password</label>
                                  </div>
                                  <input id="password" type="password" formControlName="password" class="form-control">
                                  <div class="error-message message-small" *ngIf="!loginForm.get('password').valid && loginForm.get('password').touched">
                                    please enter your password
                                  </div>
                                </div>  
                                <div class="form-group">
                                  <span class="login-message success-message message-small">{{loginSuccess}}</span>
                                   <span class="login-message error-message message-small">{{loginError}}</span>
                               <input type="submit" value="Login" class="btn btn-primary btn-lg btn-block" tabindex="4">
                            
                              
                               
                             </div>    
                               
                              </form>
                              <div class="fl ms-signin text-center mt-3 mb-3"> 
                                <span class="signin-text ">OR SIGN IN WITH</span>
                              </div>
                              <div class="ms-img-holder fl text-center mb-2">
                                <a href="https://omnitickets.mconnectapps.com/ms-sso/simplesamlphp/index_omni.php"> 
                                  <img width="130" class="logo-name"src="assets/img/zoho_crm/microsoft_logo.svg">
                                </a>
                              </div>
                              <div class="float-right">
                                <a href="javascript:void(0)" routerLink="/forgot-pwd" class="text-small">
                                  Forgot Password?
                                </a>
                              </div>
                            </div>
                          </div>
                          
                      </div>
                      <div class="row" *ngIf="sendotp">
                        <!-- <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"> -->
                          <div class="card card-primary">
                            <div class="card-header">
                              <h4>Send OTP Via</h4>
                            </div>
                            <div class="card-body">
                              <!-- <div class="login-logo" style="padding-bottom: 45px;">
                                <img src="assets/img/logo.png"> 
                                <span class="logo-name">Omni Channel</span>
                              </div> -->
                              <div class="row">
                                <div class="col-12 col-lg-4 ">
                                  <img src="assets/images/sms.svg"> 
                                </div>
                                <div class="col-12 col-lg-8 ">
                                <button type="button" class="btn btn-primary btn-lg btn-block" (click)="loginVia('sms')">SMS</button>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 col-lg-4 ">
                                  <img src="assets/images/gmail.svg">
                                </div>
                                <div class="col-12 col-lg-8 ">
                                <button  type="button" class="btn btn-primary btn-lg btn-block" (click)="loginVia('email')">Email</button>
                                </div>
                              </div>
                              <div class="row">
                      
                                <div class="col-12 ">
                                <button type="button" class="btn btn-primary btn-lg btn-block" (click)="backToLogin()">Back to Login</button>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                          
                        <!-- </div> -->
                      </div>
                      <div class="row" *ngIf="sendotpmain">
                        <!-- <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4"> -->
                          <div class="card card-primary">
                            <div class="card-header">
                              <h4>OTP</h4>
                            </div>
                            <div class="card-body">
            
                              <!-- <div class="login-logo">
                                <img src="assets/img/logo.png"> 
                                <span class="logo-name">Omni Channel</span>
                              </div> -->
            
                        <p style="width: 100%;margin-top: .25rem;font-size: 80%;color: #dc3545;"></p>
                                <div class="form-group">
                                  <label for="username">Enter OTP</label>
                                  <input id="otp" type="text" class="form-control" id="otp" autocomplete="off">
                                  
                                </div>
                                
                              
                                <div class="form-group">
                                    <span class="login-message success-message message-small">{{loginSuccess}}</span>
                                      <span class="login-message error-message message-small">{{loginError}}</span>
                                  <input type="submit" value="Login" class="btn btn-primary btn-lg btn-block" tabindex="4" (click)="sendOTPLogin()">
                                  
                                </div>
            
            
                                <div class="float-left">
                                  <a href="javascript:void(0)" routerLink="/forgot-pwd" class="text-small">
                                    resend otp
                                  </a>
                                </div>
            
                              <div class="float-right">
                                <a href="javascript:void(0)" routerLink="/forgot-pwd" class="text-small">
                                  Forgot Password?
                                </a>
                              </div>
                            </div>
                          </div>
                          
                        <!-- </div> -->
                      </div>
                    </div>
                    <div class="tab-panel" id="tabPanel1" *ngIf="tab==1">
                      <!-- <div class="card-header">
                        <h4>QR Code</h4>
                      </div> -->
                      <div *ngIf="!expired" >
                          <!-- <qr-code [value]="qrdata" [size]="width" [level]="level" style="display: flex;justify-content: center;"></qr-code> -->
                          <img src="/../assets/img/custom-images/qrimage.png" style="opacity: 0.1;margin: 0 auto;
                          display: inherit;">
                          <h5 class="aligncenter">SCAN WITH MOBILE APP</h5>
                          <p class="aligncenter">No more remembering passwords</p>
                      </div>
                      <div *ngIf="expired"class="qr-scanner-section">
                          <img src="/../assets/img/custom-images/qrimage.png" style="opacity: 0.1;margin: 0 auto;
                          display: inherit;">
                            <!-- <button type="button"  class="btn btn-danger btnchn" (click)="subscribes('1')"> -->
                              <button type="button"  class="btn btn-danger btnchn">
                            <i class="fas fa-sync"></i>Refresh QR Code</button>
                            <h6 class="exptext">QR CODE HAS EXPIRED</h6>
                      </div>
                      <!-- <h4 class="designh4">Don't have CMS Mobile ? <span class="pointer clr">Download now!</span></h4> -->
              <div class="mobile-app-scan mb-3" style="text-align: center;">
              <a  href="#" target="_blank"><span >
              <img alt="image" class="" src="/../assets/img/custom-images/app-store.png" style="height: 36px !important">

              </span>
              </a>




              <a  href="#" target="_blank"><span >
              <img alt="image" class="ml-2" src="assets/img/custom-images/play-store.png" style="height: 36px !important">

              </span>
              </a>
              </div>
              <div class="fl ms-signin text-center mb-3"> 
                <span class="signin-text ">OR SIGN IN WITH</span>
              </div>
              <div class="ms-img-holder fl text-center mb-2">
                <a href="https://omnitickets.mconnectapps.com/ms-sso/simplesamlphp/index_omni.php"> 
                  <img width="130" class="logo-name"src="assets/img/zoho_crm/microsoft_logo.svg">
                </a>
              </div>


                  </div>   
            </div>   
        
         
        </div>
      </div>
    </div>
    <!-- </div> -->
    </section>
  </div>