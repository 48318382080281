
<div class="section-body">
    <div class="row">
        <div class="col-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4> SMS Report</h4>
                  
                </div>
                <div class="card-body p-30">
                <form [formGroup]="getRep" >
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="from_date">From Date</label>
                                <input type="date" id="from_dt" class="form-control" formControlName="from_dt">
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="to_date">To Date</label>
                                <input type="date" id="to_dt" class="form-control" formControlName="to_dt">
                            </div>
                        </div>
                        <!-- <div class="col-md-6">
                            <div class="form-group">
                                <label for="rep_format">Report Format</label>
                               <select class="form-control" formControlName="rep_format">
                                   <option value="html">Html</option>
                                   <option value="excel">Excel</option>
                               </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="report_name">Report Name</label>
                                <input type="report_name"  class="form-control" formControlName="report_name">
                            </div>
                        </div> -->
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <!-- <button type="button" class="btn btn-primary" (click)="searchData()">Search Report</button> -->

                                <button type="button" class="btn btn-primary" (click)="genReport()">Generate Report</button>
                            </div>
                        </div>
                    </div>
                    
                </form>
                </div>
            </div>
            
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>SMS History</h4>
                    <div class="addBtn">
                        <button type="button" class="btn btn-primary" >SMS Balance: ${{this.price_sms}}</button>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-striped table-md">
                            <tr>
                                <td colspan="12" class="search-text-tbl">
                                    <div class="input-group"><input type="text" class="form-control" placeholder="search number" (keyup.enter)="searchData({'search_text':queue_search.value})" #queue_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="searchData({'search_text':queue_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>S.no</th>
                                <th>Sent to</th>
                                <th>Sent By</th>
                                <th>Message</th>
                                <th>Country Code</th>
                                <th>Sender ID</th>
                                <th>Sms Amount ($)</th>
                                
                                
                            </tr>
                            <tr *ngFor="let history of call_history_list;let i=index" >
                                <td>{{offset_count+i+1}}</td>
                                <td>{{history.customer_name}}</td>
                                <td>{{history.user_name}}</td>
                                <td>
                                   <ul [ngClass]="{'has-tooltip': history.chat_message.length >= '30'}">
                                   
                                    <span class="tooltip-modal" style="left: -255px;
                                    right: -369px;" >{{history.chat_message}}</span>
                                    <div id="chat-message">{{history.chat_message}}</div>
                                   </ul>
                                </td>
                                <td>{{history.country_code}}</td>
                                <td>{{history.sender_id}}</td>
                                <td>{{history.sms_tarrif}}</td>
                            </tr>
                            
                            <tr *ngIf="recordNotFound == true">
                               <td colspan="12">Data not found</td>
                            </tr>
                      
                        </table>
                    </div>
                </div>
                <div class="card-footer text-right">
                    <nav class="d-inline-block">
                        <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">
            
                            <li class="page-item">
                                <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? searchData({'search_text':queue_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                            </li>
                            <li class="page-item" *ngIf="paginationData.backtab =='show'">
                                <a class="page-link" href="javascript:void(0)" (click)="searchData({'search_text':queue_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                            </li>
            
            
                            <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                                <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? searchData({'search_text':queue_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                            </li>
            
                            <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                                <a class="page-link" href="javascript:void(0)" (click)="searchData({'search_text':queue_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                            </li>
            
                            <li class="page-item">
                                <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? searchData({'search_text':queue_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>


</div>