 <div class="section-body">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="card">
                  <div class="card-header">
                    <h4>Tickets</h4>
                    <div class="addBtn" *ngIf="show_admin_sett">
                      <button type="button" class="btn btn-primary" (click)="ticketReport()">Generate Report</button>
                    </div>
                    <a href="https://uatassaabloyccapi.mconnectapps.com/api/v1.0/pro_contents/internal-ticket.pdf"  target="_blank"><i style="font-size: 24px;float: right;padding-left: 20px;" class="fa fa-question-circle"></i></a>
                </div>
                  <div class="boxs mail_listing">
                    <div class="inbox-center table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th class="text-center">
                              <label class="form-check-label">
                                <a href="#" class="col-dark-gray waves-effect m-r-20" title="back"
                                data-toggle="tooltip" routerLink="/mc">
                                <i class="material-icons">keyboard_return</i>
                              </a>                                
                               <span class="form-check-sign"></span>
                              </label>
                            </th>
                            <th colspan="3">
                              <div class="inbox-header">
                                <div class="mail-option">
                                  <div class="email-btn-group m-l-15">
                                    

                                    <!-- <a href="#" class="col-dark-gray waves-effect m-r-20" title="Archive"
                                      data-toggle="tooltip">
                                      <i class="material-icons">archive</i>
                                    </a>
                                    <div class="p-r-20">|</div>
                                    <a href="#" class="col-dark-gray waves-effect m-r-20" title="Error"
                                      data-toggle="tooltip">
                                      <i class="material-icons">error</i>
                                    </a>
                                    <a href="#" class="col-dark-gray waves-effect m-r-20" title="Delete"
                                      data-toggle="tooltip">
                                      <i class="material-icons">delete</i>
                                    </a>
                                    <a href="#" class="col-dark-gray waves-effect m-r-20" title="Folders"
                                      data-toggle="tooltip">
                                      <i class="material-icons">folder</i>
                                    </a>
                                    <a href="#" class="col-dark-gray waves-effect m-r-20" title="Tag"
                                      data-toggle="tooltip">
                                      <i class="material-icons">local_offer</i>
                                    </a> -->
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th class="hidden-xs" colspan="2"></th>
                            <th class="hidden-xs" colspan="2">
                              <div class="pull-right">
                                <div class="email-btn-group m-l-15"  *ngIf="show_admin_sett">
                                  <!-- <button type="button" class="btn btn-primary" (click)="ticketReport()">Generate Report</button> -->
                                  <!-- <a href="#" class="col-dark-gray waves-effect m-r-20" title="previous"
                                    data-toggle="tooltip">
                                    <i class="material-icons">navigate_before</i>
                                  </a>
                                  <a href="#" class="col-dark-gray waves-effect m-r-20" title="next"
                                    data-toggle="tooltip">
                                    <i class="material-icons">navigate_next</i>
                                  </a> -->
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>

                        <tbody class="has-color-table-row">
                         
                          <tr class="unread" *ngFor="let ticket of queue_list; let i=index" (click)="viewTicket(ticket.ticket_id)">
                            <td class="tbl-checkbox">
                              <label class="form-check-label">
                                {{0+i+1}}
                                <span class="form-check-sign"></span>
                              </label>
                            </td>
                            <td class="hidden-xs">
                              {{ticket.ticket_id}}
                            </td>
                            <td class="hidden-xs">
                              <span class="badge badge-primary">{{ticket.department}}</span></td>
                            <td class="max-texts">
                              <a href="javascript:void(0)" >
                              {{ticket.ticket_message | slice:0:80}}...</a>
                            </td>
                            <td class="hidden-xs">
                              {{ticket.assigned_by}}
                            </td>
                            <td class="hidden-xs">
                              <span  [class]="ticket.ticket_status =='Closed' ? 'badge badge-danger' :'badge badge-success'" >{{ticket.ticket_status}} </span>
                            </td>
                            <td class="text-right">{{ticket.created_at}}</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                    <div class="row" *ngIf="recordNotFound == true">
                      <div class="col-sm-7 ">
                        <p class="p-15">Data not found</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>