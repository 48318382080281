
<div class="section-body">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4>PBX Setting </h4>
                    <div class="addBtn" *ngIf="this.pbx_count > this.queue_length">
                        <button type="button" class="btn btn-primary" (click)="addpbx()">+ Add PBX</button>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                     <table class="table table-bordered table-striped table-md">
                         
                        <tr>
                            <th># </th>
                            <th *ngIf="uadmin_id == 1">Admin Name </th>
                            <th>Sip URL</th>
                            <th>Sip PORT</th>
                            <th>Action</th>
                        </tr>
                        <tbody>
                            <tr *ngFor="let queue of queue_list; let i=index">
                                <td>{{0+i+1}}</td>
                                <th *ngIf="uadmin_id == 1">{{queue.admin_name}}</th>
                                <td>{{queue.sip_url}}</td>
                                <td>{{queue.sip_port}}</td>
                                <!-- <td><button type="button" class="btn btn-primary" (click)="editPbc(queue.id)">Edit</button></td> -->
                                <td>
                                    <span class="user-icon" style="cursor: pointer;"><i class="far fa-edit" (click)="editPbc(queue.id)"></i></span>
                                    <span class="user-icon" style="cursor: pointer;"><i class="far fa-trash-alt" (click)="deletedata(queue.id)"></i></span>
                                </td>
                            </tr>

                            <tr *ngIf="recordNotFound == true">
                             <td colspan="12">Data not found</td>
                         </tr>
                     </tbody>
                     
                 </table>
             </div>
         </div>
         
     </div>
 </div>
</div>
<!-- 

    Phone Bridge
 -->
 <div class="row">
    <div class="col-12 col-md-12 col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4>Phone Bridge </h4>
                <div class="addBtn">
                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#addBridge">+ Add Bridge</button>
                </div>
            </div>
            <div class="card-body p-0">
                <div class="table-responsive">
                 <table class="table table-bordered table-striped table-md">
                     
                    <tr>
                        <th># </th>                       
                        <th>PBX URL</th>
                        <th>PBX IP</th>
                        <th>PORT</th>
                        <th>Action</th>
                    </tr>
                    <tbody>
                        <tr *ngFor="let queue of bridge_list; let i=index">
                            <td>{{0+i+1}}</td>
                            <td>{{queue.sip_url}}</td>
                            <td>{{queue.ip_address}}</td>
                            <td>{{queue.sip_port}}</td>
                            <td>
                                <span class="user-icon" style="cursor: pointer;"><i class="far fa-edit" (click)="editBridge(queue.id)"></i></span>
                                <span class="user-icon" style="cursor: pointer;"><i class="fa fa-eye"  (click)="viewBridgeDetails(queue.ip_address)"></i></span>
                                <span class="user-icon" style="cursor: pointer;"><i class="far fa-trash-alt" (click)="deletebridges(queue.id,queue.ip_address)"></i></span>
                            </td>
                        </tr>

                        <tr *ngIf="recordNotFound == true">
                         <td colspan="12">Data not found</td>
                     </tr>
                 </tbody>
                 
             </table>
         </div>
     </div>
     
 </div>
</div>
</div>
</div>




<div class="modal fade bd-example-modal-md" id="edit_pbxform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="editPbx" (ngSubmit)="editPbxData(old_sip_url)">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Update PBX Details</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    
                    <div class="row">
                        
                        
                       <div class="col-md-6">
                        <div class="form-group">
                            <label for="sip_url">SIP Url</label>
                            <input type="text" id="sip_url" class="form-control" formControlName="sip_url">
                            
                        </div>
                    </div>   
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="sip_port">SIP Port</label>
                            <input type="text" id="sip_port" class="form-control" formControlName="sip_port" >
                        </div>
                    </div>                     
                    
                    <div class="col-md-6">
                     <div class="form-group">
                         <label for="status">PBX Status</label>
                         <!-- <input type="text" id="status" class="form-control" formControlName="status"> --> 
                         <input type="checkbox" id="status" class="form-control" formControlName="status"/>
                     </div>
                 </div>                        
             </div>

         </div>

         <div class="modal-footer">
            <div class="buttons">
                <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                <input type="submit" class="btn btn-primary update_agent" value="Update" [disabled]="!editPbx.valid">
            </div>
        </div>
    </form>
</div>
</div>
</div>


<div class="modal fade bd-example-modal-md" id="add_pbxform">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form [formGroup]="addPbx" (ngSubmit)="addPbxData()">
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">PBX Details</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                  
                    <div class="row">
                       <div class="col-md-6">
                        <div class="form-group">
                            <label for="sip_url">SIP Url</label>
                            <input type="text" id="sip_url" class="form-control" formControlName="sip_url">
                        </div>
                    </div>   
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="sip_port">SIP Port</label>
                            <input type="text" id="sip_port" class="form-control" formControlName="sip_port" >
                        </div>
                    </div>                     
                </div>

            </div>

            <div class="modal-footer">
                <div class="buttons">
                    <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                    <input type="submit" class="btn btn-primary update_agent" value="Update" [disabled]="!addPbx.valid">
                </div>
            </div>
        </form>

    </div>
</div>
</div>

<div class="modal fade bd-example-modal-md" id="addBridge">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Add Bridge Details</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                  
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="sip_url">PBX Url</label>
                            <input type="text" id="bridge_sip_url" class="form-control" >
                        </div>
                    </div>   
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="sip_url">PBX IP</label>
                            <input type="text" id="ip_address" class="form-control">
                        </div>
                    </div> 
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="sip_port">Port</label>
                            <input type="text" id="bridge_sip_port" class="form-control" >
                        </div>
                    </div>                     
               </div>

            </div>

            <div class="modal-footer">
                <div class="buttons">
                    <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                    <input type="submit" class="btn btn-primary" value="Add" (click)="addbridgeData()">
                </div>
            </div>
        </form>

    </div>
</div>
</div>
<div class="modal fade bd-example-modal-md" id="updateBridge">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Add Bridge Details</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                  
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="sip_url">PBX Url</label>
                            <input type="text" id="up_bridge_sip_url" class="form-control" >
                        </div>
                    </div>   
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="sip_url">PBX IP</label>
                            <input type="text" id="up_ip_address" class="form-control">
                        </div>
                    </div> 
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="sip_port">Port</label>
                            <input type="text" id="up_bridge_sip_port" class="form-control" >
                        </div>
                    </div>                     
               </div>

            </div>

            <div class="modal-footer">
                <div class="buttons">
                    <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="Cancel">
                    <input type="submit" class="btn btn-primary" value="Add / Update" (click)="updateBridge()">
                </div>
            </div>
        </form>

    </div>
</div>
</div>
<div class="modal fade bd-example-modal-xl" id="ViewBridge">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <form>
                <div class="modal-header">
                    <h5 class="modal-title" id="myLargeModalLabel">Bridge Details</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                  
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12">
                            <div class="card">
                                <div class="card-header">
                                    <h4><i class="fas fa-users"></i> Phone Bridge Users</h4>
                                </div>
                                <div class="card-body call-queue-wizard">
                                    <div id="wizard_horizontal" class="wizard clearfix">
                                        <!-- <div class="steps">
                                            <ul class="nav nav-tabs">                                                
                                                <li class="nav-item">
                                                    <a class="nav-link active" data-toggle="tab" href="#agentsQueue">
                                                        <span class="wizard-icon"><i class="fas fa-users"></i></span> Agents</a>
                                                </li>
                                            </ul>
                                        </div> -->
                                        <!-- Tab panes -->
                                        <div style="height: 500px;" class="tab-content content">                                         
                                            <!---- Tab List -->
                                            <div id="agentsQueue" class="body tab-pane active">
                                                <!-- <div class="card-header">
                                                    <h4><span class="wizard-icon"><i class="fas fa-users"></i></span> Phone Bridge</h4>
                                                </div> -->
                                                <!-- <div class="form-group">
                                                    <label for="wrap-up-time">Name of bridge</label>
                                                    <input class="form-control" name="wrap-up-time" type="text" disabled value="Name of bridge">
                                                </div>                 -->
                                                <div class="row">
                                                    <div style="position: absolute;overflow: scroll;" class="col-12 col-md-12 col-lg-12">
                                                        <div class="table-responsive">
                                                            <table class="table table-bordered table-striped table-md">
        
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Extension</th>
                                                                    <th>Name</th>                                                                       
                                                                </tr>
        
                                                                <tbody>                
                                                                    <ng-container *ngFor="let agent of bridge_agents;let i=index">
                                                                        <tr>
                                                                            <td>{{i+1}}</td>
                                                                            <td>{{agent.sip_login}}</td>
                                                                            <td>{{agent.agent_name}}</td>
                                                                        </tr>
                                                                    </ng-container>                

                                                                    <tr *ngIf="bridgerecordNotFound">
                                                                       No data found
                                                                    </tr>
        
        
        
                                                                </tbody>
        
                                                            </table>
                                                        </div>
                                                        <!-- <div class="card">
                                                            <div class="card-header">
                                                                <h4>Users</h4>                                                             
                                                            </div>
                                                            <div class="card-body p-0">
                                                               
                                                            </div>
                
                                                        </div> -->
                                                    </div>
                                                </div>
                
                                            </div>
                                            <!---- Tab List End -->
                                            <!---- Tab List -->
                                            <!-- <div id="queueAdvanced" class="body tab-pane">
                                                <div class="card-header">
                                                    <h4><span class="wizard-icon"><i class="fas fa-users-cog"></i></span> Bridge Preferences
                                                    </h4>
                                                </div>
                
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-12">                                                       
                
                                                        <div class="row">
                                                            <div class="col-md-6 col-12">
                                                                <div class="form-group">
                                                                    <label for="wrap-up-time">Name of bridge</label>
                                                                    <input class="form-control"name="wrap-up-time" type="text" disabled value="{{this.wrapup_time}}">
                                                                </div>
                                                            </div>
        
                                                            <div class="col-md-6 col-12">
                                                                <div class="form-group">
                                                                    <label for="max-callers-queue">Virtual extension number</label>
                                                                    <input class="form-control" id="max_callers" name="max-callers-queue" type="text" disabled value="{{this.max_callers}}">
                                                                </div>
                                                            </div>       
                                                                      
                                                          
                                                            <div class="col-md-6 col-12">
                                                            </div>                                                           
                                                        </div>
                
                
                                                      
                                                    </div>
                                                </div>
                                            </div> -->
                                            <!---- Tab List End -->             
                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

            </div>

            <div class="modal-footer">
                <div class="buttons">
                    <input type="button" data-dismiss="modal" class="btn btn-outline-secondary" value="close">
                    <!-- <input type="submit" class="btn btn-primary update_agent" value="Update"> -->
                </div>
            </div>
        </form>

    </div>
</div>
</div>