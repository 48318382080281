<!--  <div class="loader"></div>-->
<div id="app">
    <section class="section">
      <div class="container pt-50">
        <div class="row">
          <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div class="card card-primary">
              <div class="card-header">
                <h4>Forget Password</h4>
              </div>
              <div class="card-body">

                <div class="login-logo">
                  <img src="assets/img/logo.png"> 
                  <span class="logo-name">Omni Bus</span>
                </div>

				  <p style="width: 100%;margin-top: .25rem;font-size: 80%;color: #dc3545;"></p>
                  <div class="form-group">
                    <label for="username">Please Enter The Valid Email</label>
                    <input id="email" type="text" class="form-control"  autocomplete="off">
                   
                  </div>
                
                 
                       
                  <div class="form-group">
                    
                    <input type="button" value="Send" class="btn btn-primary btn-lg btn-block" tabindex="4" (click)="sendDetails()">
                    
                  </div>
                
               
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  </div>